import { useState, useEffect, SetStateAction } from 'react';
import { isEmpty } from 'lodash';

import { ColorPicker } from '../../molecules/ColorPicker/ColorPicker';
import { useAppSelector } from '../../../common/hooks';
import { useTranslation } from 'react-i18next';

const ColorPickerContainer = ({ formState, register, setValue }: any) => {
    const { t } = useTranslation();
    const styles = useAppSelector((state: { global: any }) => state.global?.globalConfig?.styles?.config || {});
    const initialColor = styles?.layout?.primary;

    const [color, setColor] = useState(formState.color);
    const handleColorChange = (e: { target: { value: SetStateAction<string> } }) => {
        setColor(e.target.value);
        setValue('color', e.target.value);
    };

    useEffect(() => {
        if (!isEmpty(styles)) {
            setColor(styles?.layout?.primary);
        }
    }, [styles]);
    return (
        <ColorPicker
            initialColor={initialColor}
            color={color}
            setColor={setColor}
            formState={formState}
            register={register}
            handleColorChange={handleColorChange}
            label={t('TEXT_ADMIN_PRIMARY_COLOR')}
        />
    );
};

export default ColorPickerContainer;
