import styled from '@emotion/styled';
import { Icon } from '../../atoms/Icon/Icon';
import { getIconForPDF } from '../../../utils/goalIconsMapperForPDF';

const Container = styled('div')<{ progress: number }>(({ progress }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: `${progress}%`,
    marginLeft: '-20px',
    alignItems: 'center',
    transition: '0.3s ease'
}));

const IconContainer = styled('div')(({}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    backgroundColor: '#3769FF',
    width: '40px',
    height: '40px',
    svg: {
        color: 'white',
        width: '24px',
        height: '24px',
        marginLeft: '1px'
    }
}));

const Label = styled('span')(({}) => ({
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'TT Commons Pro',
    color: '#416AF4',
    position: 'absolute',
    top: '-25px'
}));

type Props = {
    progress: number;
    isPDF?: boolean;
};

export const PlanSummaryProgressBarTarget = ({ progress, isPDF }: Props) => {
    return (
        <Container progress={progress}>
            <IconContainer>{isPDF ? getIconForPDF('star', '40px') : <Icon id="star" />}</IconContainer>
            <Label>Goal</Label>
        </Container>
    );
};
