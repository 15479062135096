import { Accordion, AccordionDetails, AccordionSummary, Grid, styled } from '@mui/material';
import { GroupedAssetAllocation, SubAssetData } from 'common/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import expandMoreIcon from '../../../assets/icons/expander.svg';
import { Typography } from '../../atoms/Typography/Typography';
import { PortfolioIcon } from './PortfolioIcon';
import { PATPalette } from '../../../themes/palette';
import { PortfolioCompositionListReadOnly } from './PortfolioCompositionListReadOnly';

export const StyledPortfolioHeader = styled(Typography)<{ isPdf?: boolean }>(({ theme, isPdf }) => ({
    fontWeight: 600,
    fontSize: isPdf ? '13px' : theme.spacing(2.5),
    marginRight: theme.spacing(2)
}));

export const StyledPortfolioTitle = styled(Typography)<{ isPdf?: boolean }>(({ theme, isPdf }) => ({
    fontWeight: 600,
    fontSize: `${isPdf ? '12px' : theme.spacing(2)}`,
    marginLeft: `${isPdf ? '0' : theme.spacing(2)}`
}));

export const StyledExpandCollapseText = styled(Typography)(({ theme }) => ({
    color: '#416AF4',
    fontWeight: 600,
    fontSize: theme.spacing(2),
    textDecoration: 'underline',
    cursor: 'pointer'
}));

const StyledPortfolioListContainer = styled('div')<{ split?: boolean }>(({ split }) => ({
    minWidth: '500px',
    display: 'flex',
    flexDirection: split ? 'row' : 'column',
    gap: '33px'
}));

export const StyledPortfolioListHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3)
}));

export const StyledPortfolioListItem = styled('div')(() => ({
    display: 'block'
}));

const StyledExpandIcon = styled('img')(({ theme }) => ({
    marginRight: theme.spacing(0)
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

const StyledAccordion = styled(Accordion)(() => ({
    '&.MuiPaper-root': {
        boxShadow: 'none !important'
    }
}));

export const StyledExpanded = styled('div')(() => ({
    display: 'flex',
    // fontSize: theme.spacing(2),
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: '10px',
    paddingBottom: '10px',
    borderBottom: `1px solid ${PATPalette.secondary.grey[200]}`
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '10px'
}));

export const StyledLegendName = styled('div')(({ theme }) => ({
    display: 'flex',
    fontSize: theme.spacing(2),
    alignItems: 'center',
    gap: '10px',
    flex: 1
}));

export const StyledTicker = styled(Typography)<{ split?: boolean }>(({ theme, split }) => ({
    display: 'flex',
    fontWeight: 600,
    fontSize: split ? '12px' : theme.spacing(2),
    margin: split ? theme.spacing(0) : `0 ${theme.spacing(1.8)} 0 ${theme.spacing(5)}`
}));

export const StyledTickerLink = styled('a')(({ theme }) => ({
    display: 'flex',
    fontSize: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontFamily: 'TT Commons Pro',
    '&:visited': {
        color: theme.palette.primary.main
    },
    '&:hover': {
        color: theme.palette.primary.main
    },
    '&:active': {
        color: theme.palette.primary.main
    }
}));

const PortfolioCompositionList = ({
    portfolioListData,
    isPDF = false
}: {
    portfolioListData: any;
    isPDF?: boolean;
}) => {
    const { t } = useTranslation();
    const [groupedPortfolioData] = useState(portfolioListData);
    const [expandedAccordions, setExpandedAccordions] = useState<number[][]>([[], [], []]);
    // TDOO:: setGroupedPortfolioData when doing API integration

    const accordionClicked = (_groupedPortfolioIndex: number, index: number) => {
        // Check if the inner array exists
        if (expandedAccordions[_groupedPortfolioIndex]) {
            // Toggle the expanded state
            const updatedAccordions = [...expandedAccordions];
            updatedAccordions[_groupedPortfolioIndex].includes(index)
                ? (updatedAccordions[_groupedPortfolioIndex] = updatedAccordions[_groupedPortfolioIndex].filter(
                      (item) => item !== index
                  ))
                : updatedAccordions[_groupedPortfolioIndex].push(index);

            setExpandedAccordions(updatedAccordions);
        } else {
            console.log("This group doesn't exist.");
        }
    };

    const collapseAll = (_groupedPortfolioIndex: number) => {
        const updatedAccordions = [...expandedAccordions];
        updatedAccordions[_groupedPortfolioIndex] = [];
        setExpandedAccordions(updatedAccordions);
    };

    const expandAll = (_groupedPortfolioIndex: number) => {
        const updatedAccordions = [...expandedAccordions];
        groupedPortfolioData[_groupedPortfolioIndex].groupedSubAssets?.forEach((__data: SubAssetData, _index: number) =>
            updatedAccordions[_groupedPortfolioIndex].push(_index)
        );
        setExpandedAccordions(updatedAccordions);
    };
    const equity: GroupedAssetAllocation[] = groupedPortfolioData.slice(0, 1);
    const restGroupPortfolioData: GroupedAssetAllocation[] = groupedPortfolioData.slice(1);

    return (
        <>
            {isPDF ? (
                <Grid container flexDirection="row" gap={5} marginBottom={38}>
                    {/* equity */}
                    <Grid item flex={1}>
                        <PortfolioCompositionListReadOnly key="equity" value={equity[0]} />
                    </Grid>

                    {/* cash and fixed income */}
                    <Grid item flex={1}>
                        {restGroupPortfolioData?.map(
                            (value: GroupedAssetAllocation, _groupedPortfolioIndex: number) => (
                                <PortfolioCompositionListReadOnly key={_groupedPortfolioIndex} value={value} />
                            )
                        )}
                    </Grid>
                </Grid>
            ) : (
                <StyledPortfolioListContainer>
                    {groupedPortfolioData?.map(
                        (
                            { type, totalAllocatedvalue, groupedSubAssets }: GroupedAssetAllocation,
                            _groupedPortfolioIndex: number
                        ) => (
                            <StyledPortfolioListItem key={_groupedPortfolioIndex}>
                                <StyledPortfolioListHeader>
                                    <StyledLegendName>
                                        <StyledPortfolioHeader isPdf={isPDF} label={type} aria-label={type} />
                                        <StyledExpandCollapseText
                                            label={
                                                expandedAccordions[_groupedPortfolioIndex].length <
                                                groupedPortfolioData[_groupedPortfolioIndex].groupedSubAssets?.length
                                                    ? t('LABEL_EXPAND_ALL')
                                                    : t('LABEL_COLLAPSE_ALL')
                                            }
                                            aria-label={
                                                expandedAccordions[_groupedPortfolioIndex].length <
                                                groupedPortfolioData[_groupedPortfolioIndex].groupedSubAssets?.length
                                                    ? t('LABEL_EXPAND_ALL')
                                                    : t('LABEL_COLLAPSE_ALL')
                                            }
                                            onClick={() =>
                                                expandedAccordions[_groupedPortfolioIndex].length <
                                                groupedPortfolioData[_groupedPortfolioIndex].groupedSubAssets?.length
                                                    ? expandAll(_groupedPortfolioIndex)
                                                    : collapseAll(_groupedPortfolioIndex)
                                            }
                                        />
                                    </StyledLegendName>
                                    <Typography label={totalAllocatedvalue} variant="headers.h3" />
                                </StyledPortfolioListHeader>

                                {groupedSubAssets?.map(
                                    ({ name, totalAllocatedvalue, data, color }, _groupedSubAssetsIndex) => (
                                        <StyledAccordion
                                            key={_groupedSubAssetsIndex}
                                            onChange={() =>
                                                accordionClicked(_groupedPortfolioIndex, _groupedSubAssetsIndex)
                                            }
                                            expanded={expandedAccordions[_groupedPortfolioIndex].includes(
                                                _groupedSubAssetsIndex
                                            )}
                                        >
                                            <StyledAccordionSummary
                                                expandIcon={
                                                    <StyledExpandIcon src={expandMoreIcon} alt={expandMoreIcon} />
                                                }
                                            >
                                                <StyledLegendName>
                                                    <PortfolioIcon color={color} />
                                                    <StyledPortfolioTitle label={name} aria-label={name} />
                                                </StyledLegendName>
                                                <Typography
                                                    label={`${totalAllocatedvalue}%`}
                                                    variant="body.largeBold"
                                                />
                                            </StyledAccordionSummary>

                                            {data?.map(({ ticker, tickerLink, tickerName, allocatedValue }, _in) => (
                                                <StyledAccordionDetails key={_in}>
                                                    <StyledLegendName>
                                                        <StyledTicker label={ticker} />
                                                        <StyledTickerLink
                                                            href={tickerLink}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {tickerName}
                                                        </StyledTickerLink>
                                                    </StyledLegendName>
                                                    <Typography
                                                        label={`${allocatedValue.toFixed(2)}%`}
                                                        variant="body.large"
                                                    />
                                                </StyledAccordionDetails>
                                            ))}
                                        </StyledAccordion>
                                    )
                                )}
                            </StyledPortfolioListItem>
                        )
                    )}
                </StyledPortfolioListContainer>
            )}
        </>
    );
};

export default PortfolioCompositionList;
