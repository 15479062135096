import { Chip, Typography } from '../../../atoms';
import { Grid, styled } from '@mui/material';
import { GoalTypeEnum } from '../../../../common/types';
import { GoalType } from '../../../molecules/GoalType/GoalType';
import { PATPalette } from '../../../../themes/palette';
import { useTranslation } from 'react-i18next';

export interface PDFTitleProps {
    priority: 'Need' | 'Want' | 'Wish' | 'Dream' | 'onTrack' | 'offTrack' | 'atRisk';
    type: GoalTypeEnum;
    goalName: string;
    goalType: string;
}

const StyledChipContainer = styled('div')(() => ({
    marginBottom: '3px',
    marginLeft: '12px'
}));

const StyledGrid = styled(Grid)(() => ({
    paddingBottom: '8px',
    borderBottom: `1px solid ${PATPalette.secondary.grey[200]}`
}));

export const PDFTitle = ({ priority, goalName, goalType, type }: PDFTitleProps) => {
    const { t } = useTranslation();

    return (
        <StyledGrid container alignItems="center" gap={1}>
            <GoalType type={type} isPDF />
            <Typography label={goalName} variant="headers.h5Bold" />
            <Typography label={` | ${t(goalType?.toUpperCase())}`} variant="headers.h5" />
            <StyledChipContainer>
                <Chip type={priority} />
            </StyledChipContainer>
        </StyledGrid>
    );
};
