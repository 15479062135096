import { Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FTLogo_Black from '../../../../assets/images/pdf/FTLogo_Black.svg';
import GOEDigitalAdvisor from '../../../../assets/images/pdf/GOEDigitalAdvisor.svg';
import hero from '../../../../assets/images/pdf/hero.png';
import brandLogos from '../../../../assets/images/pdf/our-investment-logos.png';
import { Typography } from '../../../atoms';

const ImageContainer = styled('div')(() => ({
    height: '840px',
    width: '850px',
    backgroundImage: `url(${hero})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    position: 'absolute',
    margin: 0,
    padding: 0,
    right: '0',
    top: '0',

    '@media (max-width: 1920px)': {
        height: '840px',
        width: '850px'
    },
    '@media (max-width: 1280px)': {
        height: '640px',
        width: '650px'
    }
}));

const TenantLogo = styled('img')(() => ({
    width: '238px',
    height: '46px'
}));

const GOEDigitalAdvisorLogo = styled('img')(() => ({
    width: '209px',
    height: '22px',
    zIndex: 2,
    position: 'absolute',
    top: '40px',
    right: '30px'
}));

const OurInvestmentsBrand = styled('img')(() => ({
    marginTop: '10px',
    width: '100%',
    height: '40px',
    aspectRatio: 1
}));

const StyledGuarntedDisclaimer = styled('tr')(({ theme }) => ({
    fontSize: '16px',
    fontFamily: 'TT Commons Pro',
    fontWeight: 700,
    marginBottom: '20px',

    '& td': {
        padding: '4px 10px',
        border: `1px solid  ${theme.palette.common.black}`
    }
}));

const StyledClientDetails = styled('td')(({ theme }) => ({
    '& tr': {
        fontSize: '30px',
        fontFamily: 'TT Commons Pro',
        fontWeight: 400,
        color: `${theme.palette.common.black}`,
        paddingTop: '43px',
        display: 'block'
    },

    '& th': {
        fontSize: '30px',
        fontWeight: 600,
        fontFamily: 'TT Commons Pro',
        color: `${theme.palette.common.black}`,
        lineHeight: '7px',
        paddingTop: '21px',
        textAlign: 'left'
    }
}));

const StyledOurInvestmentBrands = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.grey[900]}`,
    fontFamily: 'TT Commons Pro',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600
}));

const StyledHorizontalLine = styled('div')(({ theme }) => ({
    height: '1px',
    flex: 1,
    background: `${theme.palette.common.black}`
}));

const StyledName = styled(Typography)(() => ({
    color: '#FFF',
    fontFamily: 'TT Commons Pro',
    fontSize: '127px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '112px',
    top: '242px',
    right: '136px',
    maxWidth: '441px',
    position: 'absolute'
}));

const StyledFooterDisclaimer = styled(Typography)(() => ({
    color: '#333',
    fontFamily: 'TT Commons Pro',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    marginTop: '30px'
}));

export type PDFLandingPageProps = {
    proposalPlanCreationDate: string;
    proposalPlanLastUpdateDate: string;
    proposalCreatedBy: string;
    proposalCreatedFor: string;
    globalConfig: {
        logo?: string;
        subdomain: string;
        disclaimers: {
            brandName: string;
        };
    };
};

const PDFLandingPage = ({
    proposalCreatedFor,
    proposalCreatedBy,
    proposalPlanCreationDate,
    proposalPlanLastUpdateDate,
    globalConfig
}: PDFLandingPageProps) => {
    const { t } = useTranslation();
    const {
        logo,
        subdomain,
        disclaimers: { brandName }
    } = globalConfig;
    const isFranklinAdviceTenant = subdomain === 'advice';

    return (
        <Grid height={'1000px'} position={'relative'} marginBottom={'40px'}>
            <ImageContainer />
            <StyledName label={'GOE Report'} />
            <GOEDigitalAdvisorLogo src={GOEDigitalAdvisor} alt={'GOE Digital Advisor Image'} />
            <Grid
                width="100%"
                height="265mm"
                container
                justifyContent="space-between"
                flexDirection="column"
                margin={0}
                marginBottom={6}
                padding={2}
            >
                <TenantLogo src={isFranklinAdviceTenant ? FTLogo_Black : logo} alt={brandName} />

                <StyledClientDetails>
                    <tr>{t('PDF_LABEL_PREPARED_FOR')}</tr>
                    <th>{proposalCreatedFor}</th>
                    <tr>{t('PDF_LABEL_PREPARED_BY')}</tr>
                    <th>{proposalCreatedBy}</th>
                    <tr>{t('PDF_LABEL_PLAN_CREATION_DATE')}</tr>
                    <th>{proposalPlanCreationDate}</th>
                    <tr>{`${t('PDF_LABEL_LAST_UPDATED')}:`}</tr>
                    <th>{proposalPlanLastUpdateDate}</th>
                </StyledClientDetails>

                <Grid paddingRight={5}>
                    <StyledGuarntedDisclaimer style={{ bottom: isFranklinAdviceTenant ? '161px' : '65px' }}>
                        <td>{t('PDF_LABEL_NOT_FDIC_INSURED')}</td>
                        <td>{t('PDF_LABEL_MAY_LOSE_MONEY')}</td>
                        <td>{t('PDF_LABEL_NO_BANK_GUARANTEE')}</td>
                    </StyledGuarntedDisclaimer>

                    {isFranklinAdviceTenant && (
                        <>
                            <Grid container alignItems="center" marginTop={2} gap={1}>
                                <StyledOurInvestmentBrands label={'OUR INVESTMENT BRANDS'} />
                                <StyledHorizontalLine />
                            </Grid>
                            <OurInvestmentsBrand src={brandLogos} alt={'brandlogo'} />
                        </>
                    )}
                    <StyledFooterDisclaimer label={t('PDF_LABEL_DISCLAIMER_LANDING_PAGE')} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PDFLandingPage;
