import React, { useEffect, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import { Grid, Popper, styled, Paper, MenuList, MenuItem, IconButton, Grow } from '@mui/material';
import { Grid, styled } from '@mui/material';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
import { formatCurrency, removeNonNumeric } from '../../../utils';
import { Typography } from '../../atoms';
import { PATPalette } from '../../../themes/palette';
import { useTranslation } from 'react-i18next';
import { MoreActionsMenu } from '../MoreActionsMenu';

const StyledContainer = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: '400px',
    borderRadius: theme.spacing(1.5),
    padding: '20px 16px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 2px 8px 0px #0000000D',
    border: `1px solid ${PATPalette.neutral.grey[100]}`
}));

const StyledHeaderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${PATPalette.semantic.stroke.secondary}`
}));

const StyledRightControls = styled('div')(({ theme }) => ({
    padding: `0 ${theme.spacing(0.5)}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const StyledNumberBadge = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(0.5)
}));

const StyledNumberBadgeOpened = styled('div')(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(0.5)
}));

const StyledChevron = styled('div')(() => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const StyledBodyContainer = styled('div')(({ theme }) => ({
    borderBottomLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginTop: '8px'
}));

const StyledAccountContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600,
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:last-child': {
        marginBottom: 0
    }
}));

const StyledAnnualContributionsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
}));

const StyledAnnualContributionsTextLabel = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 500,
    margin: 0,
    color: theme.palette.grey[300]
}));

const StyledAnnualContributionsTextValue = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    margin: 0,
    color: theme.palette.grey[300],
    marginRight: '26px'
}));

const StyledAccountName = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px'
}));

const StyledTotalAnnualContributionsText = styled(Typography)(() => ({
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    margin: 0
}));

const StyledTotalAnnualContributionsValue = styled(Typography)(() => ({
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '20px',
    fontWeight: 500,
    display: 'flex',
    flex: '1 0 0'
}));

const StyledTotalLumSumText = styled(Typography)(() => ({
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    display: 'flex',
    flex: '1 0 0'
}));
const StyledAccountBalance = styled(Typography)(() => ({
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    margin: 0
}));

const StyledGrid = styled(Grid)(() => ({
    justifyContent: 'space-between',
    alignItems: 'center'
}));

export interface Source {
    accountNumber: string;
    accountName: string;
    accountBalance: number;
    isSelected: boolean;
    isRCSelected?: boolean;
    // eslint-disable-next-line no-unused-vars
    onClick?: (accountNumber: string) => void;
    disabled?: boolean;
    readOnly?: boolean;
    frequency?: string;
    recurringContributions?: number;
    recurringContributionsEndDate?: string;
    recurringContributionsStartDate?: string;
    beginningYear?: string;
    endYear?: string;
    // eslint-disable-next-line no-unused-vars
    onEditClick?: (accountNumber: string) => void;
    // eslint-disable-next-line no-unused-vars
    onDeleteClick?: (accountNumber: string) => void;
    showMoreActions?: boolean;
    showContribution?: boolean;
}

export interface MonetarySourceProps {
    accountData: Record<string, Source>;
    // eslint-disable-next-line no-unused-vars
    onAccountChange?: (data: Record<string, Source>) => void;
    title: React.ReactNode;
    label?: string;
    disabled?: boolean;
    readOnly?: boolean;
    icon?: React.ReactNode;
    // eslint-disable-next-line no-unused-vars
    onEditClick?: (accountNumber: string) => void;
    // eslint-disable-next-line no-unused-vars
    onDeleteClick?: (accountNumber: string) => void;
    isOpen?: boolean;
    showMoreActions?: boolean;
    showContribution?: boolean;
}

const Account = ({
    accountBalance,
    accountName,
    accountNumber,
    isSelected,
    onClick,
    disabled,
    readOnly = false,
    frequency,
    recurringContributions,
    onEditClick,
    onDeleteClick,
    showMoreActions,
    showContribution
}: Source) => {
    const { t } = useTranslation();
    // const anchorRef = React.useRef<HTMLButtonElement>(null);
    // const [open, setOpen] = React.useState(false);
    return (
        <div>
            <StyledAccountContainer onClick={() => !disabled && !readOnly && onClick?.(accountNumber)}>
                <StyledAccountName>
                    {!readOnly &&
                        (isSelected ? (
                            <CheckBoxIcon color={disabled ? 'disabled' : 'primary'} sx={{ fontSize: '20px' }} />
                        ) : (
                            <CheckBoxOutlineBlankIcon
                                color="disabled"
                                sx={{ opacity: disabled ? 0.3 : 1, fontSize: '20px' }}
                            />
                        ))}
                    <Typography t={accountName} color="neutral.black" variant="labels.largeDemibold" />
                </StyledAccountName>
                <div style={{ display: 'flex', gap: '18px', alignItems: 'center' }}>
                    <StyledAccountBalance label={`${formatCurrency(accountBalance)}`} color="neutral.black" />
                    {!showMoreActions && (
                        <MoreActionsMenu
                            options={
                                disabled
                                    ? []
                                    : [
                                        {
                                            label: 'Edit',
                                            onClick: () => onEditClick && onEditClick(accountNumber),
                                            icon: 'edit'
                                        },
                                        {
                                            label: 'Delete',
                                            onClick: () => onDeleteClick && onDeleteClick(accountNumber),
                                            icon: 'delete'
                                        }
                                    ]
                            }
                        />
                    )}
                    {/* need for future purpose to fix scroll issue with kebabmenu */}
                    {/* <IconButton ref={anchorRef} onClick={() => setOpen(true)}>Hello</IconButton>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                width: '80px'
                            }}
                            >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                >
                                    <MenuItem onClick={() => setOpen(false)}>Profile</MenuItem>
                                    <MenuItem onClick={() => setOpen(false)}>My account</MenuItem>
                                    <MenuItem onClick={() => setOpen(false)}>Logout</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                        </Grow>
                        )}
                    </Popper> */}
                </div>
            </StyledAccountContainer>
            {readOnly && showContribution && (
                <StyledAnnualContributionsContainer>
                    <StyledAnnualContributionsTextLabel label={t('ANNUAL_CONTRIBUTIONS')} />
                    <StyledAnnualContributionsTextValue
                        label={formatCurrency(
                            frequency === 'Annually'
                                ? Number(removeNonNumeric(recurringContributions))
                                : Number(removeNonNumeric(recurringContributions)) * 12
                        )}
                    />
                </StyledAnnualContributionsContainer>
            )}
        </div>
    );
};

export const MonetarySource = ({
    onAccountChange,
    accountData,
    title,
    label,
    disabled,
    isOpen = false,
    readOnly = false,
    onEditClick,
    onDeleteClick,
    showMoreActions = false,
    showContribution
}: MonetarySourceProps) => {
    const [accounts, setAccounts] = useState({ ...accountData });
    const { t } = useTranslation();
    const [isDropdownOpen, setDropdownOpen] = useState(isOpen);
    const onAccountSelect = (accountNumber: string) => {
        const copyOfAccounts = { ...accounts };
        const selectedAccount = copyOfAccounts[accountNumber];
        if (selectedAccount) {
            selectedAccount.isSelected = !selectedAccount.isSelected;
            setAccounts(copyOfAccounts);
            onAccountChange?.(copyOfAccounts);
        }
    };
    const onRCSelect = (accountNumber: string) => {
        const copyOfAccounts = { ...accounts };
        const selectedAccount = copyOfAccounts[accountNumber];
        if (selectedAccount) {
            selectedAccount.isRCSelected = !selectedAccount.isRCSelected;
            setAccounts(copyOfAccounts);
            onAccountChange?.(copyOfAccounts);
        }
    };

    useEffect(() => {
        setAccounts(accountData);
    }, [accountData]);

    const totalLumSum = Object.values(accounts).reduce(
        (acc: number, current: Source) => acc + current.accountBalance,
        0
    );
    const hasAnnualContributions = Object.values(accounts).some((acc) =>
        Object.keys(acc).includes('recurringContributions')
    );
    let totalAnnualContributions = 0;
    if (showContribution) {
        Object.values(accounts).forEach((item: any) => {
            item.recurringContributions = item.recurringContributions ?? 0;
            totalAnnualContributions += +(item.frequency === 'Annually'
                ? item.recurringContributions
                : item.recurringContributions * 12);
        });
    }
    return (
        <StyledContainer>
            <StyledHeaderContainer>
                <Grid container alignItems="center" gap="12px">
                    <Typography label={title} variant="h5" />
                </Grid>
                <StyledRightControls>
                    <StyledChevron onClick={() => setDropdownOpen(!isDropdownOpen)}>
                        {isDropdownOpen ? (
                            <KeyboardArrowDownIcon color="primary" />
                        ) : (
                            <ChevronRightIcon color="primary" />
                        )}
                    </StyledChevron>
                </StyledRightControls>
            </StyledHeaderContainer>
            {isDropdownOpen ? (
                <>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="body.large" color="neutral.grey.300" label={label} />
                        {readOnly && <StyledTotalLumSumText label={formatCurrency(totalLumSum)} />}
                        <StyledNumberBadgeOpened>
                            <Typography
                                label={
                                    readOnly
                                        ? Object.keys(accounts)?.length
                                        : Object.values(accounts)?.reduce(
                                            (totalChecked, acc) => (acc.isSelected ? totalChecked + 1 : totalChecked),
                                            0
                                        )
                                }
                                variant="body.small"
                                color="inherit"
                            />
                        </StyledNumberBadgeOpened>
                    </Grid>
                    {readOnly && showContribution && (
                        <Grid>
                            <StyledGrid container gap={'8px'}>
                                <StyledTotalAnnualContributionsText label={t('TOTAL_ANNUAL_CONTRIBUTIONS')} />
                                <StyledTotalAnnualContributionsValue label={formatCurrency(totalAnnualContributions)} />
                            </StyledGrid>
                        </Grid>
                    )}

                    {!!Object.values(accounts)?.length && (
                        <StyledBodyContainer>
                            {Object.values(accounts)?.map((source) => (
                                <Account
                                    key={source.accountNumber}
                                    accountBalance={source.accountBalance}
                                    accountName={source.accountName}
                                    accountNumber={source.accountNumber}
                                    isSelected={source.isSelected}
                                    onClick={(accountNumber) => {
                                        onAccountSelect(accountNumber);
                                    }}
                                    disabled={disabled || source.disabled}
                                    readOnly={readOnly}
                                    frequency={source.frequency}
                                    recurringContributions={source.recurringContributions}
                                    onEditClick={onEditClick}
                                    onDeleteClick={onDeleteClick}
                                    showMoreActions={showMoreActions}
                                    showContribution={showContribution}
                                />
                            ))}
                        </StyledBodyContainer>
                    )}
                    {hasAnnualContributions &&
                        window.location.pathname.toLowerCase() !== '/createclient' &&
                        !readOnly && (
                            <>
                                <Grid container justifyContent="space-between" alignItems="center" mb="8px" mt="8px">
                                    <Typography variant="body.large" color="neutral.grey.300" t="RECC_CONT_LABEL" />
                                    <StyledNumberBadgeOpened>
                                        <Typography
                                            label={Object.values(accounts)?.reduce(
                                                (totalChecked, acc) =>
                                                    acc.isRCSelected ? totalChecked + 1 : totalChecked,
                                                0
                                            )}
                                            variant="body.small"
                                            color="inherit"
                                        />
                                    </StyledNumberBadgeOpened>
                                </Grid>
                                {!!Object.values(accounts)?.length && (
                                    <StyledBodyContainer>
                                        {Object.values(accounts)?.map((source) => (
                                            <Account
                                                key={source.accountNumber}
                                                accountBalance={
                                                    source.frequency === 'Annually'
                                                        ? Number(source.recurringContributions)
                                                        : Number(source.recurringContributions) * 12
                                                }
                                                accountName={source.accountName}
                                                accountNumber={source.accountNumber}
                                                isSelected={Boolean(source.isRCSelected)}
                                                onClick={(accountNumber) => {
                                                    onRCSelect(accountNumber);
                                                }}
                                                disabled={source.disabled}
                                                readOnly={false}
                                                frequency={source.frequency}
                                                showMoreActions={showMoreActions}
                                            />
                                        ))}
                                    </StyledBodyContainer>
                                )}
                            </>
                        )}
                </>
            ) : (
                <>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="body.large" color="neutral.grey.300" t={label} />
                        {readOnly && <StyledTotalLumSumText label={formatCurrency(totalLumSum)} />}
                        <StyledNumberBadge>
                            <Typography
                                label={
                                    readOnly
                                        ? Object.keys(accounts)?.length
                                        : Object.values(accounts)?.reduce(
                                            (totalChecked, acc) => (acc.isSelected ? totalChecked + 1 : totalChecked),
                                            0
                                        )
                                }
                                variant="body.small"
                                color="inherit"
                            />
                        </StyledNumberBadge>
                    </Grid>
                    {hasAnnualContributions && window.location.pathname.toLowerCase() === '/createclient' && (
                        <StyledGrid container>
                            <StyledTotalAnnualContributionsText label={t('TOTAL_ANNUAL_CONTRIBUTIONS')} />
                            <StyledTotalAnnualContributionsValue label={formatCurrency(totalAnnualContributions)} />
                        </StyledGrid>
                    )}
                </>
            )}
        </StyledContainer >
    );
};
