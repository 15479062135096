import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { PATPalette } from '../../themes/palette';

interface StickyRegionProps {
    children: ReactNode;
    top?: number;
    zIndex?: number;
    backgroundColor?: string;
}

const StyledStickyRegion = styled('div')<{ top: number; zIndex: number; backgroundColor: string }>(
    ({ top, zIndex, backgroundColor }) => ({
        position: 'sticky',
        top: `${top}px`,
        zIndex,
        backgroundColor
    })
);

export const StickyRegion = (props: StickyRegionProps) => {
    const { children, top = 60, zIndex = 10, backgroundColor = PATPalette.neutral.white } = props;
    return (
        <StyledStickyRegion top={top} zIndex={zIndex} backgroundColor={backgroundColor}>
            {children}
        </StyledStickyRegion>
    );
};
