import React, { useRef, useState } from 'react';

import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogTitleProps,
    FormControlLabel,
    IconButton,
    IconButtonProps,
    Button
} from '@mui/material';
import { styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '../../atoms/Typography/Typography';
import { PATPalette } from '../../../themes/palette';
import { ConfirmationDialog } from '..';
import { useTranslation } from 'react-i18next';

interface Agreement {
    title: string;
    content: string;
}

export interface ClickWrapAgreementProps {
    open: boolean;
    // eslint-disable-next-line no-unused-vars
    onClose: () => void;
    title?: string;
    agreements: Agreement[];
    onComplete: () => void;
}

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paper': {
        maxWidth: '800px'
    }
}));

const StyledHeaderContainer = styled('div')(() => ({
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between'
}));

const StyledDialogTitle = styled(DialogTitle)<DialogTitleProps>(() => ({
    padding: 0
}));

const StyledDialogCloseButton = styled(IconButton)<IconButtonProps>(() => ({
    padding: 0
}));

const StyledDialogContent = styled(DialogContent)(() => ({
    padding: 0,
    height: 'calc(100vh - 72px)'
}));

const StyledBody = styled('div')(() => ({
    display: 'flex',
    height: '100%'
}));

const StyledContentContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}));

const StyledList = styled('ol')(() => ({
    width: '30%',
    minWidth: '240px',
    borderRight: `1px solid ${PATPalette.neutral.grey[200]}`,
    listStyleType: 'none',
    margin: 0,
    padding: 0
}));

const StyledListItem = styled('li')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 24px',
    fontSize: '13px',
    lineHeight: '16px',
    color: PATPalette.neutral.grey[300],
    '&.active': {
        color: PATPalette.neutral.black,
        backgroundColor: PATPalette.secondary.skyBlue[100]
    },
    '&.completed': {
        color: PATPalette.neutral.black
    }
}));

const StyledListItemTypography = styled(Typography)(() => ({
    lineHeight: '16px',
    color: 'inherit'
}));

const StyledCheckCircleIcon = styled(CheckCircleIcon)(() => ({
    fontSize: '16px',
    color: PATPalette.primary.skyBlue
}));

const StyledAgreementContainer = styled('div')(() => ({
    padding: '24px',
    flexGrow: 1,
    overflow: 'auto'
}));

const StyledAgreementTypography = styled(Typography)(() => ({
    '& p': {
        fontFamily: 'TT Commons Pro',
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 500,
        '& strong': {
            fontWeight: 600
        }
    },
    '& span': {
        fontFamily: 'TT Commons Pro',
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 500,
        '& strong': {
            fontWeight: 600
        }
    }
}));

const StyledDialogActions = styled(DialogActions)(() => ({
    padding: '24px',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between'
}));

const StyledButton = styled(Button)(() => ({
    padding: '0',
    textTransform: 'none',
    textDecoration: 'underline',
    backgroundColor: 'none',
    '&.Mui-disabled': {
        backgroundColor: 'transparent'
    },
    '&:hover': {
        backgroundColor: 'transparent',
        color: PATPalette.primary.skyBlue,
        textDecoration: 'underline'
    }
}));

const WIDTH = '100%';

export const ClickWrapAgreement = ({ open, title, onClose, agreements, onComplete }: ClickWrapAgreementProps) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [closeModalState, setCloseModalState] = useState(false);
    const { t } = useTranslation();

    const contentRef = useRef<null | HTMLDivElement>(null);

    const handleScroll = () => {
        if (contentRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 24 && !isScrolledToBottom) setIsScrolledToBottom(true);
        }
    };

    const handleContinueClick = () => {
        if (currentTab + 1 === agreements.length) {
            onComplete();
        } else {
            setCurrentTab((prevTab) => ++prevTab);
            setIsScrolledToBottom(false);
            setIsChecked(false);
            if (contentRef.current) {
                contentRef.current.scrollTo(0, 0);
            }
        }
    };

    const handleCancelClose = async () => {
        setCloseModalState(!closeModalState);
    };

    const handleConfirmClose = () => {
        onClose();
    };

    return (
        <StyledDialog
            open={open}
            onClose={() => {
                setCloseModalState(true);
            }}
            fullWidth
        >
            <ConfirmationDialog
                onCancellation={handleCancelClose}
                onConfirmation={() => handleConfirmClose()}
                open={closeModalState}
                content={t('CLICKWRAP_CONFIRMATION_CONTENT')}
                confirmButtonLabel={t('TEXT_QUIT')}
                cancelButtonLabel={t('BUTTON_CANCEL')}
                title={t('TEXT_ARE_SURE_TO_QUIT')}
                width={WIDTH}
                cardMaxWidth="350px"
                hideClose
            />
            <StyledHeaderContainer>
                <StyledDialogTitle id="confirm-dialog-title">
                    <Typography variant="headers.h4">{title}</Typography>
                </StyledDialogTitle>
                <StyledDialogCloseButton
                    aria-label="close"
                    onClick={() => {
                        setCloseModalState(true);
                    }}
                >
                    <CloseIcon />
                </StyledDialogCloseButton>
            </StyledHeaderContainer>
            <StyledDialogContent dividers>
                <StyledBody>
                    <StyledList>
                        {agreements.map((agreement, index) => (
                            <StyledListItem
                                className={currentTab === index ? 'active' : currentTab > index ? 'completed' : ''}
                                key={index}
                            >
                                <StyledListItemTypography variant="body.small">{`${index + 1}. ${
                                    agreement.title
                                }`}</StyledListItemTypography>
                                {((isScrolledToBottom && currentTab === index) || currentTab > index) && (
                                    <StyledCheckCircleIcon />
                                )}
                            </StyledListItem>
                        ))}
                    </StyledList>
                    <StyledContentContainer>
                        <StyledAgreementContainer ref={contentRef} onScroll={handleScroll}>
                            <StyledAgreementTypography as="div" variant="body.large">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: agreements?.[currentTab]?.content
                                    }}
                                />
                            </StyledAgreementTypography>
                        </StyledAgreementContainer>
                        <StyledDialogActions>
                            <FormControlLabel
                                control={<Checkbox />}
                                onChange={(e: any) => {
                                    setIsChecked(e.target.checked);
                                }}
                                checked={isChecked}
                                label={
                                    <Typography variant="body.large">
                                        {`I have read and agree to the ${agreements[currentTab].title}`}
                                        {/* need to update translation in ui ticket */}
                                    </Typography>
                                }
                                disabled={!isScrolledToBottom}
                            />
                            <StyledButton variant="text" disabled={!isChecked} onClick={handleContinueClick}>
                                {currentTab + 1 === agreements.length ? 'Complete' : 'Continue'}
                            </StyledButton>
                        </StyledDialogActions>
                    </StyledContentContainer>
                </StyledBody>
            </StyledDialogContent>
        </StyledDialog>
    );
};
