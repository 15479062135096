import { Grid } from '@mui/material';
import { Typography } from '../../atoms/Typography/Typography';

interface DateTypographyProps {
    label: number;
    isRetirement?: boolean;
}

export const DateTypography: React.FC<DateTypographyProps> = ({ label, isRetirement }) => (
    <Grid container alignItems="center" gap={1}>
        <Typography variant="inherit" label={label} sx={{ fontWeight: 600 }} />
        {isRetirement && <Typography label="(Retirement)" variant="inherit" />}
    </Grid>
);
