import React from 'react';
import { IconButton, styled } from '@mui/material';
import { Card } from '@mui/material';
import { Typography } from '../Typography/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatCurrency } from '../../../utils';

export interface SavedWidgetProps {
    type: string;
    name: string;
    amount: number;
    frequency: string;
    index?: number;
    onClick?: () => void;
}

const StyledSavedWidgetCard = styled(Card)(({}) => ({
    border: '1px solid #DBDBDB', //should update from theme later
    borderRadius: '8px',
    padding: '12px 16px',
    width: '100% !important'
}));

const StyledActionButton = styled(IconButton)({
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '16px',
    '& .MuiSvgIcon-root': {
        fill: 'black',
        fontWeight: 'bold'
    }
});

const StyledTypography = styled(Typography)({
    lineHeight: 'normal',
    fontFeatureSettings: "'clig' off, 'liga' off",
    letterSpacing: '-0.154px'
});

export const SavedWidget = ({ type, name, amount, frequency, index = 0, onClick = () => {} }: SavedWidgetProps) => {
    return (
        <StyledSavedWidgetCard key={`source-${index}`}>
            <StyledActionButton aria-label="settings" onClick={onClick}>
                <MoreVertIcon />
            </StyledActionButton>
            <StyledTypography variant="body.small" label={`${type}: ${name}`} />
            <StyledTypography variant="headers.h3" label={formatCurrency(amount)} />
            <StyledTypography variant="body.small" label={frequency} />
        </StyledSavedWidgetCard>
    );
};
