import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { CheckboxProps } from '@mui/material';

export interface CheckboxPropsOverride extends CheckboxProps {
    isSelected?: boolean;
}

export const Checkbox = ({ isSelected = false, disabled = false }: CheckboxPropsOverride) => {
    return isSelected ? (
        <CheckBoxIcon color={disabled ? 'disabled' : 'primary'} />
    ) : (
        <CheckBoxOutlineBlankIcon color={disabled ? 'disabled' : 'primary'} />
    );
};
