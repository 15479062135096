import { Grid } from '@mui/material';
import DoughnutChart from './DoughnutChart';
import PortfolioCompositionList from './PortfolioCompositionList';
import { useGetPortfolioCompositionData } from './useGetPortfolioCompositionData';

const PortfolioCompositionContainer = ({
    recommendedPortfolioId,
    allMarketPortfolios
}: {
    recommendedPortfolioId: number;
    allMarketPortfolios: Array<any>;
}) => {
    const { doughnutChartData, groupedPortfolio } = useGetPortfolioCompositionData(
        recommendedPortfolioId,
        allMarketPortfolios
    );
    return (
        <Grid container item direction="row" xs={12} spacing={1} mt={1} mb={3}>
            <Grid item xs={4}>
                <DoughnutChart doughnutChartData={doughnutChartData} groupedPortfolio={groupedPortfolio} />
            </Grid>
            <Grid item xs={8}>
                <PortfolioCompositionList portfolioListData={groupedPortfolio} />
            </Grid>
        </Grid>
    );
};

export default PortfolioCompositionContainer;
