import { Grid, Radio, styled, useTheme } from '@mui/material';
import { PlanInfoAndReRunSectionProps, ReRunOptionType } from 'common/types';
import { useState } from 'react';
import {
    formatCurrencyOrYearWithPlus,
    formatCurrencyOrYearWithMinus,
    formatCurrency,
    spaceToHyphen
} from '../../../utils';
import { BasicButton, Typography } from '../../atoms';
import { InfoTooltip } from '../InfoTooltip';
import { useTranslation } from 'react-i18next';
import { PATPalette } from '../../../themes/palette';

const SectionContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
}));

const StyledRecommendationsList = styled('ul')(() => ({
    paddingLeft: '16px',

    '& li': {
        fontWeight: 500,
        marginTop: '16px',
        fontFamily: 'TT Commons Pro',

        '& span': {
            fontWeight: 600,
            float: 'right'
        }
    }
}));

export interface RecomendationProps {
    isRecomendation?: boolean;
}

const SelectableOptions = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(1),
    border: '1px',
    borderRadius: '11px',
    borderStyle: 'solid',
    borderColor: PATPalette.neutral.grey[100],
    padding: '21px',
    '&:hover': {
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
        cursor: 'pointer'
    }
}));

const InfoSection = styled('div')<RecomendationProps>(({ theme, isRecomendation = false }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(5),
    border: 0,
    borderRight: isRecomendation ? '0 px' : '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[400]
}));

const InfoSectionAnnual = styled('div')<RecomendationProps>(({ theme, isRecomendation = false }) => ({
    display: 'flex',
    flexDirection: !isRecomendation ? 'column' : 'row',
    paddingRight: theme.spacing(5)
}));

const InfoSectionAnnualRDiv = styled('div')<RecomendationProps>(({ theme, isRecomendation = false }) => ({
    display: 'flex',
    marginLeft: !isRecomendation ? 0 : '1px',
    flexDirection: 'column',
    paddingRight: theme.spacing(5)
}));

const InfoSectionAnnualLDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '190px',
    flexDirection: 'column',
    paddingRight: theme.spacing(4)
}));

const PlanReRunSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(5)
}));

const StyledInfoLabel = styled(Typography)(() => ({
    lineHeight: '20px'
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
    marginRight: '13px',
    '&.MuiButtonBase-root': {
        padding: 0
    },
    '&.Mui-checked': {
        color: theme.palette.primary.main
    },
    '&:hover': {
        backgroundColor: 'transparent'
    }
}));
export const PlanInfoAndReRunSection = ({
    initialInvestmentAmount,
    annualContributionsAmount,
    yearsToGoal,
    planReRunOptions,
    isRetirement,
    yearsInRetirement,
    handleReRunButtonClicked,
    isRecomendation = false,
    isPDF
}: PlanInfoAndReRunSectionProps & { isPDF?: boolean }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState<ReRunOptionType | undefined>();
    const isOptionSelected = (option: ReRunOptionType) =>
        Boolean(selectedOption) &&
        option?.optionLabel === selectedOption?.optionLabel &&
        option?.optionValue === selectedOption?.optionValue;
    const handleSelectedOption = (option: ReRunOptionType) => {
        if (isOptionSelected(option)) setSelectedOption(undefined);
        else setSelectedOption(option);
    };

    const mappedData = planReRunOptions?.map((option: ReRunOptionType, key) =>
        option?.optionValue != 0 && Number(option?.optionValue) > 0 ? (
            isPDF ? (
                <li>
                    {option?.optionLabel || ''}{' '}
                    <span>
                        {option.key === 'topUpDecumulation'
                            ? formatCurrencyOrYearWithMinus(option?.optionValue)
                            : option.key === 'recommendedTenure'
                            ? '+' + option?.optionValue + ' years'
                            : formatCurrencyOrYearWithPlus(option?.optionValue)}
                    </span>
                </li>
            ) : (
                <SelectableOptions
                    key={key}
                    data-testid={spaceToHyphen(option?.optionLabel)}
                    onClick={() => handleSelectedOption(option)}
                >
                    {/* Uncontrolled radio input since the whole option is selectable */}
                    <StyledRadio checked={isOptionSelected(option)} />
                    <Typography
                        label={option?.optionLabel || ''}
                        variant="labels.large"
                        sx={{ flex: 1, marginRight: '16px' }}
                    />
                    <Typography
                        label={
                            option.key === 'topUpDecumulation'
                                ? formatCurrencyOrYearWithMinus(option?.optionValue)
                                : option.key === 'recommendedTenure'
                                ? '+' + option?.optionValue + ' years'
                                : formatCurrencyOrYearWithPlus(option?.optionValue)
                        }
                        variant="numbers.small"
                        color="primary.skyBlue"
                    />
                </SelectableOptions>
            )
        ) : null
    );
    // const isRecomendation = mappedData.every((value) => value === false);
    return (
        <SectionContainer>
            <InfoSection isRecomendation={isRecomendation}>
                {/* SBD: No translations */}
                <InfoSectionAnnual isRecomendation={isRecomendation}>
                    <InfoSectionAnnualLDiv>
                        <Grid container alignItems="center">
                            <StyledInfoLabel label={'Initial Investment'} variant="body1" />
                            {!isPDF && (
                                <InfoTooltip message={t('INITIAL_INVESTMENT_INFO_TEXT')} placement="right-end" />
                            )}
                        </Grid>
                        <Typography
                            label={formatCurrency(initialInvestmentAmount)}
                            variant="h2"
                            sx={{ marginBottom: theme.spacing(5), fontWeight: 600 }}
                        />
                    </InfoSectionAnnualLDiv>
                    <InfoSectionAnnualRDiv isRecomendation={isRecomendation}>
                        {/* SBD: No translations */}
                        <Grid container alignItems="center">
                            <StyledInfoLabel t="LABELS_ANNUAL_CONTRIBUTION" variant="body1" />
                            {!isPDF && (
                                <InfoTooltip message={t('ANNUAL_CONTRIBUTIONS_INFO_TEXT')} placement="right-end" />
                            )}
                        </Grid>
                        <Typography
                            label={formatCurrency(annualContributionsAmount)}
                            variant="h2"
                            sx={{ marginBottom: theme.spacing(5), fontWeight: 600 }}
                        />
                    </InfoSectionAnnualRDiv>
                </InfoSectionAnnual>
                <InfoSectionAnnual isRecomendation={isRecomendation}>
                    <InfoSectionAnnualLDiv>
                        {/* SBD: No translations */}
                        <Grid container alignItems="center">
                            <StyledInfoLabel
                                label={isRetirement ? 'Years to Retirement' : 'Years to Goal'}
                                variant="body1"
                            />
                            {!isPDF && (
                                <InfoTooltip
                                    message={t(
                                        isRetirement
                                            ? 'YEARS_TO_RETIREMENT_INFO_TEXT'
                                            : 'WEALTH_YEARS_TO_GOAL_INFO_TEXT'
                                    )}
                                    placement="right-end"
                                />
                            )}
                        </Grid>
                        <Typography
                            label={yearsToGoal}
                            variant="h2"
                            sx={{ marginBottom: theme.spacing(5), fontWeight: 600 }}
                        />
                    </InfoSectionAnnualLDiv>
                    {isRetirement && (
                        <InfoSectionAnnualRDiv isRecomendation={isRecomendation}>
                            <Grid container alignItems="center">
                                <Typography label={'Years in Retirement'} variant="body1" />
                                {!isPDF && (
                                    <InfoTooltip message={t('YEARS_IN_RETIREMENT_INFO_TEXT')} placement="right-end" />
                                )}
                            </Grid>
                            <Typography
                                label={yearsInRetirement}
                                variant="h2"
                                sx={{ marginBottom: theme.spacing(5), fontWeight: 600 }}
                            />
                        </InfoSectionAnnualRDiv>
                    )}
                </InfoSectionAnnual>
            </InfoSection>
            {!isRecomendation && (
                <PlanReRunSection>
                    <Typography
                        t="INCREASE_PROBABILITY_OF_SUCCESS"
                        variant="h2"
                        sx={{ marginBottom: theme.spacing(1) }}
                    />
                    {!isPDF && (
                        <Typography
                            t="SELECT_ONE_OPTION_BELOW"
                            variant="body1"
                            sx={{ marginBottom: theme.spacing(1) }}
                        />
                    )}
                    {isPDF ? <StyledRecommendationsList>{mappedData}</StyledRecommendationsList> : mappedData}
                    {!isPDF && (
                        <BasicButton
                            variant="contained"
                            onClick={() => {
                                if (selectedOption) handleReRunButtonClicked(selectedOption);
                            }}
                            disabled={!selectedOption}
                            sx={{ maxWidth: theme.spacing(25), marginBlock: theme.spacing(2) }}
                        >
                            {t('RE_RUN_PLAN')}
                        </BasicButton>
                    )}
                </PlanReRunSection>
            )}
        </SectionContainer>
    );
};
