import { Divider, Grid, styled, Theme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PATPalette } from '../../../themes/palette';
import { formatCurrency, removeNonNumeric } from '../../../utils';
import { Checkbox, Typography } from '../../atoms';
import { InfoTooltip } from '../InfoTooltip';
import { Source } from '../MonetarySource/MonetarySource';
import { MoreActionsMenu } from '../MoreActionsMenu';

const StyledContainer = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: '400px',
    borderRadius: theme.spacing(1.5),
    padding: '20px 16px',
    marginTop: 12,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 2px 8px 0px #0000000D',
    border: `1px solid ${PATPalette.neutral.grey[100]}`
}));

const StyledAccountValues = styled('div')(() => ({
    display: 'flex',
    gap: '18px',
    alignItems: 'center'
}));

const CountChip = styled('div')(({ theme, checked }: { theme: Theme; checked: boolean }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    height: theme.spacing(3),
    borderRadius: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    color: checked ? theme.palette.common.white : theme.palette.primary.main,
    background: checked ? theme.palette.primary.main : theme.palette.common.white
}));

const StyledAccountContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600,
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:last-child': {
        marginBottom: 0
    }
}));

const StyledAccountName = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px'
}));

const StyledAccountBalance = styled(Typography)(() => ({
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '20px',
    margin: 0
}));

const StyledAnnualContributionsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(3),
    cursor: 'pointer'
}));

const StyledAnnualContributionsText = styled(Typography)(() => ({
    fontSize: '13px',
    margin: 2,
    fontWeight: 500
}));

const StyledBodyContainer = styled('div')(({ theme }) => ({
    borderBottomLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    '&:not(:last-child)': {
        marginBottom: '12px'
    }
}));

const ErrorSpan = styled(Typography)(() => ({
    color: PATPalette.secondary.berry[400],
    display: 'flex',
    fontStyle: 'italic'
}));

export interface FundingSourcesWidgetProps {
    incomeSource: Record<string, Source>;
    // eslint-disable-next-line no-unused-vars
    onIncomeSourceChange: (data: Record<string, Source>) => void;
    investmentSource: Record<string, Source>;
    // eslint-disable-next-line no-unused-vars
    onInvestmentSourceChange: (data: Record<string, Source>) => void;
    // eslint-disable-next-line no-unused-vars
    onEditClick?: (accountNumber: string) => void;
    // eslint-disable-next-line no-unused-vars
    onDeleteClick?: (accountNumber: string) => void;
    errorMsg?: string | null;
}

const Account = ({
    accountBalance,
    accountName,
    accountNumber,
    isSelected,
    onClick,
    disabled,
    frequency,
    recurringContributions,
    onEditClick,
    onDeleteClick,
    showMoreActions = false
}: Source) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const accountRow = (
        <>
            <StyledAccountContainer
                onClick={() => !disabled && onClick?.(accountNumber)}
                sx={{ opacity: disabled ? 0.5 : 1 }}
            >
                <StyledAccountName>
                    <Checkbox isSelected={isSelected} disabled={disabled} />
                    <Typography
                        t={accountName}
                        color="neutral.black"
                        variant="labels.largeDemibold"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: theme.spacing(23)
                        }}
                    />
                </StyledAccountName>
                <StyledAccountValues>
                    <StyledAccountBalance label={formatCurrency(accountBalance)} color="neutral.black" />
                    {showMoreActions && (
                        <MoreActionsMenu
                            options={
                                disabled
                                    ? []
                                    : [
                                          {
                                              label: 'Edit',
                                              onClick: () => onEditClick && onEditClick(accountNumber),
                                              icon: 'edit'
                                          },
                                          {
                                              label: 'Delete',
                                              onClick: () => onDeleteClick && onDeleteClick(accountNumber),
                                              icon: 'delete'
                                          }
                                      ]
                            }
                        />
                    )}
                </StyledAccountValues>
            </StyledAccountContainer>
            {(frequency && recurringContributions) || recurringContributions === 0 ? (
                <StyledAnnualContributionsContainer
                    sx={{ marginRight: showMoreActions ? '1.8em' : 0, opacity: disabled ? 0.5 : 1 }}
                    onClick={() => !disabled && onClick?.(accountNumber)}
                >
                    <StyledAnnualContributionsText label={t('LABELS_ANNUAL_CONTRIBUTION')} />
                    <StyledAnnualContributionsText
                        label={formatCurrency(
                            frequency === 'Annually'
                                ? Number(removeNonNumeric(recurringContributions))
                                : Number(removeNonNumeric(recurringContributions)) * 12
                        )}
                    />
                </StyledAnnualContributionsContainer>
            ) : null}
        </>
    );
    if (disabled)
        return (
            <InfoTooltip message={t('FUNDNG_SOURCE_NOT_AVAILABLE_MSG')} placement="top">
                {accountRow}
            </InfoTooltip>
        );
    return accountRow;
};

export const FundingSourcesWidget = ({
    incomeSource,
    onIncomeSourceChange,
    investmentSource,
    onInvestmentSourceChange,
    onEditClick,
    onDeleteClick,
    errorMsg
}: FundingSourcesWidgetProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const onAccountSelect = (accountNumber: string, type: 'incomeSource' | 'investmentSource') => {
        const isIncomeSource = type === 'incomeSource';
        const copyOfAccounts = isIncomeSource ? { ...incomeSource } : { ...investmentSource };
        const selectedAccount = copyOfAccounts[accountNumber];
        if (selectedAccount) {
            selectedAccount.isSelected = !selectedAccount.isSelected;
            if (isIncomeSource) onIncomeSourceChange(copyOfAccounts);
            else {
                selectedAccount.isRCSelected = !selectedAccount.isRCSelected;
                onInvestmentSourceChange(copyOfAccounts);
            }
        }
    };
    const totalSelectedIncomeSources = () =>
        Object.values(incomeSource)?.reduce(
            (totalChecked, acc) => (acc.isSelected ? totalChecked + 1 : totalChecked),
            0
        );
    const totalSelectedInvestmentSources = () =>
        Object.values(investmentSource)?.reduce(
            (totalChecked, acc) => (acc.isSelected ? totalChecked + 1 : totalChecked),
            0
        );
    return (
        <>
            <Grid>
                <Typography variant="body.large" sx={{ marginBottom: 6 }} label={t('SELECT_FUNDING_SOURCES_HEADING')} />
                {errorMsg && <ErrorSpan variant="subtitle2" role="alert" label={errorMsg} />}
                <StyledContainer>
                    <Grid container justifyContent="space-between" alignItems="center" marginBottom="1em">
                        <Typography variant="h5">
                            <Grid container alignItems="center" gap={0.5}>
                                <Typography label={t('ACCOUNTS_LABEL_TEXT')} variant="h5" />
                                <InfoTooltip message={t('WEALTH_SOURCES_INFO_TEXT')} />
                            </Grid>
                        </Typography>
                        <CountChip theme={theme} checked={totalSelectedInvestmentSources() !== 0}>
                            <Typography
                                label={totalSelectedInvestmentSources() + ' Selected'}
                                variant="body.small"
                                color="inherit"
                            />
                        </CountChip>
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" marginBottom="0.8em">
                        <Typography label={t('TOTAL_CURRENT_WEALTH_LABEL')} variant="body.large" />
                        <Typography
                            label={formatCurrency(
                                Object.values(investmentSource)?.reduce(
                                    (total, acc) => (acc.isSelected ? total + acc.accountBalance : total),
                                    0
                                )
                            )}
                            variant="headers.h4"
                        />
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" marginBottom="1em">
                        <Typography label={t('TOTAL_ANNUAL_CONTRIBUTION_LABEL')} variant="body.large" />
                        <Typography
                            label={formatCurrency(
                                Object.values(investmentSource)?.reduce(
                                    (total, acc) =>
                                        acc.isSelected && acc.recurringContributions && acc.frequency
                                            ? total +
                                              (acc.frequency === 'Monthly'
                                                  ? acc.recurringContributions * 12
                                                  : acc.recurringContributions)
                                            : total,
                                    0
                                )
                            )}
                            variant="headers.h4"
                        />
                    </Grid>
                    {!!Object.values(investmentSource)?.length && (
                        <StyledBodyContainer>
                            {Object.values(investmentSource)?.map((source) => (
                                <Account
                                    key={source.accountNumber}
                                    accountBalance={source.accountBalance}
                                    accountName={source.accountName}
                                    accountNumber={source.accountNumber}
                                    isSelected={source.isSelected}
                                    onClick={(accountNumber) => {
                                        onAccountSelect(accountNumber, 'investmentSource');
                                    }}
                                    disabled={source.disabled}
                                    frequency={source.frequency}
                                    recurringContributions={source.recurringContributions}
                                    onEditClick={onEditClick}
                                    onDeleteClick={onDeleteClick}
                                />
                            ))}
                        </StyledBodyContainer>
                    )}
                    <Divider sx={{ marginBottom: '1em' }} />
                    <Grid container justifyContent="space-between" alignItems="center" marginBottom="1em">
                        <Typography variant="h5">
                            <Grid container alignItems="center" gap={0.5}>
                                <Typography label={t('RETIREMENT_INCOME_LABEL_TEXT')} variant="h5" />
                                <InfoTooltip message={t('INCOME_SOURCES_INFO_TEXT')} />
                            </Grid>
                        </Typography>
                        <CountChip theme={theme} checked={totalSelectedIncomeSources() !== 0}>
                            <Typography
                                label={totalSelectedIncomeSources() + ' Selected'}
                                variant="body.small"
                                color="inherit"
                            />
                        </CountChip>
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" marginBottom="1em">
                        <Typography label={t('TOTAL_ANNUAL_INCOME_LABEL')} variant="body.large" />
                        <Typography
                            label={formatCurrency(
                                Object.values(incomeSource)?.reduce(
                                    (total, acc) => (acc.isSelected ? total + acc.accountBalance : total),
                                    0
                                )
                            )}
                            variant="headers.h4"
                        />
                    </Grid>
                    {!!Object.values(incomeSource)?.length && (
                        <StyledBodyContainer>
                            {Object.values(incomeSource)?.map((source) => (
                                <Account
                                    key={source.accountNumber}
                                    accountBalance={source.accountBalance}
                                    accountName={source.accountName}
                                    accountNumber={source.accountNumber}
                                    isSelected={source.isSelected}
                                    onClick={(accountNumber) => {
                                        onAccountSelect(accountNumber, 'incomeSource');
                                    }}
                                    disabled={source.disabled}
                                    frequency={source.frequency}
                                    onEditClick={onEditClick}
                                    onDeleteClick={onDeleteClick}
                                />
                            ))}
                        </StyledBodyContainer>
                    )}
                </StyledContainer>
            </Grid>
        </>
    );
};
