import { IRows, IColumns } from '../../atoms/Table/Table';
import { DateTypography } from '../../molecules/ExhibitTableDateTypography';

interface AllocationExhibit {
    startingYear: number;
    retirementYear?: number;
    equity: number[];
    fixedIncome: number[];
    cash: number[];
}

export const getAllocationsExhibitTableData = ({
    startingYear,
    retirementYear,
    equity,
    cash,
    fixedIncome
}: AllocationExhibit) => {
    const exhibitColumns: IColumns[] = [
        { name: 'Date', width: '40%', alignment: 'left' },
        { name: 'Equity', alignment: 'right' },
        { name: `Fixed Income`, alignment: 'right' },
        { name: 'Cash', alignment: 'right' }
    ];

    const formatRows = (year: number, index: number, isRetirement = false): React.ReactNode[] | string[] => {
        return [
            <DateTypography key={`date-${year}`} label={year} isRetirement={isRetirement} />,
            `${equity[index] ?? 0}%`,
            `${fixedIncome[index] ?? 0}%`,
            `${cash[index] ?? 0}%`
        ];
    };

    // building the rows
    const exhibitRows: IRows[] = [];
    exhibitRows.push({ id: 'starting-year', values: formatRows(startingYear, 0) });

    if (retirementYear) {
        const retirementIndex = retirementYear - startingYear;
        exhibitRows.push({ id: 'retirement-year', values: formatRows(retirementYear, retirementIndex, true) });
    }
    const lastYearIndex = equity.length - 1;
    const lastYear = startingYear + lastYearIndex;
    exhibitRows.push({ id: 'ending-year', values: formatRows(lastYear, lastYearIndex) });

    return { exhibitColumns, exhibitRows };
};
