import { styled } from '@mui/material';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useCallback, useEffect, useState } from 'react';
import { Typography } from '../../atoms/Typography/Typography';
import { PortfolioIcon } from './PortfolioIcon';
import { DoughnutChartDataType } from 'common/types';

const StyledHighchartContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const StyledLegendContainer = styled('div')(({ theme }) => ({
    fontSize: theme.spacing(3),
    width: '190px',
    marginTop: theme.spacing(5)
}));

const StyledLegendItem = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const StyledLegendName = styled('div')(({ theme }) => ({
    display: 'flex',
    fontSize: theme.spacing(2),
    alignItems: 'center'
}));

const DoughnutChart = ({
    doughnutChartData,
    groupedPortfolio,
    isPDF
}: {
    doughnutChartData: DoughnutChartDataType[];
    groupedPortfolio: any;
    isPDF?: boolean;
}) => {
    const pieChartOptions: Highcharts.Options = {
        credits: {
            enabled: false
        },
        chart: {
            // plotShadow: false,
            height: 256,
            width: 256,
            margin: 0,
            type: 'pie',
            style: {
                fontFamily: 'TT Commons Pro'
            },
            animation: !isPDF
        },
        title: {
            text: ''
        },
        legend: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            valueSuffix: '%',
            headerFormat: '<tspan style="font-size: 15px;">{point.key}</tspan>',
            style: {
                fontSize: '15px'
            }
        },
        plotOptions: {
            pie: {
                //borderRadius: 0,
                animation: !isPDF,
                allowPointSelect: false,
                states: {
                    hover: {
                        enabled: false
                    },
                    inactive: {
                        enabled: false
                    }
                }
            }
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            },
            enabled: false // to remove accessibilty warning on console
        },
        series: [
            {
                dataLabels: {
                    enabled: false
                },
                name: 'Portfolio Composition',
                // @ts-ignore
                innerSize: '50%',
                colorByPoint: true,
                type: 'pie',
                data: [],
                animation: !isPDF
            }
        ]
    };

    const [chartOptions, setChartOptions] = useState(pieChartOptions);

    const updateHighcharts = useCallback(() => {
        setChartOptions((prev) => {
            return {
                ...prev,
                series: [
                    {
                        dataLabels: {
                            enabled: false
                        },
                        name: 'Portfolio Composition',
                        innerSize: '50%',
                        colorByPoint: true,
                        type: 'pie',
                        data: doughnutChartData,
                        animation: !isPDF
                    }
                ]
            };
        });
    }, [doughnutChartData]);

    useEffect(() => {
        updateHighcharts();
    }, [updateHighcharts, doughnutChartData]);

    return (
        <StyledHighchartContainer>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                oneToOne={true}
                updateArgs={[true]}
                aria-label="Allocation interactive chart."
            />

            <StyledLegendContainer>
                {groupedPortfolio?.map((_item: any, _index: number) => (
                    <StyledLegendItem key={_index}>
                        <StyledLegendName>
                            <PortfolioIcon color={_item.color} />
                            <Typography label={_item.type} marginLeft={0.8} />
                        </StyledLegendName>
                        <Typography label={_item.totalAllocatedvalue} variant="body.largeBold" />
                    </StyledLegendItem>
                ))}
            </StyledLegendContainer>
        </StyledHighchartContainer>
    );
};

export default DoughnutChart;
