import palette from './palette';
import { responsiveFontSizes } from './utils';
import { fontFamily } from '../common/constants';
import { CSSProperties } from 'react';
declare module '@mui/material/Typography' {
    export interface TypographyPropsVariantOverrides {
        label: true;
    }
}

declare module '@emotion/react' {
    export interface Theme {
        typography: Typography;
    }
}

const typography = {
    htmlFontSize: 16,
    fontFamily,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,

    // Deprecated, please use updated styles
    h1: {
        color: palette.text.primary,
        fontFamily,
        fontWeight: 300,
        fontSize: '32px',
        lineHeight: '36px',
        letterSpacing: '-0.01562em',
        ...responsiveFontSizes({
            sm: {
                fontSize: 'calc(2rem + (((100vw - 48rem) / (90 - 48))) * (3.125 - 2))',
                lineHeight: 'calc(2.75rem + (((100vw - 48rem) / (90 - 48))) * (2.125 - 1.5))'
            },
            lg: {
                fontSize: '2rem',
                lineHeight: '2.5rem'
            }
        })
    },
    h2: {
        color: palette.text.primary,
        fontFamily,
        fontWeight: 700,
        fontSize: '22px',
        lineHeight: '24px',
        letterSpacing: '-0.00833em',
        ...responsiveFontSizes({
            sm: {
                fontSize: 'calc(1.5rem + (((100vw - 48rem) / (90 - 48))) * (2.125 - 1.5))',
                lineHeight: 'calc( 1.75rem + (((100vw - 48rem) / (90 - 48))) * (2.125 - 1.5))'
            },
            lg: {
                fontSize: '1.5rem',
                lineHeight: '2.25rem'
            }
        })
    },
    h3: {
        color: palette.text.primary,
        fontFamily,
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '20px',
        letterSpacing: '0em',
        ...responsiveFontSizes({
            sm: {
                fontSize: 'calc(1.25rem + (((100vw - 48rem) / (90 - 48))) * (1.875 - 1.25))',
                lineHeight: 'calc(2rem + (((100vw - 48rem) / (90 - 48))) * (1.875 - 1.25))'
            },
            lg: {
                fontSize: '1.25rem',
                lineHeight: '1.875rem'
            }
        })
    },
    h4: {
        color: palette.text.primary,
        fontFamily,
        fontWeight: 800,
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.00735em',
        ...responsiveFontSizes({
            sm: {
                fontSize: 'calc(0.875rem + (((100vw - 48rem) / (90 - 48))) * (1.375 - 0.875))',
                lineHeight: 'calc(1.25rem + (((100vw - 48rem) / (90 - 48))) * (1.375 - 0.875))'
            },
            lg: {
                fontSize: '0.875rem',
                lineHeight: 'inherit'
            }
        })
    },
    h5: {
        color: palette.text.primary,
        fontFamily,
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '1',
        letterSpacing: '0em',
        ...responsiveFontSizes({
            sm: {
                fontSize: 'calc(0.875rem + (((100vw - 48rem) / (90 - 48))) * (1.375 - 0.875))',
                lineHeight: '2.5rem'
            },
            lg: {
                fontSize: '1.125rem',
                lineHeight: 'inherit'
            }
        })
    },
    h6: {
        color: palette.text.grey,
        fontFamily,
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: '1.8rem',
        letterSpacing: '0.0075em'
    },
    body1: {
        color: palette.text.primary,
        fontFamily,
        fontWeight: 400,
        fontSize: 'calc(1rem + (1 - 1) * ((100vw - 20rem) / (120 - 20)))',
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
        margin: '4px 0 5px 0'
    },
    subtitle1: {
        color: palette.text.primary,
        fontFamily,
        fontWeight: 400,
        fontSize: 'calc(1rem + (1 - 1) * ((100vw - 20rem) / (120 - 20)))',
        lineHeight: 1.75,
        letterSpacing: '0.00938em'
    },
    subtitle2: {
        color: palette.text.disabled,
        fontFamily,
        fontWeight: 500,
        fontSize: 'calc(1rem + (0.875 - 1) * ((100vw - 20rem) / (120 - 20)))',
        lineHeight: 1.57,
        letterSpacing: '0.00714em'
    },
    body2: {
        color: palette.text.grey,
        fontFamily,
        fontWeight: 400,
        fontSize: 'calc(1rem + (0.875 - 1) * ((100vw - 20rem) / (120 - 20)))',
        lineHeight: 1.43,
        letterSpacing: '0.01071em'
    },
    caption: {
        color: palette.text.disabled,
        fontFamily,
        fontWeight: 400,
        fontSize: 'calc(1rem + (0.75 - 1) * ((100vw - 20rem) / (120 - 20)))',
        lineHeight: 2.66,
        letterSpacing: '0.03333em'
    },
    button: {
        fontSize: '1rem',
        fontFamily,
        fontWeight: 500
    },

    label: {
        color: '#595959',
        fontFamily,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '18px'
    },

    // Updated styles
    headers: {
        h1: {
            fontFamily,
            fontSize: '48px',
            fontWeight: 600,
            lineHeight: '50px'
        },
        h2: {
            fontFamily,
            fontSize: '30px',
            fontWeight: 600,
            lineHeight: '32px'
        },
        h2Medium: {
            fontFamily,
            fontSize: '30px',
            fontWeight: 500,
            lineHeight: '32px'
        },
        h3: {
            fontFamily,
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '28px'
        },
        h4: {
            fontFamily,
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24px'
        },
        h4Medium: {
            fontFamily,
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '24px',
            textAlign: 'left'
        },
        h4Light: {
            fontFamily,
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left'
        },
        h5Bold: {
            fontFamily,
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '50px',
            textAlign: 'left'
        },
        h5: {
            fontFamily,
            fontSize: '18px',
            lineHeight: '50px',
            textAlign: 'left'
        }
    },
    body: {
        largeBold: {
            fontFamily,
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '20px',
            textAlign: 'left'
        },
        large: {
            all: 'unset',
            fontFamily,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            color: palette.common.black
        },
        smallDemibold: {
            fontFamily,
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: '18px'
        },
        smallSemibold: {
            fontFamily,
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: '18px'
        },
        small: {
            fontFamily,
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '18px'
        },
        xsmall: {
            fontFamily,
            fontSize: '8px',
            fontWeight: 500,
            lineHeight: '18px'
        },
        xsmallDemibold: {
            fontFamily,
            fontSize: '8px',
            fontWeight: 600,
            lineHeight: '18px'
        },
        overline: {
            fontFamily,
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '16px',
            letterSpacing: '0.02em'
        },
        link: {
            small: {
                all: 'unset',
                fontFamily,
                fontSize: '13px',
                fontWeight: 600,
                lineHeight: '18px',
                borderBottom: '1px solid currentColor'
            },
            large: {
                fontFamily,
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '20px',
                borderBottom: '1px solid currentColor'
            }
        },
        caption: {
            fontFamily,
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px'
        }
    },
    labels: {
        largeDemibold: {
            all: 'unset',
            fontFamily,
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '20px'
        },
        large: {
            all: 'unset',
            fontFamily,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            color: '#595959'
        },
        smallDemibold: {
            fontFamily,
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '16px',
            letterSpacing: '0.01em'
        },
        small: {
            fontFamily,
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: '0.01em'
        }
    },
    numbers: {
        largeBold: {
            all: 'unset',
            fontFamily,
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '28px',
            color: '#000000'
        },
        smallBold: {
            fontFamily,
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '20px'
        },
        small: {
            fontFamily,
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '20px'
        }
    },
    tables: {
        headers: {
            fontFamily,
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: '16px',
            letterSpacing: '0.01em'
        },
        rows: {
            fontFamily,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px'
        }
    }
};

type Typography = {
    // New Styles
    headers: {
        h1: CSSProperties;
        h2: CSSProperties;
        h2Medium: CSSProperties;
        h3: CSSProperties;
        h4: CSSProperties;
        h4Medium: CSSProperties;
    };
    body: {
        largeBold: CSSProperties;
        large: CSSProperties;
        smallDemibold: CSSProperties;
        smallSemibold: CSSProperties;
        small: CSSProperties;
        overline: CSSProperties;
        caption: CSSProperties;
        link: {
            small: CSSProperties;
            large: CSSProperties;
        };
    };
    labels: {
        largeDemibold: CSSProperties;
        large: CSSProperties;
        smallDemibold: CSSProperties;
        small: CSSProperties;
    };
    numbers: {
        largeBold: CSSProperties;
        smallBold: CSSProperties;
        small: CSSProperties;
    };
    tables: {
        headers: CSSProperties;
        rows: CSSProperties;
    };
};

export default typography;
