import { Divider, styled } from '@mui/material';
import { useAppSelector } from '../../common/hooks';
import { incomeSources, InvestmentSourcesType } from '../../common/types';
import { Typography } from '../../components/atoms/Typography/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PATPalette } from '../../themes/palette';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../utils';

export function calculateTotalIncome(incomeSources: incomeSources[]) {
    let annualamount = 0;
    incomeSources.forEach((item: any) => {
        annualamount += +(item.frequency === 'Annually' ? item.amount : item.amount * 12);
    });
    return annualamount;
}

export function calculateTotalWealthAvailable(items: readonly InvestmentSourcesType[]) {
    return items.map(({ amount }) => amount).reduce((sum, i) => sum + i, 0);
}
export function calculateAnnualContribution(investmentsSources: InvestmentSourcesType[]) {
    let annualContribution = 0;
    investmentsSources.forEach((item: any) => {
        annualContribution += +(item.frequency === 'Annually'
            ? item.recurringContributions
            : item.recurringContributions * 12);
    });
    return annualContribution;
}

const StyledTotalContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
}));

const StyledListContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    paddingTop: '8px',
    paddingBottom: '20px',
    marginBottom: '12px'
}));

const StyledValueIconContainer = styled('div')(() => ({
    display: 'flex'
}));

const StyledMoreVertIcon = styled(MoreVertIcon)(() => ({
    marginRight: '-12px',
    color: PATPalette.neutral.grey[200]
}));

const StyledFlexContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

const StyledTitle = styled(Typography)(() => ({
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
    margin: 0
}));

const StyledTotal = styled(Typography)(() => ({
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 600,
    margin: 0
}));

const StyledTotalAnnualContributionsText = styled(Typography)(() => ({
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    margin: 0
}));

const StyledAnnualContributionsTextLabel = styled(Typography)(({ theme }) => ({
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 500,
    margin: 0,
    color: theme.palette.grey[300]
}));

const StyledAnnualContributionsTextValue = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    margin: 0,
    marginRight: '12px',
    color: theme.palette.grey[300]
}));

const StyledIncomeTextLabel = styled(Typography)(() => ({
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 600,
    margin: 0,
    overflow: 'hidden',
    width: '180px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
}));

const StyledIncomeTextValue = styled(Typography)(() => ({
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 600,
    margin: 0
}));

const StyledContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}));

const FundingSource = () => {
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const { t } = useTranslation();
    const { incomeSources, investmentsSources } = adviceClient.accounts;

    const totalAnnualIncome = calculateTotalIncome(incomeSources);
    const totalAnnualInvestment = calculateTotalWealthAvailable(investmentsSources);
    const totalAnnualContributions = calculateAnnualContribution(investmentsSources);
    return (
        <StyledContainer>
            {incomeSources.length > 0 && (
                <>
                    <StyledTotalContainer>
                        <StyledTitle label={t('SUBTEXT_FUNDING_SOURCE_INCOME')} />
                        <StyledTotal label={formatCurrency(totalAnnualIncome)} />
                        <Divider sx={{ paddingTop: '12px' }} />
                    </StyledTotalContainer>
                    <StyledListContainer>
                        {incomeSources.map(({ sourceName, frequency, amount }, index) => (
                            <StyledFlexContainer key={`income-source-${index}`}>
                                <StyledIncomeTextLabel label={sourceName} />
                                <StyledValueIconContainer>
                                    <StyledIncomeTextValue
                                        label={formatCurrency((frequency === 'Annually' ? amount : amount * 12) || 0)}
                                    />
                                    <StyledMoreVertIcon />
                                </StyledValueIconContainer>
                            </StyledFlexContainer>
                        ))}
                    </StyledListContainer>
                </>
            )}
            {investmentsSources.length > 0 && (
                <>
                    <StyledTotalContainer>
                        <StyledTitle label={t('SUBTEXT_FUNDING_SOURCE_WEALTH')} />
                        <StyledTotal label={formatCurrency(totalAnnualInvestment)} />
                        <StyledFlexContainer>
                            <StyledTotalAnnualContributionsText label={t('TOTAL_ANNUAL_CONTRIBUTIONS')} />
                            <StyledTotalAnnualContributionsText label={formatCurrency(totalAnnualContributions)} />
                        </StyledFlexContainer>
                        <Divider sx={{ paddingTop: '12px' }} />
                    </StyledTotalContainer>
                    <StyledListContainer>
                        {investmentsSources.map(({ accountName, amount, frequency, recurringContributions }, index) => (
                            <div key={`investment-source-${index}`}>
                                <StyledFlexContainer>
                                    <StyledIncomeTextLabel label={accountName} />
                                    <StyledValueIconContainer>
                                        <StyledIncomeTextValue label={formatCurrency(amount)} />
                                        <StyledMoreVertIcon />
                                    </StyledValueIconContainer>
                                </StyledFlexContainer>
                                <StyledFlexContainer key={`investment-source-annual-contribution-${index}`}>
                                    <StyledAnnualContributionsTextLabel label={t('ANNUAL_CONTRIBUTIONS')} />
                                    <StyledAnnualContributionsTextValue
                                        label={formatCurrency(
                                            frequency === 'Annually'
                                                ? recurringContributions
                                                : recurringContributions * 12 || 0
                                        )}
                                    />
                                </StyledFlexContainer>
                            </div>
                        ))}
                    </StyledListContainer>
                </>
            )}
        </StyledContainer>
    );
};

export default FundingSource;
