import { Card, CardHeader, styled } from '@mui/material';
import Divider from '@mui/material/Divider';
import { PATPalette } from '../../../themes/palette';
import { Typography } from '../../atoms/Typography/Typography';
import { ActionOption, MoreActionsMenu } from '../MoreActionsMenu';
import { formatCurrency } from '../../../utils';

export interface FundSourceChipProps {
    title: string;
    subTitle: string;
    headerIcon?: React.ReactNode | React.ReactNode[];
    amount: number;
    freqcontLabel: string;
    annualContribution?: number;
    count: number;
    action?: boolean;
    actionMenuOptions?: ActionOption[];
}

const StyledFundSourceChipCard = styled(Card)(() => ({
    padding: '20px 16px',
    width: '100% !important',
    borderRadius: '12px',
    background: PATPalette.neutral.white,
    height: '187px',
    margin: 0
}));

const StyledCardHeader = styled(CardHeader)({
    padding: '0',
    paddingBottom: '8px',
    '& .MuiCardHeader-avatar': {
        marginRight: '12px'
    },
    '& .MuiSvgIcon-root': {
        fontWeight: '900'
    }
});

const StyledCircleDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
});

const StyledNumIcon = styled('div')({
    width: '24px',
    height: '24px',
    flexShrink: 0,
    borderRadius: '50px',
    border: `1px solid ${PATPalette.secondary.skyBlue[400]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto'
});

const StyledNum = styled(Typography)(() => ({
    color: PATPalette.secondary.skyBlue[400],
    fontSize: '12px',
    fontWeight: 500,
    lineHeigh: '16px',
    margin: 0
}));

export const StyledTitle = styled(Typography)(() => ({
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '14px',
    fontStyle: 'normal'
}));

const StyledDiv = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const StyledDivider = styled(Divider)({
    borderTop: '1px',
    marginBottom: '10px'
});

const StyledHeaderDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
});

const StyledSubHeading = styled(Typography)({
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    color: PATPalette.neutral.grey[300],
    margin: '0'
});

const StyledHeading = styled(Typography)({
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px'
});

const StyledCurrencyLine = styled(Typography)({
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '28px',
    margin: 0,
    marginBottom: '8px'
});

const StyledFreqCountLabel = styled(Typography)({
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0
});

const StyledAnnualContribution = styled('span')({
    fontSize: '16px',
    color: '#000',
    fontFamily: '"TT Commons Pro"',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px'
});

export const FundSourceChip = ({
    title,
    headerIcon,
    amount,
    count,
    freqcontLabel,
    annualContribution,
    subTitle,
    actionMenuOptions
}: FundSourceChipProps) => {
    const cardHeader = (
        <StyledHeaderDiv>
            <StyledHeading variant="h3" label={title} />
        </StyledHeaderDiv>
    );
    return (
        <StyledFundSourceChipCard>
            <StyledCardHeader
                avatar={headerIcon}
                action={actionMenuOptions ? <MoreActionsMenu options={actionMenuOptions} /> : undefined}
                title={cardHeader}
            ></StyledCardHeader>
            <StyledDivider />
            <StyledCircleDiv>
                <StyledSubHeading label={subTitle} />
                <StyledNumIcon>
                    <StyledNum label={count} />
                </StyledNumIcon>
            </StyledCircleDiv>
            <StyledCurrencyLine label={formatCurrency(amount)} />
            <StyledDiv>
                <StyledFreqCountLabel label={freqcontLabel} />
                &nbsp;
                {annualContribution && (
                    <StyledAnnualContribution>{formatCurrency(annualContribution)}</StyledAnnualContribution>
                )}
            </StyledDiv>
        </StyledFundSourceChipCard>
    );
};
