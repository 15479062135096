import React from 'react';
import { CLIENT_PROFILE_STAGE_KEYS } from '../../common/constants';
import { isStageDisabled } from '../../common/helper';
import { useAppSelector } from '../../common/hooks';
import { RelationshipType } from '../../common/types';
import { RelationshipTree } from '../../components/molecules/RelationshipTree/RelationshipTree';
import { UniversalCard } from '../../components/molecules/UniversalCard/UniversalCard';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccountIcon } from '../../assets/icons/account.svg';
import { HeaderIcon } from './HeaderIcon';

export interface RelationshipsCardProps {
    onClick(): void;
    setOpenDrawer: (flag: boolean) => void;
    setIsRelationship: (flag: boolean) => void;
}

export const RelationshipsCard: React.FC<RelationshipsCardProps> = ({ onClick, setOpenDrawer, setIsRelationship }) => {
    const { t } = useTranslation();
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const addSelfAsPrimaryRel = (relationship: Array<RelationshipType>) => {
        if (!relationship || relationship?.length <= 0) return;
        const selfAsPrimary = {
            firstName: adviceClient?.clientFirstName,
            lastName: adviceClient?.clientLastName,
            age: adviceClient?.clientDob,
            relationship: 'Primary' // Adding self as Primary relationship
        };
        return [selfAsPrimary, ...relationship];
    };

    return (
        <UniversalCard
            header={t('CLIENT_PROFILE_RELATIONSHIPS_HEADING')}
            headerIcon={
                <HeaderIcon
                    isDisabled={isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[1])}
                    icon={<AccountIcon />}
                />
            }
            showOptional
            onClick={() => {}}
            cardSx={
                isStageDisabled(adviceClient, CLIENT_PROFILE_STAGE_KEYS[1])
                    ? {
                          cursor: 'not-allowed!important',
                          '*': { 'pointer-events': 'none', filter: 'grayscale(100%)' }
                      }
                    : {}
            }
            actionMenuOptions={
                adviceClient.relationship?.length > 0
                    ? [
                          {
                              label: `${t('LABEL_EDIT')}`,
                              onClick: () => {
                                  setOpenDrawer(true), setIsRelationship(true);
                              },
                              icon: 'edit'
                          }
                      ]
                    : undefined
            }
        >
            <RelationshipTree
                onSubmit={onClick}
                relationships={addSelfAsPrimaryRel(adviceClient?.relationship) || []}
                loggedInUserName={adviceClient?.clientFirstName || ''}
            />
        </UniversalCard>
    );
};
