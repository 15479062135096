import { Checkbox, Divider, FormControlLabel, Grid, MenuItem, Select } from '@mui/material';
import ColorPickerContainer from '../../components/organisms/SummaryTab/ColorPickerContainer';
import CompanyLogo from '../../components/organisms/SummaryTab/CompanyLogo';
import FinancialAdvisors from '../../components/organisms/SummaryTab/FinancialAdvisors';
import SummaryMetrics from '../../components/organisms/SummaryTab/SummaryMetrics';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BasicButton, Typography } from '../../components/atoms';
import { useAppSelector, useAppDispatch } from '../../common/hooks';
import { updateAdminConfig, updateGlobalLoaderState, updateTenantInfo } from '../../features/global/globalSlice';
import { Localization, Tenant } from '../../utils';
import Palette from '../../themes/palette';
import palette from '../../themes/palette';
import { useEffect, useState } from 'react';

type ConfigType = {
    color: string;
    tenantType: string;
    currencySymbol: string;
    termsOfUse: boolean;
    eula: boolean;
};

export const AdminSummaryTab = () => {
    const { t } = useTranslation();
    const { globalConfig } = useAppSelector((state) => state.global);

    const initialColor = globalConfig?.styles?.config?.layout?.primary;
    const formattedGlobal = globalConfig;
    const dispatch = useAppDispatch();

    const initialFormValues: ConfigType = {
        color: initialColor,
        tenantType: globalConfig.tenantType,
        currencySymbol: globalConfig.currencySymbol,
        termsOfUse: globalConfig.termsOfUse,
        eula: globalConfig.eula
    };

    const { control, formState, handleSubmit, register, reset, setValue, watch } = useForm<ConfigType>({
        mode: 'all',
        reValidateMode: 'onSubmit',
        defaultValues: initialFormValues
    });
    const { errors } = formState;

    const [savedValues, setSavedValues] = useState(initialFormValues);

    const color = watch('color', initialColor);

    useEffect(() => {
        if (globalConfig) {
            const currentSavedValues = {
                color: globalConfig?.styles?.config?.layout?.primary,
                tenantType: globalConfig.tenantType,
                currencySymbol: globalConfig.currencySymbol,
                termsOfUse: globalConfig.termsOfUse,
                eula: globalConfig.eula
            };
            setSavedValues(currentSavedValues);
        }
    }, [globalConfig]);

    const onSubmit = async (data: ConfigType) => {
        dispatch(updateGlobalLoaderState(true));
        const requestedPayload = {
            ...formattedGlobal,
            tenantType: data.tenantType,
            currencySymbol: data.currencySymbol,
            termsOfUse: data.termsOfUse,
            eula: data.eula,
            styles: {
                config: {
                    layout: {
                        primary: data.color
                    }
                }
            }
        };
        await dispatch(updateTenantInfo(requestedPayload));
        dispatch(
            updateAdminConfig({
                color: data.color,
                tenantType: data.tenantType,
                currencySymbol: data.currencySymbol,
                termsOfUse: data.termsOfUse,
                eula: data.eula
            })
        );

        setSavedValues(data);

        reset({}, { keepValues: true });
        dispatch(updateGlobalLoaderState(false));
    };

    const handleCancel = () => {
        reset(savedValues);
    };

    const localizationOptions: { label: string; value: string }[] = Object.keys(Localization).map((eachKey) => {
        const key = eachKey as keyof typeof Localization;
        return {
            label: Localization[key],
            value: eachKey
        };
    });

    const tenantOptions: { label: string; value: string }[] = Object.keys(Tenant).map((eachKey) => {
        const key = eachKey as keyof typeof Tenant;
        return {
            label: Tenant[key],
            value: eachKey
        };
    });
    return (
        <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                    <FinancialAdvisors />
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SummaryMetrics />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Grid mb={3} pb={3} sx={{ borderBottom: `1px solid ${Palette.primary.light}` }}>
                        <CompanyLogo formattedGlobal={formattedGlobal} />
                    </Grid>
                    <ColorPickerContainer
                        reset={reset}
                        formState={formState}
                        setValue={setValue}
                        register={register}
                        color={color}
                        formattedGlobal={formattedGlobal}
                    />

                    <Grid container gap={2} marginTop={3}>
                        <Grid width="100%" item flexDirection="row" container justifyContent="space-between">
                            <Grid width="49%" item>
                                <Typography
                                    sx={{ marginBottom: '5px', color: palette.common.black }}
                                    variant="labels.large"
                                    label={t('TENANT_TYPE')}
                                />
                                <Controller
                                    name="tenantType"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                                        }
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            size="small"
                                            fullWidth
                                            onChange={field.onChange}
                                            value={field.value}
                                            data-testid="tenant-type"
                                            error={!!errors.tenantType}
                                        >
                                            {tenantOptions?.map(({ value, label }) => (
                                                <MenuItem key={value} value={value}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </Grid>
                            <Grid width="49%" item>
                                <Typography
                                    sx={{ marginBottom: '5px', color: palette.common.black }}
                                    variant="labels.large"
                                    label={t('CURRENCY')}
                                />
                                <Controller
                                    name="currencySymbol"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                                        }
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            size="small"
                                            fullWidth
                                            onChange={field.onChange}
                                            value={field.value}
                                            data-testid="localization"
                                            error={!!errors.currencySymbol}
                                        >
                                            {localizationOptions?.map(({ value, label }) => (
                                                <MenuItem key={value} value={value}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Grid item width="100%">
                            <Grid>
                                <Typography label={t('AGREEMENTS')} />
                                <Divider />
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="tenantType"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                                        }
                                    }}
                                    render={({ field }) => (
                                        <Controller
                                            name="termsOfUse"
                                            control={control}
                                            render={({ field: { onChange, value, ref } }) => (
                                                <FormControlLabel
                                                    ref={ref}
                                                    onChange={onChange}
                                                    checked={value}
                                                    control={
                                                        <Checkbox
                                                            sx={{ fontSize: '20px', paddingY: 0, paddingX: '5px' }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            sx={{ padding: 0, margin: 0, color: palette.common.black }}
                                                            variant="body1"
                                                        >
                                                            {t('TERMS_OF_USE')}
                                                        </Typography>
                                                    }
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="eula"
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <FormControlLabel
                                            ref={ref}
                                            onChange={onChange}
                                            checked={value}
                                            control={
                                                <Checkbox sx={{ fontSize: '20px', paddingY: 0, paddingX: '5px' }} />
                                            }
                                            label={
                                                <Typography
                                                    sx={{ padding: 0, margin: 0, color: palette.common.black }}
                                                    variant="body1"
                                                >
                                                    {t('EULA')}
                                                </Typography>
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid mt={4} container gap={2}>
                                <BasicButton
                                    type="submit"
                                    variant="contained"
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    {t('BUTTON_SAVE')}{' '}
                                </BasicButton>
                                <BasicButton onClick={handleCancel} variant="outlined">
                                    {t('BUTTON_CANCEL')}{' '}
                                </BasicButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};
