import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../../assets/logos/logo.svg';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { hex2rgba } from '../../../common/helper';
import { BasicButton, TextboxInput } from '../../atoms';
import { AlertDialog } from '../../molecules/AlertDialog/AlertDialog';
import { useNavigate } from 'react-router-dom';
import { logout, toggleSessionExpiredModal } from '../../../features/auth/authSlice';
import { PATPalette } from '../../../themes/palette';

export const GridContainer: any = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    // '.MuiGrid-root': {
    //     height: '75vh',
    // },
    minHeight: 'calc(100vh - 28px)'
}));

export const LeftContainer: any = styled(GridContainer)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    backgroundColor: theme.palette.layout.primary,
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10%'
}));

export const RightContainer: any = styled(GridContainer)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    background: `${theme.palette.layout.primary} 0.5`,
    backgroundColor: `${hex2rgba(theme.palette.layout.primary, 0.5)}`,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    justifyContent: 'center'
}));

export const ErrorSpan = styled(Typography)(() => ({
    color: PATPalette.secondary.berry[400],
    display: 'flex',
    marginTop: '5px'
}));

export const ItemContainer = styled('div')(({ theme }) => ({
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& a': {
        color: theme.palette.background.paper
    }
}));

const ForgotPasswordLink = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.paper,
    textAlign: 'center',
    cursor: 'pointer',
    ':hover': {
        textDecoration: 'underline'
    }
}));

export const FormContainer: any = styled(GridContainer)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    backgroundColor: theme.palette.layout.primary,
    padding: 30,
    borderRadius: 10,
    maxWidth: '330px',
    minHeight: '75vh'
}));

export const Button = styled(BasicButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    minWidth: '35%',
    color: theme.palette.primary.main,
    textTransform: 'capitalize'
}));

export const WelcomText = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.default
}));

const StyleCheckbox = styled(Checkbox)(() => ({
    color: '#fff',
    '&.Mui-checked': {
        color: '#fff'
    }
}));

interface FormInput {
    username: string;
    password: string;
    rememberMe: boolean;
}

interface LoginFormProps {
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values: FormInput) => void;
}

export const LoginForm = ({ onSubmit }: LoginFormProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const logo = useAppSelector((state) => state.global?.globalConfig?.logo || '');
    const { loading, error } = useAppSelector((state) => state.auth);
    const showSessionExpiredModal = useAppSelector((state) => state.auth.showSessionExpiredModal);
    const [showModal, setShowModal] = useState(showSessionExpiredModal);
    // const [showModal, setShowModal] = useState(true);
    const [serverError, setServerError] = useState({});

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue
    } = useForm<FormInput>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {
            username: '',
            password: '',
            rememberMe: false
        }
    });

    useEffect(() => {
        setServerError(error);
    }, [error]);

    useEffect(() => {
        dispatch(logout());
        (async () => {
            const email = await localStorage.getItem('GOE_APP_REMEMBERED_EMAIL');

            if (email) {
                setValue('username', email);
                setValue('rememberMe', true);
            }
        })();
    }, []);

    const handleCloseModal = () => {
        dispatch(toggleSessionExpiredModal(false));
        setShowModal(false);
    };

    return (
        <GridContainer container>
            <LeftContainer item xs={12} sm={12} md={7} lg={7}>
                <WelcomText variant="h2">{t('TEXT_LOGIN_SCREEN_WELCOME_TEXT_1')}</WelcomText>
                <WelcomText variant="h1" sx={{ fontWeight: 600 }}>
                    {t('TEXT_LOGIN_SCREEN_WELCOME_TEXT_2')}
                </WelcomText>
                <WelcomText variant="h5">{t('TEXT_LOGIN_SCREEN_WELCOME_TEXT_3')}</WelcomText>
            </LeftContainer>

            <RightContainer item xs={12} sm={12} md={5} lg={5}>
                <FormContainer container direction="column">
                    <form onSubmit={loading ? () => {} : handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <ItemContainer>
                            {logo ? (
                                <img
                                    src={logo}
                                    alt="Franklin Templeton"
                                    data-testid="brand-logo"
                                    width="200"
                                    height="auto"
                                />
                            ) : (
                                <Logo title="login" data-testid="brand-logo" />
                            )}
                        </ItemContainer>
                        <ItemContainer>
                            <Typography variant="h5" color="Background">
                                {t('HEADER_LOGIN_SCREEN_FORM')}
                            </Typography>
                        </ItemContainer>

                        <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                            <Controller
                                name="username"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: t('TEXT_CLIENT_INFO_EMAIL_VALIDATION')
                                    }
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextboxInput
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        inputRef={ref}
                                        error={!!errors.username}
                                        type="text"
                                        data-testid="email"
                                        placeholder={t('PLACEHOLDER_LOGIN_SCREEN_EMAIL')}
                                        fullWidth
                                        sx={{ background: '#FFF', borderRadius: 1 }}
                                    />
                                )}
                            />
                            {errors.username ? (
                                <ErrorSpan variant="subtitle2" role="alert">
                                    {errors.username.message}
                                </ErrorSpan>
                            ) : null}
                        </ItemContainer>

                        <ItemContainer sx={{ alignItems: 'flex-start' }}>
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                                    }
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextboxInput
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        inputRef={ref}
                                        error={!!errors.password}
                                        type="password"
                                        placeholder={t('PLACEHOLDER_LOGIN_SCREEN_PASSWORD')}
                                        data-testid="password"
                                        fullWidth
                                        sx={{ background: '#FFF', borderRadius: 1 }}
                                    />
                                )}
                            />
                            {errors.password ? (
                                <ErrorSpan variant="subtitle2" role="alert">
                                    {errors.password.message}
                                </ErrorSpan>
                            ) : null}
                        </ItemContainer>
                        <ItemContainer sx={{ alignItems: 'flex-start', marginTop: '0px' }}>
                            <Controller
                                name="rememberMe"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <FormControlLabel
                                        ref={ref}
                                        onChange={onChange}
                                        checked={value}
                                        control={<StyleCheckbox />}
                                        label={
                                            <Typography variant="body1" color="white">
                                                {t('TEXT_LOGIN_SCREEN_REMEMBER_ME_CHECKBOX_LABEL')}
                                            </Typography>
                                        }
                                    />
                                )}
                            />
                        </ItemContainer>

                        {!isEmpty(serverError) ? (
                            <ErrorSpan variant="subtitle2" role="alert">
                                {t('TEXT_INCORRECT_PASSWORD')}
                            </ErrorSpan>
                        ) : null}

                        <ItemContainer>
                            <Button type="submit" variant="contained" data-testid="login-button">
                                {loading ? (
                                    <CircularProgress color="inherit" size="24px" />
                                ) : (
                                    t('TEXT_LOGIN_SCREEN_BUTTON')
                                )}
                            </Button>
                        </ItemContainer>

                        <ItemContainer>
                            <ForgotPasswordLink
                                variant="subtitle1"
                                onClick={() => {
                                    navigate('/forgot-password');
                                }}
                            >
                                {t('TEXT_LOGIN_SCREEN_FORGOT_PASSWORD_LINK')}
                            </ForgotPasswordLink>
                        </ItemContainer>
                    </form>
                </FormContainer>
            </RightContainer>

            <AlertDialog
                handleClose={handleCloseModal}
                open={showModal}
                content={`${t('SESSION_EXPIRED_MODAL_CONTENT')}`}
                title={t('SESSION_EXPIRED_MODAL_TITLE')}
                aria-label="close-session-expired-popup"
                data-testid="session-expired-modal-close-button"
            />
        </GridContainer>
    );
};
