import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { PersonalInfoAndRelationshipsDrawer } from '../../containers/create-client-step/PersonalInfoAndRelationshipsDrawer';
import { FundingSourcesCard } from '../../containers/create-client-step/FundingSourcesCard';
import { PersonalInfoCard } from '../../containers/create-client-step/PersonalInfoCard';
import { RelationshipsCard } from '../../containers/create-client-step/RelationshipsCard';
import { RiskToleranceCard } from '../../containers/create-client-step/RiskToleranceCard';
import {
    CLIENT_PROFILE_STAGE1,
    CLIENT_PROFILE_STAGE2,
    CLIENT_PROFILE_STAGE3,
    CLIENT_PROFILE_STAGE4,
    CLIENT_PROFILE_STAGE_KEYS
} from '../../common/constants';
import { useNavigate } from 'react-router-dom';

const GridContainer = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gap: theme.spacing(3),
    minWidth: '100%'
}));

export const CreateClientStep = () => {
    const [isRelationship, setIsRelationship] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(location.pathname !== '/createclient') navigate('/createclient', { replace: true })
    }, []);

    return (
        <>
            <GridContainer>
                {CLIENT_PROFILE_STAGE_KEYS.map((stage, key) => {
                    switch (stage) {
                        case CLIENT_PROFILE_STAGE1:
                            return (
                                <PersonalInfoCard
                                    key={key}
                                    onClick={() => {
                                        setOpenDrawer(!openDrawer);
                                        setIsRelationship(false);
                                    }}
                                />
                            );
                        case CLIENT_PROFILE_STAGE2:
                            return (
                                <RelationshipsCard
                                    key={key}
                                    onClick={() => {
                                        setOpenDrawer(!openDrawer);
                                        setIsRelationship(true);
                                    }}
                                    setOpenDrawer={setOpenDrawer}
                                    setIsRelationship={setIsRelationship}
                                />
                            );
                        case CLIENT_PROFILE_STAGE3:
                            return <RiskToleranceCard key={key} />;
                        case CLIENT_PROFILE_STAGE4:
                            return <FundingSourcesCard key={key} />;
                        default:
                            return null;
                    }
                })}
            </GridContainer>
            <PersonalInfoAndRelationshipsDrawer
                setOpenDrawer={setOpenDrawer}
                openDrawer={openDrawer}
                isRelationship={isRelationship}
            />
        </>
    );
};
