import styled from '@emotion/styled';

export type StatusVariant = 'incomplete' | 'complete';

const Container = styled('button')<Omit<VariantObject, 'label'>>(
    ({ borderColor, backgroundColor, theme, width, height }) => ({
        borderRadius: '100px',
        border: '1px',
        borderStyle: 'solid',
        borderColor,
        backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width,
        height,
        ...theme.typography.labels.smallDemibold,
        lineHeight: 'unset'
    })
);

type Props = {
    variant: StatusVariant;
};

export const Status = ({ variant }: Props) => {
    const { label, ...rest } = variants.get(variant) || {};

    if (!label) {
        return null;
    }

    return <Container {...rest}>{label}</Container>;
};

type VariantObject = {
    borderColor?: string;
    backgroundColor?: string;
    label?: string;
    width?: number;
    height?: number;
};

const variants = new Map<StatusVariant, VariantObject>([
    [
        'incomplete',
        {
            borderColor: '#FF6035',
            backgroundColor: '#FFE0D2',
            label: 'Incomplete',
            width: 80,
            height: 32
        }
    ],
    [
        'complete',
        {
            borderColor: '#00BFB3',
            backgroundColor: '#CCF2F0',
            label: 'Complete',
            width: 80,
            height: 32
        }
    ]
]);
