import { Source } from 'components/molecules/MonetarySource/MonetarySource';
import { Dayjs } from 'dayjs';

export interface RelationshipType {
    firstName: string;
    lastName: string;
    age: string;
    relationship: string;
}

export type relationshipInput = {
    firstName: string;
    lastName: string;
    age: string;
    relationship: string;
};

export type InvestmentSourcesType = {
    accountId: string;
    accountName: string;
    accountType: string;
    accountHolder: string;
    location: string;
    amount: number;
    recurringContributions: number;
    frequency: string;
    recurringContributionsStartDate: Dayjs | null;
    recurringContributionsEndDate: Dayjs | null;
    escalationContributions: number | null;
    percentage: string;
};
export type incomeSources = {
    accountId: string;
    sourceName: string;
    sourceType: string;
    amount: number;
    frequency: string;
    beginningYear: string;
    endYear: string;
    escalateIncome: number;
    percentage: string;
    sourceOwner: string;
};

export type FundingSource = {
    investmentsSources: Array<InvestmentSourcesType>;
    incomeSources: Array<incomeSources>;
};

export interface AdviceClientType {
    _id: string;
    clientDob: string;
    clientFirstName: string;
    clientLastName: string;
    tenant: string;
    relationship: Array<RelationshipType>;
    riskProfile: string;
    accounts: FundingSource;
    stages: Array<string>;
    loading: boolean;
    error?: null | undefined | any;
    step: number;
    editMode?: boolean;
}

export type InvestmentSourcesTypeMap = {
    fundingType: string;
    recurringContribution: number;
    escalationPercentage: number;
    amount: number;
    escalationYears: number;
    frequency: string;
    startDate: string;
    endDate: string;
};

export enum GoalPriorityEnum {
    // eslint-disable-next-line no-unused-vars
    NEED = 'Need',
    // eslint-disable-next-line no-unused-vars
    WANT = 'Want',
    // eslint-disable-next-line no-unused-vars
    WISH = 'Wish',
    // eslint-disable-next-line no-unused-vars
    DREAM = 'Dream'
}

export enum WealthGoalType {
    // eslint-disable-next-line no-unused-vars
    BUY_A_HOUSE = 'Buy a House',
    // eslint-disable-next-line no-unused-vars
    BUY_A_CAR = 'Buy a Car',
    // eslint-disable-next-line no-unused-vars
    PAY_FOR_COLLEGE = 'Pay for College',
    // eslint-disable-next-line no-unused-vars
    CUSTOM = 'Custom'
}

// eslint-disable-next-line no-unused-vars
export interface WealthGoalsFormType {
    goalName: string;
    goalAmount: number;
    goalType: string;
    wealthGoalType?: GoalTitleEnum;
    goalPriority?: GoalPriorityEnum;
    goalDate?: Dayjs | null;
    goalId: string;
    sources: { incomeSource: Array<Source>; investmentSource: Array<Source> };
}

export interface RetirementGoalsFormType {
    title?: GoalTitleEnum;
    goalName: string;
    goalAmount: number | null;
    goalType: string;
    wealthGoalType?: GoalTitleEnum;
    goalPriority: GoalPriorityEnum;
    goalId: string;
    sources: { incomeSource: Array<Source>; investmentSource: Array<Source> };
    frequency?: string;
    planStartRetirement: Dayjs | null;
    targetDate?: Dayjs | null;
    yearsInRetirement?: number;
    targetedRetirementIncome: number;
}

export type GoalTypeEnum = 'retirement' | 'bequest' | 'buyahouse' | 'buyacar' | 'payforcollege' | 'custom';
export type GoalTitleEnum = 'Retirement' | 'Bequest' | 'Buy a House' | 'Buy a Car' | 'Pay for College' | 'Custom';

export const GoalTypeTitlemapping = {
    'Buy a House': 'buyahouse',
    'Buy a Car': 'buyacar',
    'Pay for College': 'payforcollege',
    Custom: 'custom',
    Retirement: 'retirement',
    Bequest: 'bequest'
};

export interface GoalTypeProps {
    category?: string;
    size?: 'large' | 'medium' | 'small' | number;
    type: GoalTypeEnum;
    title?: GoalTitleEnum;
    heading?: string;
    iconName?: string;
    onClickHandler?: () => void;
    isFocused?: boolean | GoalTypeEnum;
    disabled?: boolean;
    order?: number;
    backgroundColor?: string;
    darkBackground?: string;
    color?: string;
    icon?: string;
}

export interface ClientStagesType {
    order: number;
    key: string;
    optional: boolean;
}

export interface ReRunOptionType {
    optionLabel: string;
    key: string;
    // typeof optionValue is number for curreny and string for years
    optionValue: number | string;
}

export interface PlanInfoAndReRunSectionProps {
    initialInvestmentAmount: number;
    annualContributionsAmount: number;
    yearsToGoal: string;
    isRetirement?: boolean;
    yearsInRetirement?: string;
    planReRunOptions: Array<ReRunOptionType>;
    isRecomendation?: boolean;
    // eslint-disable-next-line no-unused-vars
    handleReRunButtonClicked: (selectedOption?: ReRunOptionType) => void;
}
export type AssetType = 'Equity' | 'Fixed Income' | 'Alternatives';

export interface DoughnutChartDataType {
    name: string;
    y: number;
    color: string;
    subAssetClass: string;
    ticker: string;
    tickerLink: string;
}
export interface GroupedAssetAllocation {
    type: string;
    totalAllocatedvalue: string;
    color: string;
    groupedSubAssets: SubAssetGroup[];
}

export interface SubAssetGroup {
    name: string;
    data: SubAssetData[];
    totalAllocatedvalue: string;
    color: string;
}

export interface SubAssetData {
    allocatedValue: number;
    subAssetClass: string;
    ticker: string;
    tickerLink: string;
    tickerName: string;
}
// disable IWS AEX-1611
// export type IwsGoalResponseType = {
//     fundedStatus: string;
//     goal: string;
//     goalId: string;
//     minInitialNeeded: number;
//     origCurrWealth: number;
//     shortfall: number;
//     wealthSplit: number;
// };
