import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../common/hooks';
import { fetchAdvisorAdminFAClients } from '../../features/adviceAdmin';
import { DataTable, IRows, TABLE_VARIANT } from '../../components/atoms/Table/Table';
import { Typography } from '../../components/atoms/Typography/Typography';
import { formatCurrency, toShortDate } from '../../utils';

export const ClientsViewOnly = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const clients = useAppSelector((state) => state.advisorAdmin.clients || []);
    const financialAdvisors = useAppSelector((state) => state.advisorAdmin.financialAdvisors || []);
    const admins = useAppSelector((state) => state.advisorAdmin.admins || []);
    const selectedUser = useAppSelector((state) => state.advisorAdmin.selectedUser);

    let listOfAdvisors: {
        label: string;
        value: string;
    }[] = [];

    financialAdvisors.forEach((financialAdvisor) => {
        listOfAdvisors.push({
            label: financialAdvisor.advisorName,
            value: financialAdvisor.id
        });
    });
    admins.forEach((admin) => {
        listOfAdvisors.push({
            label: `${admin.adminName} (Admin)`,
            value: admin.id
        });
    });

    useEffect(() => {
        dispatch(fetchAdvisorAdminFAClients(selectedUser?.id || ''));
    }, []);

    const columnsTranslation = {
        client_full_name: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_1'),
        client_email: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_2'),
        advisor_name: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_3'),
        goals: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_4'),
        portfolio_value: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_5'),
        last_modified: t('TEXT_ADMIN_CLIENTS_TABLE_HEADER_6')
    };

    const columns = [
        { name: columnsTranslation.client_full_name },
        { name: columnsTranslation.client_email },
        { name: columnsTranslation.advisor_name },
        { name: columnsTranslation.goals },
        { name: columnsTranslation.portfolio_value },
        { name: columnsTranslation.last_modified }
    ];
    const clientRows: IRows[] = clients.map((client) => {
        return {
            key: client.id,
            id: client.id,
            values: [
                client.clientFullName,
                client.clientEmail,
                client.advisorName,
                client.goals.length,
                client.portfolioValue && typeof +client.portfolioValue === 'number'
                    ? formatCurrency(+client.portfolioValue)
                    : '-',
                toShortDate(client.lastModified?.toString()) ?? '-'
            ]
        };
    });

    return (
        <>
            <Typography variant="h6" tabIndex={0} label={t('TEXT_ADMIN_CLIENTS_HEADER')} />

            <DataTable
                rows={clientRows}
                columns={columns}
                showPagination
                allowFilter
                variant={TABLE_VARIANT.STYLED_COLUMN}
            />
        </>
    );
};
