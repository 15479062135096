import { useEffect, useMemo, useState } from 'react';
import { DrawerComp, FundingSourcesWidget, InfoTooltip } from '../../components/molecules';
import { styled } from '@mui/material/styles';
import { TextboxInput, ComboSelect, Typography, BasicDatePicker } from '../../components/atoms';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../common/hooks';
import { createGoal, updateBanner, updateGoal } from '../../features/client-goals';
import { WealthGoalsFormType, GoalPriorityEnum, GoalTypeProps } from '../../common/types/clients-entity';
import dayjs, { Dayjs } from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { WEALTH_GOAL } from '../../common/constants';
import { Source } from '../../components/molecules/MonetarySource/MonetarySource';
import { removeNonNumeric, deepEqual, sortObjectKeysAlphabetically, formatCurrency } from '../../utils';
import {
    convertDateFormat,
    convertDateFormatReverse,
    getHeaderIconFromType,
    getYYYYMMDDfromDate
} from '../../common/helper';
import { updateGlobalLoaderState } from '../../features/global/globalSlice';
import { GoalType } from '../../components/molecules/GoalType/GoalType';
import { Grid } from '@mui/material';
import { customPalette, PATPalette } from '../../themes/palette';
import { ErrorSpan } from '../../containers/retirement-goals-drawer';

export interface WealthGoalProps {
    // eslint-disable-next-line no-unused-vars
    handleClose: (flag: boolean) => void;
    onSubmit?: () => void;
    loading?: boolean;
    heading?: string;
    subHeading?: string;
    selectedGoal?: boolean | GoalTypeProps;
    goalData?: any;
    open: boolean;
    currency?: string;
}

export const ItemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '8px',
    '& a': {
        color: theme.palette.background.paper
    }
}));

export const Footer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    fontSize: '10px',
    height: '100%'
}));

export const ItemContainerSplit = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: '8px',
    '& a': {
        color: theme.palette.background.paper
    }
}));

const GoalFormWrapper = styled('form')(() => ({
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    fontSize: '14px'
}));

export const SavedFundsWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
}));

export const Wrap = styled('div')(() => ({
    position: 'relative'
}));

export const StyledInfoText = styled(Typography)(() => ({
    variant: 'body1',
    fontStyle: 'italic',
    color: customPalette.universalCard.subTextFontColor,
    fontSize: '16px',
    lineHeight: '12px',
    fontWeight: 400,
    margin: 0
}));

interface FrequencyProps {
    selected: boolean;
}

export const Frequency = styled('div')<FrequencyProps>(({ selected }) => ({
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    aspectRatio: '1/1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    cursor: 'pointer',
    backgroundColor: selected ? '#E0E0E0' : 'transparent'
}));

export const Placeholder = styled('div')(() => ({
    height: '21px'
}));

export const WealthGoals = ({ handleClose, selectedGoal, open, goalData }: WealthGoalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        orgInfo,
        globalConfig: { currencySymbol }
    } = useAppSelector((state) => state.global);
    const currency = currencySymbol === 'en' || currencySymbol === 'es' ? '$' : 'CAD $';
    const clientData = useAppSelector((state) => state.adviceClient);
    const adviceGoals = useAppSelector((state) => state.adviceGoals || []);
    const originalGoalsDataWealth: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
    const banner = adviceGoals?.global?.banner;
    const investmentSourceData = useMemo(
        () =>
            clientData?.accounts.investmentsSources.reduce((acc: Record<string, Source>, curr) => {
                acc[curr.accountId] = {
                    accountBalance: curr.amount,
                    accountNumber: curr.accountId,
                    accountName: curr.accountName,
                    frequency: curr.frequency,
                    isSelected: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.investmentSource?.some(
                                  (inv: { accountNumber: string; isSelected: boolean }) =>
                                      inv.accountNumber === curr.accountId &&
                                      inv.isSelected &&
                                      goal?.goalId === goalData?.goalId
                              )
                          )
                        : false,
                    recurringContributionsStartDate: getYYYYMMDDfromDate(dayjs(curr.recurringContributionsStartDate)),
                    recurringContributionsEndDate: getYYYYMMDDfromDate(dayjs(curr.recurringContributionsEndDate)),
                    isRCSelected: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.investmentSource?.some(
                                  (inv: { accountNumber: string; isSelected: boolean }) =>
                                      inv.accountNumber === curr.accountId &&
                                      inv.isSelected &&
                                      goal?.goalId === goalData?.goalId
                              )
                          )
                        : false,
                    recurringContributions: curr.recurringContributions || 0,
                    disabled: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.investmentSource?.some(
                                  (inv: { accountNumber: string; isSelected: boolean }) =>
                                      inv.accountNumber === curr.accountId &&
                                      inv.isSelected &&
                                      goal?.goalId !== goalData?.goalId
                              )
                          )
                        : adviceGoals.formFields?.some((goal) =>
                              goal?.data?.sources?.investmentSource?.some(
                                  (inv: { accountNumber: string; isSelected: boolean }) =>
                                      inv.accountNumber === curr.accountId && inv.isSelected
                              )
                          )
                };
                return acc;
            }, {}),
        [adviceGoals.formFields, open]
    );
    const incomeSourceData = useMemo(
        () =>
            clientData?.accounts.incomeSources.reduce((acc: Record<string, Source>, curr) => {
                acc[curr.accountId] = {
                    accountBalance: curr.frequency === 'Annually' ? curr.amount : 12 * curr.amount,
                    accountNumber: curr.accountId,
                    accountName: curr.sourceName,
                    beginningYear: curr.beginningYear,
                    endYear: curr.endYear,
                    frequency: curr.frequency,

                    isSelected: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                            goal?.data?.sources?.incomeSource?.some(
                                (inc: any) =>
                                    inc.accountNumber === curr.accountId &&
                                    inc.isSelected &&
                                    goal?.goalId === goalData?.goalId
                            )
                        )
                        : false,
                    disabled: goalData
                        ? adviceGoals.formFields?.some((goal) =>
                            goal?.data?.sources?.incomeSource?.some(
                                (inc: any) =>
                                    inc.accountNumber === curr.accountId &&
                                    inc.isSelected &&
                                    goal?.goalId !== goalData?.goalId
                            )
                        )
                        : adviceGoals.formFields?.some((goal) =>
                            goal?.data?.sources?.incomeSource?.some(
                                (inc: any) => inc.accountNumber === curr.accountId
                            )
                        )
                };
                return acc;
            }, {}),
        [adviceGoals.formFields, open]
    );

    const [incomeSource, setIncomeSource] = useState(incomeSourceData);
    const [investmentSource, setInvestmentSource] = useState(investmentSourceData);
    const initialValues = {
        goalAmount: 0,
        goalDate: null as Dayjs | null,
        goalType: 'Wealth Goal',
        goalId: '',
        goalName: '',
        goalPriority: undefined
    };
    const {
        control,
        formState: { errors, isValid },
        trigger,
        reset,
        getValues,
        setValue,
        watch,
        setError,
        clearErrors
    } = useForm<WealthGoalsFormType>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: initialValues
    });

    const incomeSourceSelected = Object.values(incomeSource).filter((source) => source.isSelected);
    const investmentSourceSelected = Object.values(investmentSource).filter((source) => source.isSelected);
    const [fundingSourceError, setFundingSourceError] = useState<string | null>(null);

    const submitFunds = async () => {
        try {
            const data = getValues();
            await trigger();

            if (
                !goalData &&
                isValid &&
                clientData?._id &&
                typeof selectedGoal !== 'boolean' &&
                (incomeSourceSelected.length > 0 || investmentSourceSelected.length > 0)
            ) {
                const isDuplicateName = adviceGoals?.formFields
                    .filter((goal) => (typeof selectedGoal !== 'boolean' ? goal.goalId !== goalData?.goalId : true))
                    .some((i) => i.data.goalName.toLowerCase() === data.goalName.toLowerCase());
                if (isDuplicateName) {
                    setError('goalName', { message: t('DUPLICATE_NAME') });
                    return;
                } else {
                    clearErrors('goalName');
                }
                const generatedId = uuidv4();
                data.goalType = WEALTH_GOAL;
                data.wealthGoalType = goalData ? goalData?.title : selectedGoal?.title;
                data.sources = {
                    incomeSource: incomeSourceSelected,
                    investmentSource: investmentSourceSelected
                };

                let amountVal = data['goalAmount'];
                var formatedAmountVal = Number(removeNonNumeric(amountVal));
                data['goalAmount'] = formatedAmountVal;
                data['goalDate'] = getYYYYMMDDfromDate(data['goalDate']);
                const nextIndex = !adviceGoals?.formFields ? 0 : adviceGoals.formFields.length + 1;
                const newFormField = {
                    goalId: generatedId,
                    data: {
                        ...data,
                        index: nextIndex,
                        goalId: generatedId,
                        createdAt: new Date().toISOString()
                    }
                };
                let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
                const updatedGoalsData = Object.assign({}, originalGoalsData);
                updatedGoalsData.formFields = updatedGoalsData?.formFields
                    ? [...updatedGoalsData?.formFields, newFormField]
                    : [newFormField];
                updatedGoalsData.domainEmail = orgInfo?.orgEmail ?? '';
                updatedGoalsData.goeConfigEmail = orgInfo?.orgEmail ?? '';
                updatedGoalsData.riskProfile = clientData.riskProfile;
                updatedGoalsData.assignedClientId = clientData._id;
                updatedGoalsData.error = false;
                updatedGoalsData.loading = false;
                dispatch(updateGlobalLoaderState(true));
                if (updatedGoalsData.global?.hasRunPipe) {
                    updatedGoalsData.global = {
                        ...updatedGoalsData.global,
                        status: 'incomplete',
                        banner: {
                            ...updatedGoalsData.global.banner,
                            visible: true,
                            type: 'attention',
                            isGoalSummary: true,
                            message: t('GOAL_DELETE_BANNER_ATTENTION_MESSAGE_SUMMARY')
                        }
                    };
                }
                if (updatedGoalsData.global?.enablePDFGenerate) {
                    updatedGoalsData.global.enablePDFGenerate = false;
                }
                !updatedGoalsData._id
                    ? await dispatch(createGoal(updatedGoalsData))
                    : await dispatch(updateGoal(updatedGoalsData));
                dispatch(updateGlobalLoaderState(false));
                reset();
                setIncomeSource(incomeSourceData);
                setInvestmentSource(investmentSourceData);
                handleClose(false);
            } else if (typeof selectedGoal !== 'boolean' && goalData) {
                handleEditClick();
            }
        } catch (error) {
            console.error('Error in creating goals', error);
            dispatch(updateGlobalLoaderState(false));
        }
    };

    const handleGoalPriorityChange = (selectedValue: GoalPriorityEnum) => {
        setValue('goalPriority', selectedValue, { shouldValidate: true });
    };

    const handleEditClick = async () => {
        await trigger();
        if (isValid) {
            const data = getValues();
            const isDuplicateName = adviceGoals?.formFields
                .filter((goal) => (typeof selectedGoal !== 'boolean' ? goal.goalId !== goalData?.goalId : true))
                .some((i) => i.data.goalName.toLowerCase() === data.goalName.toLowerCase());
            if (isDuplicateName) {
                setError('goalName', { message: t('DUPLICATE_NAME') });
                return;
            } else {
                clearErrors('goalName');
            }
            dispatch(updateGlobalLoaderState(true));
            let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
            const updatedGoalsData = Object.assign({}, originalGoalsData);
            updatedGoalsData?.formFields.forEach((item) => {
                const itemData = item['data'];
                if (item.goalId === goalData?.goalId && item.goalType != 'retirement') {
                    itemData.sources = {
                        incomeSource: incomeSourceSelected,
                        investmentSource: investmentSourceSelected
                    };
                    let amountVal = data['goalAmount'];
                    var formatedAmountVal = Number(removeNonNumeric(amountVal));
                    itemData['goalAmount'] = formatedAmountVal;
                    itemData['goalDate'] =
                        typeof data['goalDate'] === 'string'
                            ? convertDateFormatReverse(data['goalDate'])
                            : getYYYYMMDDfromDate(data['goalDate']);
                    itemData['goalPriority'] = data['goalPriority'];
                    itemData['goalName'] = data['goalName'];
                    itemData['updatedAt'] = new Date().toISOString();
                }
            });
            if (goalData && !deepEqual(originalGoalsDataWealth.formFields, updatedGoalsData.formFields)) {
                if (updatedGoalsData.global.hasRunPipe) {
                    updatedGoalsData.global.status = 'incomplete';
                }
                if (updatedGoalsData.global?.enablePDFGenerate) {
                    updatedGoalsData.global.enablePDFGenerate = false;
                }
                await dispatch(updateGoal(updatedGoalsData));
                dispatch(
                    updateBanner({
                        ...banner,
                        visible: true,
                        type: 'attention',
                        isGoalSummary: true,
                        message: t('GOAL_DELETE_BANNER_ATTENTION_MESSAGE_SUMMARY')
                    })
                );
            }
            reset();
            setIncomeSource(incomeSourceData);
            setInvestmentSource(investmentSourceData);
            dispatch(updateGlobalLoaderState(false));
            handleClose(false);
        }
    };

    useEffect(() => {
        if (goalData) {
            reset({
                goalAmount: goalData?.goalAmount,
                goalId: goalData?.goalId,
                goalPriority: goalData?.goalPriority,
                goalName: goalData?.goalName,
                goalDate: convertDateFormat(goalData?.targetDate) // it should be "01-20-2056" - mm-dd-yyyy
            });
            setIncomeSource(incomeSourceData);
            setInvestmentSource(investmentSourceData);
        }
    }, []);

    const handleAmountChange = (value: string) => {
        const numericValue = Number(removeNonNumeric(value));
        setValue('goalAmount', isNaN(numericValue) ? 0 : numericValue, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const checkIfFormIsEdited = () => {
        // goalData not falsy means edit mode else add mode
        let currentGoal = goalData ? JSON.parse(JSON.stringify(goalData)) : JSON.parse(JSON.stringify(initialValues));
        let data = watch();
        // custom hasChanged logic for custom input component FundingSourcesWidget
        const fundingSourceHasChanged =
            !Object.values(investmentSourceData).every(
                (inv) =>
                    (inv.isSelected &&
                        investmentSourceSelected.find(
                            (invSelected) => inv.accountNumber === invSelected.accountNumber
                        )) ||
                    (!inv.isSelected &&
                        !investmentSourceSelected.find(
                            (invSelected) => inv.accountNumber === invSelected.accountNumber
                        ))
            ) ||
            !Object.values(incomeSourceData).every(
                (inv) =>
                    (inv.isSelected &&
                        incomeSourceSelected.find((invSelected) => inv.accountNumber === invSelected.accountNumber)) ||
                    (!inv.isSelected &&
                        !incomeSourceSelected.find((invSelected) => inv.accountNumber === invSelected.accountNumber))
            );
        if (currentGoal?.title) delete currentGoal['title'];
        if (currentGoal?.targetDate) {
            currentGoal.goalDate = convertDateFormat(currentGoal.targetDate);
            delete currentGoal.targetDate;
        }
        if (currentGoal?.goalType) {
            delete currentGoal.isIWSApplied;
            delete currentGoal.riskProfile;
            delete currentGoal.title;
            delete currentGoal.accounts;
            if (currentGoal?.recommendedWealthSplit != undefined) delete currentGoal.recommendedWealthSplit;
            data.goalType = currentGoal.goalType;
        }

        return !(
            JSON.stringify(sortObjectKeysAlphabetically(currentGoal)) ===
                JSON.stringify(sortObjectKeysAlphabetically(data)) && !fundingSourceHasChanged
        );
    };

    const checkFundingSourceError = (source: Record<string, Source>, type: 'income' | 'investment') => {
        if (
            (!Object.values(source).find((sc) => sc.isSelected) &&
                type === 'income' &&
                investmentSourceSelected?.length === 0) ||
            (!Object.values(source).find((sc) => sc.isSelected) &&
                type === 'investment' &&
                incomeSourceSelected?.length === 0)
        )
            setFundingSourceError(t('FUNDING_SOURCES_ERROR_MSG'));
        else setFundingSourceError(null);
    };

    return (
        <DrawerComp
            open={open}
            buttonlabel="Complete"
            header={t(
                goalData
                    ? goalData?.title?.toUpperCase()
                    : typeof selectedGoal !== 'boolean'
                        ? selectedGoal?.heading?.toUpperCase()
                        : ''
            )}
            drawerIcon={
                <GoalType
                    type={
                        getHeaderIconFromType(
                            goalData
                                ? goalData?.title
                                : typeof selectedGoal !== 'boolean'
                                    ? selectedGoal?.title
                                    : 'Buy a House'
                        )!
                    }
                />
            }
            handleClose={() => {
                reset();
                setIncomeSource(incomeSourceData);
                setInvestmentSource(investmentSourceData);
                setFundingSourceError(null);
                handleClose(false);
            }}
            onSubmit={submitFunds}
            disableSubmit={!isValid || (incomeSourceSelected.length === 0 && investmentSourceSelected.length === 0)}
            hasChange={checkIfFormIsEdited()}
        >
            <GoalFormWrapper>
                <StyledInfoText>{t('TEXT_ALL_REQUIRED_FIELDS')}</StyledInfoText>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Typography
                        variant="labels.large"
                        sx={{ color: PATPalette.neutral.grey[300] }}
                        label={`${t('TEXT_GOAL_NAME')} *`}
                    />
                    <Controller
                        name="goalName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            },
                            pattern: {
                                value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s'(),.-]*$/,
                                message: t('TEXT_FULLNAME_VALIDATION')
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                size="small"
                                error={!!errors.goalName}
                                type="text"
                                data-testid="wealth-goal-name"
                                placeholder={t('TEXT_GOAL_NAME')}
                                fullWidth
                            />
                        )}
                    />
                    {errors.goalName ? (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.goalName.message} />
                    ) : null}
                </ItemContainer>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Grid container alignItems="center">
                        <Typography
                            variant="labels.large"
                            sx={{ color: PATPalette.neutral.grey[300] }}
                            label={`${t('TEXT_GOAL_PRIORITY')} *`}
                        />
                        <InfoTooltip
                            message={
                                <Trans
                                    i18nKey="GOAL_PRIORITY_INFO_TEXT"
                                    components={{ strong: <strong />, br: <br /> }}
                                />
                            }
                        />
                    </Grid>
                    <Controller
                        name="goalPriority"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            }
                        }}
                        render={({ field }) => (
                            <ComboSelect
                                {...field}
                                onSelectChange={handleGoalPriorityChange}
                                size="small"
                                selectedValue={field.value ?? ''}
                                options={Object.keys(GoalPriorityEnum).map((key) => ({
                                    label: GoalPriorityEnum[key as keyof typeof GoalPriorityEnum],
                                    value: GoalPriorityEnum[key as keyof typeof GoalPriorityEnum]
                                }))}
                                data-testid="goal-priority"
                            />
                        )}
                    />
                    {errors.goalPriority && (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.goalPriority.message} />
                    )}
                </ItemContainer>

                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Grid container alignItems="center">
                        <Typography
                            variant="labels.large"
                            sx={{ color: PATPalette.neutral.grey[300] }}
                            label={`${t('TEXT_GOAL_TARGET_AMOUNT')} *`}
                        />
                        <InfoTooltip
                            message={
                                <Trans
                                    i18nKey="TEXT_GOAL_TARGET_AMOUNT_INFO_TEXT"
                                    components={{ strong: <strong />, br: <br /> }}
                                />
                            }
                        />
                    </Grid>
                    <Controller
                        name="goalAmount"
                        control={control}
                        rules={{
                            required: { value: true, message: t('TEXT_THIS_IS_REQUIRED_FIELD') },
                            validate: {
                                weight: (value) => (value < 0 ? t('TEXT_AMOUNT_VALIDATION') : undefined)
                            }
                        }}
                        render={({ field: { onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={() => {
                                    onBlur();
                                }}
                                onChange={(e) => {
                                    handleAmountChange(e.target.value);
                                }}
                                value={value ? formatCurrency(value) : ''}
                                inputRef={ref}
                                size="small"
                                error={!!errors.goalAmount}
                                type="text"
                                data-testid="goal-amount"
                                fullWidth
                                placeholder={currency}
                                iconPlacement="start"
                            />
                        )}
                    />
                    {errors.goalAmount ? (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.goalAmount.message} />
                    ) : null}
                </ItemContainer>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Grid container alignItems="center">
                        <Typography
                            variant="labels.large"
                            sx={{ color: PATPalette.neutral.grey[300] }}
                            label={`${t('GOAL_SUMMARY_TARGET_DATE_TEXT')} *`}
                        />
                        <InfoTooltip
                            message={
                                <Trans
                                    i18nKey="GOAL_SUMMARY_TARGET_DATE_TEXT_INFO_TEXT"
                                    components={{ strong: <strong />, br: <br /> }}
                                />
                            }
                        />
                    </Grid>
                    <Controller
                        name="goalDate"
                        control={control}
                        rules={{
                            required: { value: true, message: t('TEXT_THIS_IS_REQUIRED_FIELD') },
                            validate: (value) => {
                                if (!value) {
                                    return t('TEXT_THIS_IS_REQUIRED_FIELD');
                                }

                                const today = dayjs();
                                const oneYearFromToday = today.add(1, 'year');
                                const dateValue = dayjs(value);
                                if (!dateValue.isValid()) {
                                    return false;
                                }
                                if (dateValue.isAfter(today, 'day')) {
                                    return dateValue.isAfter(oneYearFromToday) || t('TEXT_DATE_AT_LEAST_ONE_YEAR');
                                } else {
                                    return false;
                                }
                            }
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <BasicDatePicker
                                enabled
                                type="single"
                                startDate={dayjs().add(1, 'day').format()}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                data-testid="goal-date"
                                minErrorMsg={t('TEXT_VALID_FUTURE_DATE')}
                            />
                        )}
                    />
                    {errors.goalDate && <ErrorSpan variant="subtitle2" role="alert" label={errors.goalDate.message} />}
                </ItemContainer>
                <FundingSourcesWidget
                    incomeSource={incomeSource}
                    onIncomeSourceChange={(source) => {
                        setIncomeSource(source);
                        checkFundingSourceError(source, 'income');
                    }}
                    investmentSource={investmentSource}
                    onInvestmentSourceChange={(source) => {
                        setInvestmentSource(source);
                        checkFundingSourceError(source, 'investment');
                    }}
                    errorMsg={fundingSourceError}
                />
            </GoalFormWrapper>
        </DrawerComp>
    );
};