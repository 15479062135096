/* eslint-disable operator-linebreak */
import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import VerificationInput from 'react-verification-input';
import { Typography, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { resendMFA, confirmMFAAndLogin } from '../../../features/auth/authSlice';
import { ItemContainer, ErrorSpan } from './LoginForm';
import { LOGIN } from '../../../common/constants';
import { BasicButton } from '../../atoms';
import './MFAForm.css';
import { handleOpenAgreementModal, logEventToBackEnd } from '../../../features/global/globalSlice';
import { ReactComponent as Logo } from '../../../assets/logos/logo.svg';

const GridContainer: any = styled(Grid)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    minHeight: 'calc(100vh - 161px)'
}));

const FormContainer: any = styled(Grid)(({ theme }) => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[900]}`,
    padding: 30,
    borderRadius: 4,
    maxWidth: '380px',
    width: 'unset'
}));

const StyleCheckbox = styled(Checkbox)(() => ({
    color: 'green',
    '&.Mui-checked': {
        color: 'green'
    }
}));

const Container: any = styled(GridContainer)(() => ({
    '.MuiInputBase-input': {
        boxSizing: 'border-box'
    },
    // background: `${theme.palette.layout.primary} 0.5`,
    // background: 'linear-gradient(90deg, #092e39 50%)',
    // filter: 'brightness(0.4)',
    // backgroundColor: `${hex2rgba(theme.palette.layout.primary, 0.5)}`,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 50px'
}));

const HyperLink = styled('span')(({}) => ({
    cursor: 'pointer'
    // color: theme.palette.layout.primary
}));

type MFAFormPropsType = {
    currentLogginEmail: string;
    goBackToLogin: () => void;
    rememberMe?: boolean;
};

export const MFAForm = ({ currentLogginEmail, goBackToLogin, rememberMe }: MFAFormPropsType) => {
    const { t } = useTranslation();
    const { loading, isSendingMFA } = useAppSelector((state) => state.auth);
    const [serverError, setServerError] = useState<string | undefined>(undefined);
    const [checkBoxValue, setCheckboxValue] = useState(false);

    const [mfaCode, setMfaCode] = useState<string>('');
    const { oktaAuth } = useOktaAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const logo = useAppSelector((state) => state.global?.globalConfig?.logo || '');

    useEffect(() => {
        if (isSendingMFA) {
            setMfaCode('');
        }
    }, [isSendingMFA]);

    const onResendCode = async () => {
        dispatch(resendMFA());
    };

    const navigatetoclients = () => {
        navigate('/clients');
    };

    const onVerify = async () => {
        try {
            const res: any = await dispatch(
                confirmMFAAndLogin({
                    oktaAuth,
                    code: mfaCode,
                    rememberDevice: checkBoxValue,
                    username: currentLogginEmail
                })
            );

            if (res.payload.status === LOGIN.SUCCESS) {
                setServerError(undefined);
                //Login to remember username
                rememberMe
                    ? localStorage.setItem('GOE_APP_REMEMBERED_EMAIL', currentLogginEmail)
                    : localStorage.removeItem('GOE_APP_REMEMBERED_EMAIL');

                dispatch(logEventToBackEnd('LOGIN_SUCCESS'));

                if (
                    !res?.payload?.user?.clickWrapAgreements?.accepted ||
                    res?.payload?.user?.clickWrapAgreements?.agreements?.find(
                        (el: { agreementName: string }) => el.agreementName === 'UserAgreement'
                    )?.acceptedUserAgreementVersion !== res?.payload?.user?.userAgreement?.version
                    //will be using during hantz version update so commented here
                    // (res?.payload?.user?.clickWrapAgreements?.agreements?.find(
                    //     (el: { agreementName: string }) => el.agreementName === 'hantz'
                    // )?.acceptedHantzVersion &&
                    //     res?.payload?.user?.clickWrapAgreements?.agreements?.find(
                    //         (el: { agreementName: string }) => el.agreementName === 'hantz'
                    //     )?.acceptedHantzVersion !== res?.payload?.user?.hantz?.version)
                    //res?.payload?.user?.acceptedEulaVersion !== res?.payload?.user?.eula?.version ||
                    //res?.payload?.user?.acceptedUserAgreementVersion !== res?.payload?.user?.userAgreement?.version
                ) {
                    navigatetoclients();
                    dispatch(handleOpenAgreementModal(true));
                } else {
                    navigatetoclients();
                }
            } else {
                setServerError(res.payload.errorSummary);
            }
        } catch (error) {
            console.error('error verifying MFA code', error);
            setServerError(t('MFA_VERIFICATION_UNEXPECTED_ERROR'));
        }
    };

    return (
        <>
            <GridContainer container>
                <Container
                    item
                    xs={12}
                    sx={{
                        justifyContent: 'flex-start',
                        paddingTop: '40px'
                    }}
                >
                    <FormContainer container direction="column">
                        <form>
                            <ItemContainer>
                                {logo ? (
                                    <img
                                        src={logo}
                                        alt="Franklin Templeton"
                                        data-testid="brand-logo"
                                        width="200"
                                        height="auto"
                                    />
                                ) : (
                                    <Logo title="login" data-testid="brand-logo" />
                                )}
                            </ItemContainer>
                            <br />
                            <hr />
                            <Typography variant="h2">{t('TEXT_MFA_SCREEN_HEADER')}</Typography>

                            <Typography variant="body1" color="green" style={{ margin: '20px 0' }}>
                                {t('TEXT_MFA_SCREEN_DISCLAIMER', { email: currentLogginEmail })}
                            </Typography>

                            <ItemContainer style={{ marginBottom: '20px' }}>
                                <VerificationInput
                                    autoFocus
                                    validChars="0-9"
                                    onChange={setMfaCode}
                                    data-testid="mfa-verification-input"
                                    placeholder=" "
                                    classNames={{
                                        container: 'vicharacter',
                                        character: 'vicharacter',
                                        characterInactive: 'vicharacter--inactive',
                                        characterSelected: 'vicharacter--selected'
                                    }}
                                />
                            </ItemContainer>

                            <FormControlLabel
                                onChange={(e: any) => {
                                    setCheckboxValue(e.target.checked);
                                }}
                                checked={checkBoxValue}
                                control={<StyleCheckbox />}
                                label={
                                    <Typography variant="body2" color="green">
                                        {t('TEXT_MFA_SCREEN_CHECKBOX')}
                                    </Typography>
                                }
                                sx={{
                                    marginBottom: '20px',
                                    alignItems: 'flex-start'
                                }}
                            />

                            <Typography variant="body1" color="green" sx={{ marginBottom: '20px' }}>
                                {t('TEXT_MFA_SCREEN_DID_NOT_RECIEVE_CODE')}{' '}
                                <HyperLink onClick={isSendingMFA ? () => {} : onResendCode} data-testid="mfa-button">
                                    {t('TEXT_MFA_SCREEN_RESEND_CODE_BUTTON')}
                                </HyperLink>
                            </Typography>

                            {serverError && <ErrorSpan role="alert">{serverError}</ErrorSpan>}

                            <BasicButton variant="contained" fullWidth onClick={loading ? () => {} : onVerify}>
                                {isSendingMFA || loading ? (
                                    <CircularProgress color="inherit" size="24px" />
                                ) : (
                                    t('TEXT_MFA_SCREEN_VERIFY_BUTTON')
                                )}
                            </BasicButton>

                            <ItemContainer sx={{ alignItems: 'flex-end' }}>
                                <HyperLink
                                    onClick={goBackToLogin}
                                    data-testid="back-to-sign-in-link"
                                    sx={{ color: 'green' }}
                                >
                                    {t('TEXT_MFA_SCREEN_BACK_TO_SIGN_IN')}
                                </HyperLink>
                            </ItemContainer>
                        </form>
                    </FormContainer>
                </Container>
            </GridContainer>
        </>
    );
};
