import dayjs from 'dayjs';
import { useAppSelector } from '../../../../common/hooks';
import { PDFImportantInformation } from '../../../../components/molecules';
import ReviewPlanDetails from '../../../../containers/review-plan-details';
import PDFLandingPage from '../PDFLanding/PDFLanding';

const GeneratedPlanPDF = () => {
    const { user } = useAppSelector((state) => state?.auth);
    const { globalConfig } = useAppSelector((state) => state?.global);
    const adviceGoals = useAppSelector((state) => state?.adviceGoals);
    const adviceClient = useAppSelector((state) => state?.adviceClient);
    const { keyValueRequest } = useAppSelector((state) => state?.advisorConsole);
    const { _id: selectedClientID } = adviceClient || {};

    const selectedClient = keyValueRequest[selectedClientID];

    const getValidDate = (value: string): string => {
        return dayjs(value).isValid() ? dayjs(value).format('MM/DD/YYYY') : '-';
    };
    const planCreationDate = getValidDate(selectedClient?.global?.planCreationDate);
    const lastUpdateDate = getValidDate(selectedClient?.global?.lastUpdateDate);

    const totalPagesForPDF =
        adviceGoals?.response?.filter((g) => g?.response?.body?.analysisReport?.isGoalRealistic)?.length * 4 + 1;
    return (
        <>
            <PDFLandingPage
                globalConfig={globalConfig}
                proposalCreatedBy={user?.loggedInUsername}
                proposalCreatedFor={`${adviceClient?.clientFirstName} ${adviceClient?.clientLastName}`}
                proposalPlanCreationDate={planCreationDate}
                proposalPlanLastUpdateDate={lastUpdateDate}
            />
            <ReviewPlanDetails isPDF />
            <PDFImportantInformation
                advisorEmail={user?.userEmail}
                advisorName={user?.loggedInUsername}
                totalPages={totalPagesForPDF}
            />
        </>
    );
};

export default GeneratedPlanPDF;
