import styled from '@emotion/styled';
import { Icon } from '../../../atoms';
import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

const Overlay = styled('div')(() => ({
    width: '100%',
    height: '100%',
    position: 'fixed',
    background: 'transparent',
    top: 0,
    left: 0,
    zIndex: 120
}));

const Container = styled('div')<{ top?: number; right?: number }>(({ top = 0, right = 0 }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top,
    right,
    width: '316px',
    boxShadow: '0px 4px 10px 0px #0000000D',
    padding: '40px',
    background: '#FFF'
}));

const CloseButton = styled('button')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    background: 'transparent',
    cursor: 'pointer',
    border: 'none',
    top: 26,
    right: 26,
    position: 'absolute',
    '> svg': {
        width: '12px',
        height: '12px'
    }
}));

type Props = PropsWithChildren<{
    onClose(): void;
    parentId?: string;
    isVisible: boolean;
}>;

export const NavDropdown = ({ children, onClose, parentId, isVisible = false }: Props) => {
    const getPosition = () => {
        const parent = document.getElementById(parentId!);

        if (!parent) {
            return;
        }

        const { offsetLeft, parentElement, offsetWidth } = parent;

        const top = ~~parentElement?.offsetHeight! + 10;
        const right = ~~document.body.offsetWidth! - ~~offsetLeft! - ~~offsetWidth!;

        return {
            top,
            right
        };
    };

    if (!isVisible) {
        return null;
    }

    return createPortal(
        <Overlay onClick={onClose}>
            <Container {...getPosition()} onClick={(e) => e.stopPropagation()}>
                <CloseButton type="button" onClick={onClose}>
                    <Icon id="close" />
                </CloseButton>

                {children}
            </Container>
        </Overlay>,
        document.getElementById('root')! || document.getElementById('storybook-root')!
    );
};
