import styled from '@emotion/styled';
import { Logo } from '../../atoms/Logo/Logo';
import { NavUser } from './User';
import { NavLink } from './Link';
import { NavItem } from 'common/types/nav';
import { NavContact } from './Contact';
import { useState } from 'react';
import { useAppSelector } from '../../../common/hooks';
import ellipse from '../../../assets/logos/Hantz_latest_logo.png';
import { Divider, Grid } from '@mui/material';
import { Typography } from '../../atoms';
import Kebab from '@mui/icons-material/MoreHoriz';
import { Tenant } from '../../../utils';
import Palette, { PATPalette } from '../../../themes/palette';
import { useTranslation } from 'react-i18next';
import palette from '../../../themes/palette';
import { useDropdownState } from '../../../common/hooks/useDropdownState';
import { Dropdown } from '../../../components/atoms/Dropdown';

const Background = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100
}));

const TrialComponent = styled(Grid)(() => ({
    display: 'flex',
    color: Palette.common.white,
    border: `1px solid ${PATPalette.secondary.berry[100]}`,
    backgroundColor: PATPalette.secondary.berry[400],
    borderRadius: '2px',
    alignItems: 'center'
}));

const Navbar = styled('nav')(() => ({
    background: '#3769FF',
    height: '60px',
    padding: '0 30px',
    display: 'flex',
    alignItems: 'center',

    '& > #hantzlogo::before': {
        height: '30px',
        background: 'red',
        width: '40px',
        color: 'red'
    }
}));

const LinksContainer = styled('div')(() => ({
    display: 'flex',
    gap: '47px',
    alignItems: 'center',
    flex: 1,
    marginRight: '85px',
    marginLeft: '85px'
}));

const ImageContainer = styled('div')(() => ({
    height: '22px',
    width: '120px',
    backgroundImage: `url(${ellipse})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    marginLeft: '10px'
}));

const Content = styled('div')<{
    width?: number;
}>(({ width }) => ({
    width,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 120,
    background: palette.common.white,
    border: `1px solid ${PATPalette.secondary.grey[200]}`,
    borderRadius: '4px',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.05) !important',
    padding: '20px',
    maxWidth: '312px'
}));

type Props = {
    items: NavItem[];
    user: {
        loggedInUsername: string;
        userRole?: string;
        adminTitle?: string;
    };
    onClickAdmin(): void;
    onClickLogout(): void;
};

export const Nav = ({ items = [], user, onClickAdmin, onClickLogout }: Props) => {
    const [activeDropdown, setActiveDropdown] = useState<DropdownNames>();
    const tenantsList = useAppSelector((state) => state?.auth?.user?.tenants);
    const tenant = useAppSelector((state) => state?.auth?.user?.tenant);
    const userRole = useAppSelector((state) => state?.auth?.user?.userRole);
    const { globalConfig } = useAppSelector((state) => state.global);
    const { t } = useTranslation();
    const { toggle, ...dropdownProps } = useDropdownState({});

    const onToggleDropdown = (name: DropdownNames) =>
        setActiveDropdown((oldName) => (oldName === name ? undefined : name));

    return (
        <>
            <Background>
                <Navbar>
                    <Logo />
                    {userRole === 'advisor' && tenantsList?.includes('hantz') ? (
                        <>
                            <Divider
                                style={{ marginLeft: '10px', color: 'rgba(0, 0, 0, 0.5)', borderColor: 'snow' }}
                                orientation="vertical"
                                variant="middle"
                                flexItem
                            />{' '}
                            <ImageContainer id="hantzlogo" />
                        </>
                    ) : null}
                    {globalConfig.tenantType === 'trial' && !tenantsList?.includes('hantz') && (
                        <TrialComponent
                            onMouseOver={toggle}
                            onMouseLeave={() => {
                                toggle();
                            }}
                            display="flex"
                            marginLeft={3}
                            gap="5px"
                            px={1}
                            paddingTop="2px"
                        >
                            <Typography
                                sx={{ color: Palette.common.white }}
                                variant="body.small"
                                label={Tenant.trial.toLocaleUpperCase()}
                            />
                            <Kebab />
                            <Dropdown placement={'bottom-start'} {...dropdownProps}>
                                <Content>
                                    <Typography label={t('TRIAL')} variant="labels.smallDemibold" />
                                </Content>
                            </Dropdown>
                        </TrialComponent>
                    )}

                    <LinksContainer>
                        {userRole === 'advisor' && tenantsList?.includes('hantz')
                            ? null
                            : items.map(({ label, ...item }, index) => (
                                  <NavLink key={`${label}_${index}`} {...item}>
                                      {label}
                                  </NavLink>
                              ))}
                    </LinksContainer>
                    <NavUser
                        {...user}
                        tenantsList={tenantsList}
                        tenant={tenant}
                        isDropdownActive={activeDropdown === 'user'}
                        onToggleDropdown={() => onToggleDropdown('user')}
                        onClickAdmin={onClickAdmin}
                        onClickLogout={onClickLogout}
                    />

                    <NavContact
                        isDropdownActive={activeDropdown === 'contact'}
                        onToggleDropdown={() => onToggleDropdown('contact')}
                    />
                </Navbar>
            </Background>
        </>
    );
};

type DropdownNames = 'user' | 'contact';
