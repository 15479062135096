import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

// Lazy load translations
const loadLocales = (language: string, namespace: string) => {
    return import(
        /* webpackChunkName: "locale-[request]" */
        `./assets/locales/${language}/${namespace}.json`
    );
};

i18n.use(initReactI18next)
    .use(
        resourcesToBackend((language: string, namespace: string) => {
            return loadLocales(language, namespace);
        })
    )
    .init({
        fallbackLng: 'en',
        load: 'languageOnly',
        ns: ['translation'],
        defaultNS: 'translation',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        }
    });

export default i18n;
