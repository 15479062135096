import { Grid } from '@mui/material';
import { Typography } from '../../atoms/Typography/Typography';
import { PortfolioIcon, SizeVariant } from './PortfolioIcon';
import { GroupedAssetAllocation } from 'common/types';
import {
    StyledExpanded,
    StyledLegendName,
    StyledPortfolioListItem,
    StyledPortfolioListHeader,
    StyledPortfolioHeader,
    StyledPortfolioTitle,
    StyledTicker
} from './PortfolioCompositionList';

export const PortfolioCompositionListReadOnly = ({
    value,
    key
}: {
    value: GroupedAssetAllocation;
    key: number | string;
}) => {
    const { groupedSubAssets, totalAllocatedvalue, type } = value;
    return (
        <StyledPortfolioListItem key={key}>
            <StyledPortfolioListHeader>
                <StyledLegendName>
                    <StyledPortfolioHeader isPdf label={type} aria-label={type} />
                </StyledLegendName>
                <Typography label={totalAllocatedvalue} variant="headers.h3" />
            </StyledPortfolioListHeader>

            {groupedSubAssets?.map(({ name, totalAllocatedvalue, data, color }, _groupedSubAssetsIndex) => (
                <StyledExpanded key={`${name}-${_groupedSubAssetsIndex}`}>
                    <Grid container justifyContent="space-between" alignItems="center" fontSize={'10px'}>
                        <StyledLegendName>
                            <PortfolioIcon variant={SizeVariant.small} color={color} />
                            <StyledPortfolioTitle isPdf label={name} aria-label={name} />
                        </StyledLegendName>
                        <Typography label={`${totalAllocatedvalue}%`} variant="body.smallDemibold" />
                    </Grid>
                    {data?.map(({ ticker, tickerName, allocatedValue }, _in) => (
                        <Grid container justifyContent="space-between" alignItems="center" key={_in}>
                            <StyledLegendName>
                                <StyledTicker split label={ticker} />
                                <Typography label={tickerName} variant="body.small" />
                            </StyledLegendName>
                            <Typography label={`${allocatedValue.toFixed(2)}%`} variant="body.small" />
                        </Grid>
                    ))}
                </StyledExpanded>
            ))}
        </StyledPortfolioListItem>
    );
};
