/* eslint-disable operator-linebreak */

import styled from '@emotion/styled';
import { StatusVariant } from './Status';
import { Fragment, useState } from 'react';
import { Row } from './Row';
import { Header } from './Header';
import { SubRow, SubRowProps } from './SubRow';
import PDFViewer from '../../organisms/PDF/PDFViewer';
import GeneratedPlanPDF from '../../organisms/PDF/GeneratedPDFs/GeneratedPlanPDF';
import { Grid } from '@mui/material';
import { ConfirmationCentered } from '../ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { deletePlan } from '../../../features/advisorConsole';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { updateGlobalLoaderState } from '../../../features/global/globalSlice';

const Table = styled('table')(() => ({
    all: 'unset',
    width: '100%',
    borderCollapse: 'collapse',
    display: 'table',
    minWidth: '1312px'
}));

const TableScroll = styled('div')(() => ({
    overflowX: 'auto'
}));

export type AdminTableItem = {
    id: string;
    status: StatusVariant;
    probabilityOfSuccess?: number;
    clientGoalName: string;
    portfolioApproach?: string;
    riskGoalPriority: string;
    portfolioValue: number;
    numberofGoals: number;
    equityFixedIncome?: string;
    subItems?: SubRowProps[];
    lastUpdated?: string;
};

type Props = {
    items: AdminTableItem[];
};

export const AdminTable = ({ items = [] }: Props) => {
    const [closedRows, setClosedRows] = useState<string[]>([]);
    const [openPDF, setOpenPDF] = useState(false);
    const [planToDelete, setPlanToDelete] = useState<string | undefined>(undefined);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { tenant } = useAppSelector((state) => ({
        tenant: state?.global?.globalConfig?.subdomain
    }));

    const handleToggleExpanded = (id: string, isExpanded: boolean) => {
        setClosedRows(isExpanded ? [...closedRows, id] : closedRows.filter((item) => item !== id));
    };

    const onConfirmDelete = async () => {
        try {
            if (planToDelete) {
                dispatch(updateGlobalLoaderState(true));
                await dispatch(deletePlan({ tenant, planId: planToDelete }));
                dispatch(updateGlobalLoaderState(false));
            }
        } catch (e) {
            console.error(`Error deleting plan with id ${planToDelete}`);
        }
    };

    return (
        <>
            {openPDF && (
                <PDFViewer open={openPDF} handleClose={() => setOpenPDF(false)}>
                    <GeneratedPlanPDF />
                </PDFViewer>
            )}

            {planToDelete && (
                <Grid
                    width="100%"
                    height="100%"
                    position="absolute"
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <ConfirmationCentered
                        cancelButtonLabel={t('DELETE_CONFIRMATION_MODAL_CANCEL_TEXT')}
                        confirmButtonLabel={t('DELETE_CONFIRMATION_MODAL_DELETE_TEXT')}
                        title={t('DELETE_PLAN_TITLE')}
                        content={t('DELETE_PLAN_CONTENT')}
                        onCancellation={() => setPlanToDelete(undefined)}
                        onConfirmation={() => onConfirmDelete()}
                        open
                    />
                </Grid>
            )}

            <TableScroll>
                <Table>
                    <Header />
                    <tbody>
                        {items.map(({ subItems = [], ...item }) => {
                            const { id } = item;
                            const isExpanded = !closedRows.includes(id);

                            return (
                                <Fragment key={id}>
                                    <Row
                                        {...item}
                                        subItems={subItems}
                                        isExpanded={isExpanded}
                                        onToggleExpanded={() => handleToggleExpanded(id, isExpanded)}
                                        printPDF={() => setOpenPDF(true)}
                                        onDeleteClick={(planId: string) => setPlanToDelete(planId)}
                                    />

                                    {isExpanded && (
                                        <>
                                            {subItems?.map((subItem, index) => {
                                                return <SubRow {...subItem} key={`${id}_${subItem.id}_${index}`} />;
                                            })}
                                        </>
                                    )}
                                </Fragment>
                            );
                        })}
                    </tbody>
                </Table>
            </TableScroll>
        </>
    );
};
