import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Typography } from '../../atoms/Typography/Typography';
import { ConfirmationDialog } from '../../molecules/ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Grid } from '@mui/material';
import palette, { PATPalette } from '../../../themes/palette';
import { BasicButton } from '../../atoms';
export interface DrawerProps {
    open?: boolean;
    header?: string;
    subHeading?: string;
    buttonlabel?: string;
    secondaryButtonLabel?: string;
    // eslint-disable-next-line no-unused-vars
    handleClose: (flag: boolean) => void;
    children?: React.ReactNode | React.ReactNode[];
    sx?: object;
    onSubmit?: () => void;
    onSecondarySubmit?: () => void;
    error?: any;
    resetChild?: () => void;
    loading?: boolean;
    disableSubmit?: boolean;
    isValid?: boolean;
    askConfirm?: boolean;
    drawerIcon?: React.ReactNode;
    hasChange?: boolean;
}

const Footer = styled('footer')(() => ({
    width: '100%',
    position: 'sticky',
    bottom: 0,
    marginBottom: 0,
    zIndex: 1,
    background: PATPalette.neutral.white,
    borderTop: `1px solid ${PATPalette.neutral.grey[100]}`
}));

const WIDTH = '396px';
export const DrawerComp = ({
    open,
    header,
    drawerIcon,
    buttonlabel,
    handleClose,
    children,
    error,
    onSubmit,
    onSecondarySubmit,
    resetChild,
    loading,
    disableSubmit,
    isValid,
    askConfirm = true,
    secondaryButtonLabel,
    hasChange
}: DrawerProps) => {
    const toggleDrawer =
        (open: boolean) =>
        // eslint-disable-next-line no-unused-vars
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (askConfirm && hasChange) setDeleteModalState(!open);
            else handleClose(open);
        };

    const [deleteModalState, setDeleteModalState] = useState(false);
    const { t } = useTranslation();

    const handleLeave = () => {
        setDeleteModalState(!deleteModalState);
        handleClose(!open);
        if (resetChild) resetChild();
    };

    const handleStay = async () => {
        setDeleteModalState(!deleteModalState);
    };

    const styleSx = {
        '& .MuiPaper-root': {
            width: WIDTH
        },
        'MuiBackdrop-root-MuiModal-backdrop': {
            backgroundColor: 'inherit'
        }
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleLeave}>
                <>
                    {open && (
                        <Drawer
                            anchor={'right'}
                            open={open}
                            onClose={toggleDrawer(false)}
                            variant="temporary"
                            sx={styleSx}
                        >
                            {/* <Box sx={{
                                width: '381px',
                            }}> */}
                            {hasChange && (
                                <ConfirmationDialog
                                    onCancellation={handleLeave}
                                    onConfirmation={() => handleStay()}
                                    open={deleteModalState}
                                    content={`${t('DRAWER_CLOSE_MODAL_HEADER_TEXT')}`}
                                    confirmButtonLabel={`${t('DRAWER_CLOSE_MODAL_HEADER_TEXT_STAY')}`}
                                    cancelButtonLabel={`${t('DRAWER_CLOSE_MODAL_HEADER_TEXT_LEAVE')}`}
                                    title={t('DRAWER_CLOSE_MODAL_TITLE')}
                                    width={WIDTH}
                                />
                            )}
                            <Grid
                                container
                                gap="10px"
                                justifyContent="center"
                                padding="12px 16px"
                                alignItems="center"
                                borderBottom={`1px solid ${palette.grey[500]}`}
                                sx={{
                                    backgroundColor: '#FFF',
                                    zIndex: 100,
                                    top: 0,
                                    position: 'sticky'
                                }}
                            >
                                {drawerIcon && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        item
                                        width="40px"
                                        height="40px"
                                        // padding="8px"
                                        borderRadius="50%"
                                        sx={{ background: palette.primary.extraLight, aspectRatio: 1 }}
                                    >
                                        {drawerIcon}
                                    </Grid>
                                )}
                                <Grid item flexGrow={1}>
                                    <Typography
                                        color="semantic.text.primary"
                                        variant="headers.h4"
                                        label={header || ''}
                                        as="h4"
                                    />
                                </Grid>
                                <Grid item width="24px" height="24px">
                                    <CloseIcon color="inherit" onClick={toggleDrawer(false)} />
                                </Grid>
                            </Grid>

                            <Grid flexGrow={1} margin={2}>
                                {children}
                            </Grid>

                            <Footer sx={{ padding: secondaryButtonLabel ? '16px' : '29px 16px' }}>
                                {!!error?.response?.data?.message && (
                                    <Typography
                                        variant="body.large"
                                        color="secondary.berry.400"
                                        label={error.response.data.message}
                                        sx={{
                                            fontStyle: 'italic',
                                            textAlign: 'center',
                                            marginBottom: 10,
                                            marginTop: -10
                                        }}
                                    />
                                )}
                                {secondaryButtonLabel && (
                                    <BasicButton
                                        disabled={isValid}
                                        fullWidth={true}
                                        size="medium"
                                        color="primary"
                                        variant="contained"
                                        onClick={onSecondarySubmit}
                                        sx={{
                                            textTransform: 'unset',
                                            borderRadius: '100px',
                                            marginBottom: '1rem'
                                        }}
                                    >
                                        {secondaryButtonLabel}
                                    </BasicButton>
                                )}
                                <BasicButton
                                    disabled={loading || disableSubmit}
                                    size="medium"
                                    color="secondary"
                                    type="submit"
                                    variant="contained"
                                    onClick={onSubmit}
                                    sx={{
                                        textTransform: 'unset',
                                        borderRadius: '100px'
                                    }}
                                    loading={loading}
                                >
                                    {buttonlabel || t('DRAWER_BUTTON_LABEL')}
                                </BasicButton>
                            </Footer>
                        </Drawer>
                    )}
                </>
            </ClickAwayListener>
        </>
    );
};
