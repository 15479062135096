import { styled } from '@mui/material';
import { Typography } from '../../atoms/Typography/Typography';
import remove from '../../../assets/icons/remove.svg';
import info from '../../../assets/icons/info.svg';
import success_tick from '../../../assets/icons/success_tick.svg';
import { PATPalette } from '../../../themes/palette';
import { useTranslation } from 'react-i18next';

export interface BannerProps {
    type: 'success' | 'attention';
    disabled?: boolean;
    isTimer?: boolean;
    isGoalSummary?: boolean;
    message?: string;
    handleClose?: () => void;
    handleTryAgain?: () => void;
}

export interface MainDivProps {
    type: string;
    disabled: boolean;
}

export interface DisabledProps {
    disabled: boolean;
}

const StyledMainDiv = styled('div')<MainDivProps>(({ disabled, theme, type }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    gap: '8px',
    borderRadius: '6px',
    background: type === 'success' ? PATPalette.secondary.teal['100'] : PATPalette.secondary.berry['100'],
    // width: '392px',
    border: disabled
        ? 'none'
        : `2px solid ${type === 'success' ? PATPalette.secondary.teal['300'] : PATPalette.secondary.berry['400']}`,
    // position: 'fixed',
    left: 0,
    bottom: 0,
    zIndex: 999
}));

const StyledContentDiv = styled('div')({
    display: 'flex',
    alignItems: 'center',
    // flexDirection: 'column',
    width: '100%'
});

const StyledCloseDiv = styled('div')({
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'flex-start'
});

const StyledIconDiv = styled('div')({
    display: 'flex',
    width: '32px',
    padding: '1px 2.3px 1.2px 2px',
    justifyContent: 'center',
    alignItems: 'center'
});

const StyledContent = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: theme.spacing(2),
    letterSpacing: '0.13px'
}));

const StyledContentBold = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: theme.spacing(2),
    letterSpacing: '0.13px'
}));

const StyledTryAgain = styled(Typography)<DisabledProps>(({ disabled, theme }) => ({
    fontSize: '13px',
    marginLeft: '10px',
    fontWeight: 600,
    color: PATPalette.primary.skyBlue,
    lineHeight: theme.spacing(2),
    letterSpacing: '0.13px',
    cursor: disabled ? 'not-allowed' : 'pointer'
}));

const StyledCloseImg = styled('img')({
    width: '18px',
    height: '18px'
});

const StyledAtag = styled('a')({
    color: PATPalette.primary.skyBlue
});

export const Banner = ({
    type,
    handleClose,
    handleTryAgain,
    disabled = false,
    isGoalSummary,
    message
}: BannerProps) => {
    const { t } = useTranslation();
    return (
        <div style={{ width: '100%' }}>
            <StyledMainDiv type={type} disabled={disabled}>
                <StyledIconDiv>
                    <img src={type === 'success' ? success_tick : info} />
                </StyledIconDiv>
                <StyledContentDiv>
                    {type === 'success' ? (
                        <StyledContent label={t('BANNER_SUCCESS_MESSAGE')} />
                    ) : (
                        <>
                            <StyledContentBold label="Needs Attention: " />
                            &nbsp;
                            <StyledContent
                                label={
                                    message
                                        ? message
                                        : isGoalSummary
                                        ? t('BANNER_ATTENTION_MESSAGE')
                                        : t('BANNER_ATTENTION_MESSAGE_SUMMARY')
                                }
                            />
                        </>
                    )}
                    {type != 'success' && (
                        <StyledAtag>
                            <u>
                                <StyledTryAgain
                                    onClick={handleTryAgain}
                                    label={
                                        isGoalSummary
                                            ? t('BANNER_ATTENTION_LINK_TEXT_SUMMARY')
                                            : t('BANNER_ATTENTION_LINK_TEXT')
                                    }
                                    disabled={disabled}
                                />
                            </u>
                        </StyledAtag>
                    )}
                </StyledContentDiv>
                {type === 'success' && (
                    <StyledCloseDiv>
                        <StyledCloseImg src={remove} onClick={handleClose} />
                    </StyledCloseDiv>
                )}
            </StyledMainDiv>
        </div>
    );
};
