import React from 'react';
import { styled } from '@mui/system';
import { Typography } from '../../atoms/Typography/Typography';
import { Grid, Box } from '@mui/material';
import Check from '@mui/icons-material/Check';
import palette, { PATPalette } from '../../../themes/palette';
import emotionStyled from '@emotion/styled';
import { updateCurrentStep } from '../../../features/client';
import { useAppDispatch, useAppSelector } from '../../../common/hooks';
import { isStep1Completed } from '../../../common/helper';
import { CLIENT_PROFILE_STAGES } from '../../../common/constants';
import { removeLoader, showLoader } from '../../../features/client-goals';

interface SingleStepWrapperProps {
    isCompleted?: boolean;
    isCurrent?: boolean;
    cursor?: 'not-allowed' | 'pointer';
}

const HorizontalBar = styled('div')<SingleStepWrapperProps>((props) => ({
    height: '4px',
    flex: 1,
    display: 'flex',
    backgroundColor: props.isCompleted ? props.theme.palette.primary.main : '#E2E2E2'
}));

const Circle = styled('div')<SingleStepWrapperProps>(({ isCompleted, theme, isCurrent, cursor }) => ({
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: isCompleted ? theme.palette.primary.main : theme.palette.primary.contrastWhite,
    border: `1px solid ${isCurrent ? theme.palette.button.blue : '#ADADAD'}`,
    aspectRatio: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    cursor
}));

const StepperContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    margin: `0 40px`,
    gap: '8px',
    marginBottom: '40px'
});

const EachStep = styled(Box)<{ isMaxWidth?: boolean }>((props) => ({
    display: 'flex',
    alignItems: 'center',
    flex: props.isMaxWidth ? 1 : 'unset',
    width: props.isMaxWidth ? '100%' : '65px',
    gap: '8px',
    position: 'relative'
}));

const StyledContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // margin: `0 ${theme.spacing(5)}`,
    paddingTop: `${theme.spacing(3)}`,
    maxWidth: '100%'
}));

const StepTextContainer = emotionStyled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center'
}));

const StepName = emotionStyled('span')<{ isCurrent?: boolean }>(({ isCurrent = false, theme }) => ({
    ...theme.typography.labels.small,
    color: isCurrent ? PATPalette.primary.skyBlue : PATPalette.neutral.grey[400],
    position: 'absolute',
    top: 32,
    textAlign: 'center',
    whiteSpace: 'nowrap'
}));

interface StepProps {
    name: string;
    isCompleted: boolean;
    stepNumber: number;
    isCurrent: boolean;
    isEnd?: boolean;
    runPlan(): void;
}

export interface StepperProps {
    steps: { name: string; content: React.ReactElement }[];
    initialStepIndex?: number;
    runPlan(): void;
}

const Step = ({ name, isCompleted, stepNumber, isCurrent, isEnd, runPlan }: StepProps) => {
    const dispatch = useAppDispatch();
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const step1Complete = isStep1Completed(CLIENT_PROFILE_STAGES, adviceClient);
    const canNavigationToGoals = step1Complete && stepNumber === 2;
    const canNavigateToClient = step1Complete && stepNumber === 1;
    const canNavigationToPlanDetails = adviceGoals.formFields.length > 0 && stepNumber === 3;
    const showPointer = (canNavigationToGoals || canNavigationToPlanDetails || canNavigateToClient) && !isCurrent;
    const banner = adviceGoals?.global?.banner;
    const bannerChangesExist = banner?.visible && banner.type === 'attention';
    const shouldRunPipe = adviceGoals.response?.length === 0 || bannerChangesExist;

    const onStepperClick = async () => {
        if (isCurrent) return;
        if (stepNumber === 2 && !step1Complete) {
            return;
        }
        if (stepNumber === 3 && adviceGoals.formFields.length === 0) {
            return;
        }

        if (stepNumber === 3 && shouldRunPipe) {
            dispatch(showLoader());
            await runPlan();
            dispatch(removeLoader());
        }
        await dispatch(updateCurrentStep(stepNumber - 1));
    };

    return (
        <EachStep isMaxWidth={isEnd ? false : true}>
            <StepTextContainer>
                <Circle
                    isCurrent={isCurrent}
                    isCompleted={isCompleted}
                    onClick={onStepperClick}
                    cursor={showPointer ? 'pointer' : 'not-allowed'}
                >
                    {isCompleted ? (
                        <Check sx={{ color: palette.primary.contrastText, marginRight: 0 }} fontSize="inherit" />
                    ) : (
                        <Typography
                            variant="body.small"
                            label={stepNumber}
                            sx={{ color: isCurrent ? palette.button.blue : 'inherit' }}
                        />
                    )}
                </Circle>

                <StepName isCurrent={isCurrent}>{name}</StepName>
            </StepTextContainer>

            {!isEnd && <HorizontalBar isCompleted={isCompleted} />}
        </EachStep>
    );
};

export const Stepper = ({ steps, initialStepIndex = 0, runPlan }: StepperProps) => {
    const [currentStep, setCurrentStep] = React.useState(initialStepIndex);

    return (
        <StyledContainer>
            <StepperContainer>
                {steps.map(({ name }, index) => {
                    return (
                        <Step
                            key={name + index}
                            isCompleted={index < currentStep}
                            isCurrent={index === currentStep}
                            name={name}
                            stepNumber={index + 1}
                            isEnd={index === steps.length - 1}
                            runPlan={runPlan}
                        />
                    );
                })}
            </StepperContainer>

            <Grid container item xs={12} direction="column">
                {React.cloneElement(steps[currentStep].content, { updateStep: setCurrentStep })}
            </Grid>
        </StyledContainer>
    );
};
