import { Theme } from '@mui/material';
import { formLabelClasses } from '@mui/material';

export default function Input(theme: Theme) {
    return {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: theme.spacing(0.75),
                    width: '-webkit-fill-available',
                    padding: theme.spacing(1.5, 2), // 1 unit = 8px :: (1, 2) => padding: 8px 16px
                    '&:hover': {
                        border: `1px solid #BFBFBF`
                    },
                    height: '40px',
                    background: theme.palette.common.white,
                    margin: 0,
                    border: `1px solid #BFBFBF`,
                    '&.Mui-error': {
                        borderColor: '#DC0546'
                    }
                },
                input: {
                    padding: theme.spacing(0),
                    height: `16px`
                },
                notchedOutline: {
                    border: 'none'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: theme.spacing(0.75),
                    width: '-webkit-fill-available',
                    padding: 0,
                    '&:hover': {
                        border: 'none'
                    }
                },
                input: {
                    fontWeight: 600,
                    fontSize: theme.spacing(2),
                    lineHeight: theme.spacing(2.5)
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: theme.spacing(0.25)
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: theme.spacing(0.75),
                    width: '-webkit-fill-available',
                    background: 'transparent !important'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#595959',
                    fontSize: theme.spacing(2),
                    fontWeight: '500',
                    lineHeight: theme.spacing(2.5),
                    margin: 0,
                    [`&.${formLabelClasses.focused}`]: { color: '#726e70' },
                    '&.MuiFormLabel-root.MuiInputLabel-root': {
                        '&[data-shrink="false"]': {
                            top: '-8px'
                        },
                        '&[data-shrink="true"]': {
                            top: '0'
                        }
                    }
                }
            }
        }
    };
}
