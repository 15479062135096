import { useState, useMemo, useEffect } from 'react';
import { DrawerComp, ConfirmationDialog } from '../../components/molecules';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { BasicDatePicker, ComboSelect, RadioOption, TextboxInput, Typography } from '../../components/atoms';
import { MonetarySource, Source } from '../../components/molecules/MonetarySource/MonetarySource';
import { Grid } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../common/hooks';
import { InvestmentSourcesType } from '../../common/types/clients-entity';
import { getYYYYMMDDfromDate } from '../../common/helper';
import { formatCurrency, isEqual, removeNonNumeric } from '../../utils';
import { CLIENT_PROFILE_STAGE4 } from '../../common/constants';
// import Percent from '@mui/icons-material/Percent';
import investmentSource from '../../assets/icons/income_source.svg';
import { updateClient, updateClientInvestmentSources } from '../../features/client';
import { updateBanner, updateGoal } from '../../features/client-goals';
import { updateGlobalLoaderState } from '../../features/global/globalSlice';
import { PATPalette } from '../../themes/palette';
// import _uniqBy from 'lodash/uniqBy';

interface AddInvestmentAccountsProps {
    // eslint-disable-next-line no-unused-vars
    onSubmit?: () => void;
    mode?: 'add' | 'edit';
    // eslint-disable-next-line no-unused-vars
    onEditSuccess?: (deletedAccounts?: string[], formFields?: any[]) => void;
    loading?: boolean;
    openDrawer: boolean;
    setOpenDrawer: any;
}

const WIDTH = '396px';

export const ItemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '8px',
    '& a': {
        color: theme.palette.background.paper
    }
}));

const FundingFormWrapper = styled('form')(() => ({
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    fontSize: '14px'
}));

export const ErrorSpan = styled(Typography)(() => ({
    fontSize: '13px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '12px',
    color: PATPalette.secondary.berry[400],
    margin: 0
}));

export const StyledAccountHolderRadioOption = styled(RadioOption)(() => ({
    '.MuiFormControlLabel-root': {
        '& .MuiTypography-root': {
            fontSize: '1rem',
            fontWeight: '600',
            lineHeight: '1.25rem',
            margin: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },

        '& .MuiButtonBase-root': {
            padding: '3px 4px 3px 10px'
        }
    }
}));

interface FrequencyProps {
    selected: boolean;
}

export const Frequency = styled('div')<FrequencyProps>(({ selected }) => ({
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    aspectRatio: '1/1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    cursor: 'pointer',
    backgroundColor: selected ? '#E0E0E0' : 'transparent'
}));

export const Placeholder = styled('div')(() => ({
    height: '21px'
}));

const StyledInfoText = styled(Typography)(() => ({
    color: '#595959',
    fontFamily: 'TT Commons Pro',
    fontSize: '16px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '12px' /* 75% */
}));

export const AddInvestmentAccounts = ({
    openDrawer,
    setOpenDrawer,
    mode = 'add',
    onEditSuccess
}: AddInvestmentAccountsProps) => {
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const dispatch = useAppDispatch();
    const {
        orgInfo,
        globalConfig: { currencySymbol }
    } = useAppSelector((state) => state.global);
    const clientData = useAppSelector((state) => state.adviceClient);
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    const isPlanRun = adviceGoals?.global?.isPlanRun;
    const [fundingAccounts, setFundingAccounts] = useState<InvestmentSourcesType[]>(
        clientData.accounts.investmentsSources ?? []
    );
    const banner = adviceGoals?.global?.banner;
    const relationships = clientData.relationship;
    const { clientFirstName, clientLastName } = clientData;
    const [keyId, setKeyId] = useState(uuidv4);
    const [investmentSourceEdited, setInvestmentSourceEdited] = useState(false);
    const [selectedAccountForEdit, setSelectedAccountForEdit] = useState('');
    const [deletedAccountIds, setDeletedAccountIds] = useState<string[]>([]);
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [currentMode, setCurrentMode] = useState(mode);
    const [isDelete, setIsDelete] = useState(false);
    const currentDate = new Date();

    // const allAdviceGoalsInvestmentSources = _uniqBy(
    //     adviceGoals?.formFields?.flatMap?.(({ data }) => data.sources.investmentSource) || [],
    //     'accountNumber'
    // );

    const locationValue = orgInfo?.investmentAccountLocation?.[language]?.[0]?.value;
    const initialFormValues = {
        accountHolder: relationships.length === 0 ? 'primary' : '',
        location: locationValue ?? '',
        accountName: '',
        accountType: '',
        amount: 0,
        recurringContributions: 0,
        frequency: undefined,
        recurringContributionsEndDate: null,
        recurringContributionsStartDate: null,
        escalationContributions: 0
    };

    const { control, formState, handleSubmit, trigger, reset, getValues, setValue, setError, clearErrors, watch } =
        useForm<InvestmentSourcesType>({
            mode: 'all',
            reValidateMode: 'onSubmit',
            defaultValues: initialFormValues
        });

    const { errors, isValid } = formState;

    const handleReset = () => {
        reset({
            accountHolder: relationships.length === 0 ? 'primary' : '',
            location: locationValue ?? '',
            accountName: '',
            accountType: '',
            amount: 0,
            recurringContributions: 0,
            frequency: undefined,
            recurringContributionsEndDate: null,
            recurringContributionsStartDate: null,
            escalationContributions: 0
        });
    };
    const recurringContributions = watch('recurringContributions');
    const frequency = watch('frequency');

    useEffect(() => {
        if (selectedAccountForEdit) {
            const investmentSourceData = fundingAccounts.find((el) => el.accountId === selectedAccountForEdit);
            reset({
                ...investmentSourceData,
                recurringContributionsEndDate: investmentSourceData?.recurringContributionsEndDate
                    ? dayjs(investmentSourceData?.recurringContributionsEndDate, 'DD-MM-YYYY')
                    : undefined,
                recurringContributionsStartDate: investmentSourceData?.recurringContributionsStartDate
                    ? dayjs(investmentSourceData?.recurringContributionsStartDate, 'DD-MM-YYYY')
                    : undefined
            });
        }
    }, [selectedAccountForEdit]);

    // This function helps ensure that the WEALTH SOURCES selected in the goals remain selected.
    const getInvestmentSourceSelection = (accountId: string, goalInvestmentSources: any[]) => {
        const source = goalInvestmentSources.find((source) => source.accountNumber === accountId);

        // Return default values if source is undefined
        return {
            isSelected: source?.isSelected ?? false,
            isRCSelected: source?.isRCSelected ?? false
        };
    };

    const updateExistingGoals = (formFields: any[], investmentAccounts: InvestmentSourcesType[]) => {
        if (formFields && formFields?.length > 0) {
            formFields.forEach((item: any) => {
                const updatedInvestmentSources = item.data.sources.investmentSource;
                if (investmentAccounts.length > 0) {
                    investmentAccounts.forEach((acc) => {
                        const accountExistsIndex = updatedInvestmentSources.findIndex(
                            (source: any) => acc.accountId === source.accountNumber
                        );
                        if (accountExistsIndex === -1) {
                            updatedInvestmentSources.push({
                                accountNumber: acc.accountId,
                                accountName: acc.accountName,
                                isSelected: false,
                                isRCSelected: false,
                                accountBalance: acc.amount,
                                ...(acc.recurringContributions && {
                                    recurringContributions: acc.recurringContributions
                                }),
                                recurringContributionsEndDate: getYYYYMMDDfromDate(
                                    dayjs(acc.recurringContributionsEndDate)
                                ),
                                recurringContributionsStartDate: getYYYYMMDDfromDate(
                                    dayjs(acc.recurringContributionsStartDate)
                                ),
                                disabled: false
                            });
                        } else {
                            const { isSelected, isRCSelected } = getInvestmentSourceSelection(
                                acc.accountId,
                                updatedInvestmentSources
                            );

                            updatedInvestmentSources[accountExistsIndex] = {
                                accountNumber: acc.accountId,
                                accountName: acc.accountName,
                                isSelected,
                                isRCSelected,
                                accountBalance: acc.amount,
                                ...(acc.recurringContributions && {
                                    recurringContributions: acc.recurringContributions
                                }),
                                recurringContributionsEndDate: getYYYYMMDDfromDate(
                                    dayjs(acc.recurringContributionsEndDate)
                                ),
                                recurringContributionsStartDate: getYYYYMMDDfromDate(
                                    dayjs(acc.recurringContributionsStartDate)
                                ),
                                disabled: updatedInvestmentSources[accountExistsIndex].disabled || false
                            };
                        }
                    });
                }
            });
        }
        return formFields;
    };

    const noUserValuesEntered = () => {
        let data = getValues();
        const userValues = Object.values(data);
        const defaultValues = Object.values(initialFormValues);
        // check if there is difference between user entered values and default values
        return JSON.stringify(userValues) === JSON.stringify(defaultValues);
    };

    const submitFunds = async () => {
        try {
            let data = getValues();

            if (noUserValuesEntered()) {
                // we just deleted an income source
                checkUnsavedAndhandleClose(false);
            } else {
                await trigger();
                if (!errors.accountName) {
                    const isaccountName = fundingAccounts
                        .filter((source) =>
                            currentMode === 'edit' && selectedAccountForEdit
                                ? source.accountId !== selectedAccountForEdit
                                : true
                        )
                        .some((i) => i.accountName.includes(data.accountName));
                    if (isaccountName && !selectedAccountForEdit) {
                        setError('accountName', {
                            type: 'required',
                            message: t('TEXT_ACCOUNT_NAME_EXIST')
                        });
                        return;
                    } else {
                        clearErrors('accountName');
                    }
                }
                if (!selectedAccountForEdit) data['accountId'] = uuidv4();
                data['recurringContributionsStartDate'] = data['recurringContributionsStartDate']
                    ? getYYYYMMDDfromDate(data['recurringContributionsStartDate'])
                    : undefined;
                data['recurringContributionsEndDate'] = data['recurringContributionsEndDate']
                    ? getYYYYMMDDfromDate(data['recurringContributionsEndDate'])
                    : undefined;
                const amountVal = data['amount'];
                var formatedAmountVal = Number(removeNonNumeric(amountVal));
                data['amount'] = formatedAmountVal;
                const recConValue = data['recurringContributions'] ?? 0;
                var formatedrecConValue = Number(removeNonNumeric(recConValue));
                data['recurringContributions'] = formatedrecConValue;
                if (!data.frequency && Number(removeNonNumeric(data.recurringContributions)) > 0) {
                    setError('frequency', {
                        type: 'required',
                        message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                    });
                    return;
                } else {
                    clearErrors('frequency');
                }
                if (isValid) {
                    let originalClientData: typeof clientData = JSON.parse(JSON.stringify(clientData));
                    const updatedClientData = Object.assign({}, originalClientData);
                    let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
                    let updatedFormFields = [];
                    // check stages
                    // if (updatedClientData.accounts?.incomeSources?.length > 0) {
                    updatedClientData.stages = [...updatedClientData.stages, CLIENT_PROFILE_STAGE4];
                    // }
                    updatedClientData.error = false;
                    updatedClientData.loading = false;
                    const updatedGoalsData = Object.assign({}, originalGoalsData);

                    if (currentMode === 'edit' && selectedAccountForEdit) {
                        updatedClientData['accounts'] = {
                            ...updatedClientData['accounts'],
                            investmentsSources: fundingAccounts?.map((funding: any) =>
                                funding.accountId === selectedAccountForEdit ? data : funding
                            )
                        };
                        updatedFormFields = updateExistingGoals(
                            originalGoalsData.formFields,
                            fundingAccounts?.map((funding: any) =>
                                funding.accountId === selectedAccountForEdit ? data : funding
                            )
                        );
                        updatedGoalsData.global.editMode = true;
                    } else {
                        updatedClientData['accounts'] = {
                            ...updatedClientData['accounts'],
                            investmentsSources: [...fundingAccounts, data]
                        };
                        updatedFormFields = updateExistingGoals(originalGoalsData.formFields, [
                            ...fundingAccounts,
                            data
                        ]);
                    }
                    updatedGoalsData.formFields.forEach((goal) => {
                        goal.data.sources.investmentSource.forEach((goalInvestmentSrc: any) => {
                            updatedClientData.accounts.investmentsSources.forEach((investmentsrc: any) => {
                                if (investmentsrc.accountId === goalInvestmentSrc.accountNumber) {
                                    goalInvestmentSrc.accountBalance = investmentsrc.amount;
                                    goalInvestmentSrc.accountName = investmentsrc.sourceName;
                                    goalInvestmentSrc.frequency = investmentsrc.frequency;
                                    goalInvestmentSrc.recurringContributions = investmentsrc.recurringContributions;
                                    goalInvestmentSrc.recurringContributionsEndDate = getYYYYMMDDfromDate(
                                        dayjs(investmentsrc.recurringContributionsEndDate)
                                    );
                                    goalInvestmentSrc.recurringContributionsStartDate = getYYYYMMDDfromDate(
                                        dayjs(investmentsrc.recurringContributionsStartDate)
                                    );
                                }
                            });
                        });
                    });

                    await dispatch(updateClient(updatedClientData));
                    await dispatch(updateClientInvestmentSources(updatedClientData?.accounts?.investmentsSources));
                    if (currentMode === 'edit' && onEditSuccess && (!noUserValuesEntered() || investmentSourceEdited)) {
                        onEditSuccess(deletedAccountIds, updatedFormFields);
                    }
                    if (updatedGoalsData.formFields?.length > 0) {
                        updatedGoalsData.global.banner = {
                            ...banner,
                            visible: true,
                            type: 'attention',
                            isGoalSummary: clientData.step === 2 ? false : true
                        };
                        dispatch(updateGlobalLoaderState(true));
                        if (updatedGoalsData.global.hasRunPipe) {
                            updatedGoalsData.global.status = 'incomplete';
                        }
                        if (updatedGoalsData.global?.enablePDFGenerate) {
                            updatedGoalsData.global.enablePDFGenerate = false;
                        }
                        await dispatch(updateGoal(updatedGoalsData));
                        dispatch(updateGlobalLoaderState(false));
                    }
                    handleReset();
                    setSelectedAccountForEdit('');
                    setOpenDrawer(false);
                    setFundingAccounts([]);
                }
            }
        } catch (error) {
            console.error('Error in submitting funds', error);
        }
    };

    const accountHolderOptions = useMemo(() => {
        if (relationships.length === 0) {
            setValue('accountHolder', 'primary');
            // show only primary
            return [
                {
                    label: `${clientFirstName} ${clientLastName}`,
                    value: `primary`,
                    disabled: true
                }
            ];
        }
        const holders = relationships.map(({ firstName, lastName }) => {
            return {
                label: `${firstName} ${lastName}`,
                value: `${firstName}_${lastName}`
            };
        });
        holders.unshift({
            label: `${clientFirstName} ${clientLastName}`,
            value: `primary`
        });
        return holders;
    }, [relationships, clientFirstName, clientLastName]);

    const handleRecurringContributionsChange = (selectedValue: string) => {
        setValue('recurringContributions', Number(removeNonNumeric(selectedValue)), {
            shouldValidate: true,
            shouldDirty: true
        });
        const factorVal = removeNonNumeric(selectedValue);
        if (factorVal <= 0) {
            clearErrors('frequency');
            clearErrors('recurringContributionsEndDate');
            clearErrors('recurringContributionsStartDate');
        }
    };

    const handleAmountChange = (value: string) => {
        const numericValue = Number(removeNonNumeric(value));
        setValue('amount', isNaN(numericValue) ? 0 : numericValue, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const addAnotherFund = async (data: InvestmentSourcesType) => {
        const isaccountName = fundingAccounts
            .filter((source) =>
                currentMode === 'edit' && selectedAccountForEdit ? source.accountId !== selectedAccountForEdit : true
            )
            .some((i) => i.accountName.includes(data.accountName));
        if (isaccountName && !selectedAccountForEdit) {
            setError('accountName', { type: 'required', message: t('TEXT_ACCOUNT_NAME_EXIST') });
            return;
        } else {
            clearErrors('accountName');
        }
        if (!selectedAccountForEdit) data['accountId'] = uuidv4();
        data['recurringContributionsStartDate'] = data['recurringContributionsStartDate']
            ? getYYYYMMDDfromDate(data['recurringContributionsStartDate'])
            : undefined;
        data['recurringContributionsEndDate'] = data['recurringContributionsEndDate']
            ? getYYYYMMDDfromDate(data['recurringContributionsEndDate'])
            : undefined;
        let amountVal = data['amount'];
        var formatedAmountVal = Number(removeNonNumeric(amountVal));
        data['amount'] = formatedAmountVal;
        const recConValue = data['recurringContributions'];
        var formatedrecConValue = Number(removeNonNumeric(recConValue));
        data['recurringContributions'] = formatedrecConValue;
        let fundingSrc;
        if (currentMode === 'edit' && selectedAccountForEdit) {
            let currentFuncdingAccounts = [...fundingAccounts];
            currentFuncdingAccounts = currentFuncdingAccounts.map((source) =>
                source.accountId === selectedAccountForEdit ? data : source
            );

            fundingSrc = currentFuncdingAccounts;
            setFundingAccounts(currentFuncdingAccounts);
        } else {
            fundingSrc = [...fundingAccounts, data];
            setFundingAccounts([...fundingAccounts, data]);
        }
        reset(initialFormValues);
        const copyInvestment = Object.assign(clientData.accounts.investmentsSources);
        if (
            !selectedAccountForEdit ||
            !noUserValuesEntered() ||
            !(JSON.stringify(copyInvestment) === JSON.stringify(fundingSrc))
        )
            setInvestmentSourceEdited(true);
        setSelectedAccountForEdit('');
        setKeyId(uuidv4());
        setCurrentMode('add');
    };

    const persistUnsavedFundingAccounts = async () => {
        try {
            let originalClientData: typeof clientData = JSON.parse(JSON.stringify(clientData));
            const updatedClientData = Object.assign({}, originalClientData);
            let originalGoalsData: typeof adviceGoals = JSON.parse(JSON.stringify(adviceGoals));
            updatedClientData.error = false;
            updatedClientData.loading = false;
            updatedClientData['accounts'] = {
                ...updatedClientData['accounts'],
                investmentsSources: fundingAccounts.filter(
                    (source: any) => !deletedAccountIds.includes(source.accountNumber)
                )
            };

            const updatedGoalsData = Object.assign({}, originalGoalsData);

            if (updatedGoalsData.formFields.length > 0) {
                updatedGoalsData.formFields.forEach((item) => {
                    const goalInvestmentSource = item.data.sources.investmentSource;
                    const investmentSource: any = [];
                    updatedClientData['accounts']['investmentsSources'].forEach((itemData) => {
                        const { isSelected, isRCSelected } = getInvestmentSourceSelection(
                            itemData.accountId,
                            goalInvestmentSource
                        );

                        investmentSource.push({
                            accountNumber: itemData.accountId,
                            accountName: itemData.accountName,
                            isSelected,
                            isRCSelected,
                            accountBalance: itemData.amount,
                            recurringContributions: itemData.recurringContributions,
                            recurringContributionsStartDate: getYYYYMMDDfromDate(
                                dayjs(itemData.recurringContributionsStartDate)
                            ),
                            recurringContributionsEndDate: getYYYYMMDDfromDate(
                                dayjs(itemData.recurringContributionsEndDate)
                            )
                        });
                    });
                    item.data.sources.investmentSource = investmentSource;
                });
            }
            updatedClientData.stages = [...updatedClientData.stages, CLIENT_PROFILE_STAGE4];
            await dispatch(updateClient(updatedClientData));
            await dispatch(updateClientInvestmentSources(updatedClientData.accounts.investmentsSources));
            if (isPlanRun && investmentSourceEdited) {
                updatedGoalsData.formFields.forEach((goal) => {
                    goal.data.sources.investmentSource.forEach((goalInvestmentSrc: any) => {
                        updatedClientData.accounts.investmentsSources.forEach((investmentsrc: any) => {
                            if (investmentsrc.accountId === goalInvestmentSrc.accountNumber) {
                                goalInvestmentSrc.accountBalance =
                                    investmentsrc.frequency === 'Monthly'
                                        ? investmentsrc.amount * 12
                                        : investmentsrc.amount;
                                goalInvestmentSrc.accountName = investmentsrc.sourceName;
                                goalInvestmentSrc.frequency = investmentsrc.frequency;
                                goalInvestmentSrc.recurringContributions = investmentsrc.recurringContributions;
                                goalInvestmentSrc.recurringContributionsEndDate = getYYYYMMDDfromDate(
                                    dayjs(investmentsrc.recurringContributionsEndDate)
                                );
                                goalInvestmentSrc.recurringContributionsStartDate = getYYYYMMDDfromDate(
                                    dayjs(investmentsrc.recurringContributionsStartDate)
                                );
                            }
                        });
                    });
                });
                const updatedFormFields = updateExistingGoals(
                    originalGoalsData.formFields,
                    updatedClientData.accounts.investmentsSources
                );
                updatedGoalsData.global.banner = {
                    visible: true,
                    type: 'attention',
                    isGoalSummary: clientData.step === 2 ? false : true
                };
                if (updatedGoalsData.global.hasRunPipe) {
                    updatedGoalsData.global.status = 'incomplete';
                }
                onEditSuccess?.(deletedAccountIds, updatedFormFields);
            }
            if (isPlanRun && investmentSourceEdited) {
                dispatch(
                    updateBanner({
                        ...banner,
                        visible: true,
                        type: 'attention',
                        isGoalSummary: clientData.step === 2 ? false : true
                    })
                );
            }
            if (updatedGoalsData.global?.enablePDFGenerate) {
                updatedGoalsData.global.enablePDFGenerate = false;
            }
            dispatch(updateGlobalLoaderState(true));
            await dispatch(updateGoal(updatedGoalsData));
            dispatch(updateGlobalLoaderState(false));
            handleReset();
            setOpenDrawer(false);
        } catch (error) {
            console.error('Error in submitting funds', error);
        }
    };
    const showDollarSign = currencySymbol === 'en' || currencySymbol === 'es';
    const checkUnsavedAndhandleClose = async (flag: boolean) => {
        if (fundingAccounts?.length > 0 || currentMode === 'edit') await persistUnsavedFundingAccounts();
        handleReset();
        setOpenDrawer(flag);
    };

    useEffect(() => {
        const locationValue = orgInfo?.investmentAccountLocation?.[language][0].value;
        if (locationValue) setValue('location', locationValue);
    }, []);

    useEffect(() => {
        if (frequency === 'Annually') {
            const startDate = getValues('recurringContributionsStartDate');
            const endDate = getValues('recurringContributionsEndDate');
            if (startDate) {
                const year = startDate.get('year');
                setValue('recurringContributionsStartDate', dayjs().year(year).month(0).date(1));
            }
            if (endDate) {
                const year = endDate.get('year');
                setValue('recurringContributionsEndDate', dayjs().year(year).month(0).date(1));
            }
        }
    }, [frequency]);

    const handleEditClick = (accountId: string) => {
        setSelectedAccountForEdit(accountId);
        setCurrentMode('edit');
    };

    const handleDeleteClick = (accountId: string) => {
        setSelectedAccountForEdit(accountId);
        setDeleteModalState(true);
    };

    const handleConfirmDelete = () => {
        handleReset();
        const updatedSources = fundingAccounts.filter((source) => source.accountId !== selectedAccountForEdit);
        setDeletedAccountIds([...deletedAccountIds, selectedAccountForEdit]);
        setFundingAccounts(updatedSources);
        setDeleteModalState(!deleteModalState);
        setInvestmentSourceEdited(true);
        setIsDelete(true);
    };

    const handleCancelDelete = async () => {
        setDeleteModalState(!deleteModalState);
    };
    // eslint-disable-next-line no-unused-vars
    const handleDateChange = (value: dayjs.Dayjs, onChange: (event: dayjs.Dayjs) => void) => {
        if (frequency === 'Annually') {
            onChange(value?.startOf('year'));
        } else {
            onChange(value?.startOf('month'));
        }
        return;
    };

    const isDeleteClicked = () => {
        if (isDelete) {
            return !isDelete;
        } else {
            return !isValid;
        }
    };

    function removeNullUndefined(obj: any) {
        if (obj === null || obj === undefined) {
            return {}; // Return an empty object if the input is null or undefined
        }

        // Create a new object without null or undefined values
        return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== null && value !== undefined));
    }

    const formValues = watch();
    // Function to check if form is edited
    const checkIfFormIsEdited = () => {
        const fundingSource = fundingAccounts.filter((source) => source.accountId == selectedAccountForEdit);
        let data = getValues();
        let amountVal = data['amount'];
        var stringAmountVal = Number(removeNonNumeric(amountVal));
        data['amount'] = stringAmountVal;
        if (data?.recurringContributionsStartDate != null && data?.recurringContributionsEndDate != null) {
            data['recurringContributionsStartDate'] = getYYYYMMDDfromDate(data['recurringContributionsStartDate']);
            data['recurringContributionsEndDate'] = getYYYYMMDDfromDate(data['recurringContributionsEndDate']);
        }
        return fundingSource.length > 0
            ? !(JSON.stringify(removeNullUndefined(fundingSource[0])) === JSON.stringify(removeNullUndefined(data)))
            : !isEqual(initialFormValues, formValues);
    };

    return (
        <DrawerComp
            subHeading={t(currentMode === 'edit' ? 'DRAWER_INCOME_SOURCE_HEADER' : 'ADD_FUNDING_SUB_HEADING')}
            open={openDrawer}
            buttonlabel="Complete"
            header="Accounts"
            handleClose={checkUnsavedAndhandleClose}
            onSubmit={submitFunds}
            drawerIcon={<img src={investmentSource} />}
            secondaryButtonLabel={t(currentMode === 'edit' ? 'SAVE_SOURCE' : 'BUTTON_ADD_ANOTHER_ACCOUNT')}
            onSecondarySubmit={handleSubmit((data) => {
                addAnotherFund(data);
            })}
            disableSubmit={isDeleteClicked()}
            hasChange={checkIfFormIsEdited()}
            isValid={!isValid}
        >
            {fundingAccounts?.length >= 0 && (
                <MonetarySource
                    icon={<img src={investmentSource} />}
                    title={t('SUBTEXT_FUNDING_SOURCE_WEALTH')}
                    readOnly
                    accountData={fundingAccounts.reduce((acc: Record<string, Source>, curr) => {
                        acc[curr.accountId] = {
                            accountNumber: curr.accountId,
                            accountName: curr.accountName,
                            isSelected: false,
                            isRCSelected: false,
                            accountBalance: curr.amount,
                            ...(curr.frequency && { frequency: curr.frequency }),
                            ...(curr.recurringContributions && {
                                recurringContributions: curr.recurringContributions
                            })
                        };
                        return acc;
                    }, {})}
                    onEditClick={handleEditClick}
                    onDeleteClick={handleDeleteClick}
                    isOpen={false}
                    showContribution={true}
                />
            )}
            <ConfirmationDialog
                onCancellation={handleCancelDelete}
                onConfirmation={() => handleConfirmDelete()}
                open={deleteModalState}
                content={`${t('DELETE_CONFIRMATION_MODAL_CONTENT_INVESTMENT')}`}
                confirmButtonLabel={`${t('DELETE_CONFIRMATION_MODAL_DELETE_TEXT')}`}
                cancelButtonLabel={`${t('DELETE_CONFIRMATION_MODAL_CANCEL_TEXT')}`}
                title={t('DELETE_CONFIRMATION_MODAL_HEADER_TEXT_INVESTMENT')}
                width={WIDTH}
            />
            <Grid container marginTop={2} marginBottom={2}>
                <Typography
                    variant="headers.h4"
                    label={t(
                        currentMode === 'edit' && selectedAccountForEdit
                            ? 'DRAWER_EDIT_INVESTMENT_SOURCE'
                            : 'DRAWER_ADD_NEW_FUNDING_SOURCE'
                    )}
                />
            </Grid>
            <Grid paddingTop={0} marginBottom={2}>
                <StyledInfoText variant="body1" color="neutral.grey.400" label={t('TEXT_REQUIRED_FIELDS')} />
            </Grid>
            <FundingFormWrapper key={keyId}>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant="labels.large" label={`${t('TEXT_ACCOUNT_NAME')} *`} />
                    <Controller
                        name="accountName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_ACCOUNT_NAME_REQUIRED_FIELD')
                            },
                            pattern: {
                                value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s'(),.-]*$/,
                                message: t('TEXT_ACCOUNT_NAME_VALIDATION')
                            },
                            maxLength: {
                                value: 30,
                                message: t('TEXT_ACCOUNT_NAME_MAX_LENGTH')
                            }
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                inputRef={ref}
                                size="small"
                                error={!!errors.accountName}
                                type="text"
                                data-testid="account-name"
                                fullWidth
                                min={0}
                            />
                        )}
                    />
                    {errors.accountName ? (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.accountName.message} />
                    ) : null}
                </ItemContainer>
                <ItemContainer sx={{ alignItems: 'flex-start' }}>
                    <Typography variant="labels.large" label={`${t('TEXT_ACCOUNT_TYPE')} *`} />
                    <Controller
                        name="accountType"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                            }
                        }}
                        render={({ field }) => (
                            <ComboSelect
                                {...field}
                                size="small"
                                onSelectChange={field.onChange}
                                selectedValue={field.value}
                                options={orgInfo?.investmentAccountType?.[language] || []}
                                data-testid="accountType"
                                error={!!errors.accountType}
                            />
                        )}
                    />
                    {errors.accountType ? (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.accountType.message} />
                    ) : null}
                </ItemContainer>
                <Grid container item direction="row" xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <Controller
                            name="accountHolder"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: t('TEXT_THIS_IS_REQUIRED_FIELD')
                                }
                            }}
                            render={({ field }) => (
                                <StyledAccountHolderRadioOption
                                    {...field}
                                    label={`${t('TEXT_ACCOUNT_HOLDER')} *`}
                                    options={accountHolderOptions}
                                />
                            )}
                        />
                        {errors.accountHolder ? (
                            <ErrorSpan variant="subtitle2" role="alert" label={errors.accountHolder.message} />
                        ) : null}
                    </Grid>
                </Grid>
                <ItemContainer sx={{ alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant="labels.large" label={`${t('WEALTH_AMOUNT')} *`} />
                    <Controller
                        name="amount"
                        control={control}
                        rules={{
                            validate: (value) => {
                                return Number(removeNonNumeric(value)) > 0 || t('TEXT_THIS_IS_REQUIRED_FIELD');
                            }
                        }}
                        render={({ field: { onBlur, value, ref } }) => (
                            <TextboxInput
                                onBlur={() => {
                                    onBlur();
                                }}
                                onChange={(e) => {
                                    handleAmountChange(e.target.value);
                                }}
                                value={value ? formatCurrency(value) : ''}
                                inputRef={ref}
                                size="small"
                                min={0}
                                error={!!errors.amount}
                                type="text"
                                data-testid="Amount"
                                placeholder={showDollarSign ? t('TEXT_AMOUNT') : t('CA_TEXT_NAME')}
                                fullWidth
                            />
                        )}
                    />
                    {errors.amount ? (
                        <ErrorSpan variant="subtitle2" role="alert" label={errors.amount.message} />
                    ) : null}
                </ItemContainer>
                <Grid container item direction="row" xs={12} spacing={2}>
                    <Grid item xs={7} sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
                        <Typography variant="labels.large" label={`${t('TEXT_RECURRING_CONTRIBUTIONS')}`} />
                        <Controller
                            name="recurringContributions"
                            control={control}
                            rules={{
                                validate: (value) => {
                                    if (value) {
                                        return Number(removeNonNumeric(value)) >= 0 || t('TEXT_MIN_VALUE');
                                    }
                                    return true;
                                }
                            }}
                            render={({ field: { onBlur, value, ref } }) => (
                                <TextboxInput
                                    onBlur={onBlur}
                                    onChange={(e) => {
                                        handleRecurringContributionsChange(e.target.value);
                                    }}
                                    size="small"
                                    value={value ? formatCurrency(value) : ''}
                                    inputRef={ref}
                                    min={0}
                                    error={!!errors.recurringContributions}
                                    type="text"
                                    data-testid="recurring-contributions"
                                    placeholder={showDollarSign ? t('TEXT_AMOUNT') : t('CA_TEXT_NAME')}
                                    fullWidth
                                />
                            )}
                        />
                        {errors.recurringContributions ? (
                            <ErrorSpan variant="subtitle2" role="alert" label={errors.recurringContributions.message} />
                        ) : null}
                    </Grid>
                    <Grid item xs={5}>
                        <ItemContainer sx={{ alignItems: 'flex-start' }}>
                            <Typography variant="labels.large" label={`${t('FREQUENCY')}`} />
                            <Controller
                                name="frequency"
                                control={control}
                                rules={{
                                    validate: (value) => {
                                        if (
                                            recurringContributions &&
                                            Number(removeNonNumeric(recurringContributions)) > 0
                                        ) {
                                            return (
                                                (Number(removeNonNumeric(recurringContributions)) > 0 && !!value) ||
                                                t('TEXT_THIS_IS_REQUIRED_FIELD')
                                            );
                                        }
                                        return true;
                                    }
                                }}
                                render={({ field }) => (
                                    <ComboSelect
                                        {...field}
                                        size="small"
                                        onSelectChange={async (val: string) => {
                                            setValue('frequency', val, { shouldValidate: true });
                                        }}
                                        selectedValue={field.value}
                                        options={[
                                            { label: t('ANNUALLY'), value: 'Annually' },
                                            { label: t('MONTHLY'), value: 'Monthly' }
                                        ]}
                                        data-testid="frequency"
                                        error={!!errors.frequency}
                                    />
                                )}
                            />
                            {errors.frequency ? (
                                <ErrorSpan variant="subtitle2" role="alert" label={errors.frequency.message} />
                            ) : null}
                        </ItemContainer>
                    </Grid>
                    {/* leaving the commented code for future purpose */}
                    {/* <Grid item xs={4}>
                        <Placeholder />

                        <Grid container alignItems="center" justifyContent="space-between" height={62} width="80px">
                            <Frequency
                                selected={getValues('frequency') === 'Yearly'}
                                onClick={() => {
                                    clearErrors('frequency');
                                    setValue('frequency', 'Yearly', { shouldValidate: true });
                                }}
                            >
                                Y
                            </Frequency>
                            <Frequency
                                selected={getValues('frequency') === 'Monthly'}
                                onClick={() => {
                                    clearErrors('frequency');
                                    setValue('frequency', 'Monthly', { shouldValidate: true });
                                }}
                            >
                                M
                            </Frequency>
                            
                        </Grid>

                        {errors.frequency &&
                        Number(removeNonNumeric(getValues('recurringContributions'))) > 0 &&
                        Object.keys(errors.frequency ?? {}).length > 0 ? (
                            <ErrorSpan variant="subtitle2" role="alert" label={errors.frequency.message} />
                        ) : null}
                    </Grid> */}
                </Grid>
                <Grid container item direction="row" xs={12} gap={1}>
                    <Controller
                        name="recurringContributionsStartDate"
                        control={control}
                        rules={{
                            validate: (value) => {
                                if (recurringContributions && Number(removeNonNumeric(recurringContributions)) > 0) {
                                    if (value) {
                                        if (getValues('frequency') === 'Annually') {
                                            const selectedYear = value.year();
                                            const currentYear = dayjs().year();

                                            if (selectedYear <= currentYear) {
                                                return t('TEXT_VALID_DATE_ERROR');
                                            }
                                        } else {
                                            if (dayjs().isAfter(value) || dayjs().isSame(value)) {
                                                return t('TEXT_VALID_DATE_ERROR');
                                            }
                                        }
                                    }
                                    return (
                                        (Number(removeNonNumeric(value)) > 0 && value !== null) ||
                                        t('TEXT_THIS_IS_REQUIRED_FIELD')
                                    );
                                }

                                return true;
                            }
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <>
                                <Typography variant="labels.large" label={`${t('BEGINING_YEAR')} *`} />
                                <BasicDatePicker
                                    enabled
                                    type="single"
                                    startDate={currentDate.toString()}
                                    {...(frequency === 'Annually' ? { isYyyy: true } : { isMmYyyy: true })}
                                    value={value ?? null}
                                    onChange={(event: any) => handleDateChange(event, onChange)}
                                    onBlur={onBlur}
                                    data-testid="recurring-contributions-date"
                                    hasError={!!errors.recurringContributionsStartDate}
                                />
                            </>
                        )}
                    />
                    {errors.recurringContributionsStartDate && (
                        <ErrorSpan
                            variant="subtitle2"
                            role="alert"
                            label={errors?.recurringContributionsStartDate?.message}
                        />
                    )}
                </Grid>
                <Grid container item direction="row" xs={12} gap={1}>
                    <Controller
                        name="recurringContributionsEndDate"
                        control={control}
                        rules={{
                            validate: (value) => {
                                if (recurringContributions && Number(removeNonNumeric(recurringContributions)) > 0) {
                                    if (value) {
                                        const startDate = getValues('recurringContributionsStartDate');
                                        if (getValues('frequency') === 'Annually') {
                                            const selectedYear = value.year();
                                            const startYear = startDate?.year();

                                            if (startYear && selectedYear <= startYear) {
                                                return t('TEXT_VALID_END_DATE_ERROR');
                                            }
                                        } else {
                                            if (startDate?.isAfter(value) || startDate?.isSame(value)) {
                                                return t('TEXT_VALID_END_DATE_ERROR');
                                            }
                                        }
                                    }
                                    return (
                                        (Number(removeNonNumeric(value)) > 0 && value !== null) ||
                                        t('TEXT_THIS_IS_REQUIRED_FIELD')
                                    );
                                }
                                return true;
                            }
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <>
                                <Typography variant="labels.large" label={`${t('END_YEAR')} *`} />

                                <BasicDatePicker
                                    enabled
                                    type="single"
                                    startDate={currentDate.toString()}
                                    {...(frequency === 'Annually' ? { isYyyy: true } : { isMmYyyy: true })}
                                    value={value ?? null}
                                    onChange={(event: any) => handleDateChange(event, onChange)}
                                    onBlur={onBlur}
                                    data-testid="recurring-contributions-end-date"
                                    hasError={!!errors.recurringContributionsEndDate}
                                />
                            </>
                        )}
                    />
                    {errors.recurringContributionsEndDate && (
                        <ErrorSpan
                            variant="subtitle2"
                            role="alert"
                            label={errors?.recurringContributionsEndDate?.message}
                        />
                    )}
                </Grid>
            </FundingFormWrapper>
        </DrawerComp>
    );
};
