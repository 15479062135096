import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { logEventToBackEnd } from '../global/globalSlice';
import { sumBy } from 'lodash';
import { uipApiInstance } from '../../services';
import {
    AdvisorConsoleStore,
    AdvisorConsoleStoreFilters,
    GetAdvisorConsoleProposalsPayload,
    GetProposalsResponse,
    DeletePlanPayload
} from './types';
import { GoalTypeEnum, GoalTypeTitlemapping } from '../../common/types/clients-entity';
import { AdminTableItem } from 'components/molecules/AdminTable';
import { calculateEquityFixedIncome, filterDataItems, getItemsByPage, getNumberOfPages } from './utils';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';
import { priorityOptions, riskProfileOptions, statusOptions, typeOptions } from '../../common/constants';
import dayjs from 'dayjs';

function calculateAmountAfterMonths(startDateInput: string, endDateInput: string): number {
    // Helper function to parse DD-MM-YYYY formatted date
    const parseDate = (dateInput: string): Date => {
        const [month, day, year] = dateInput.split('-').map(Number);
        return new Date(year, month - 1, day); // months are 0-indexed in JavaScript Date
    };

    const currentDate = new Date();
    const startDate = parseDate(startDateInput);
    const endDate = parseDate(endDateInput);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // current month is 0-indexed, so add 1
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth() + 1;
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;
    if (currentYear < startYear) {
        return 0; // Current year is before the start year
    }
    if (currentYear > endYear) {
        return 0; // Current year is after the end year
    }
    if (currentYear === startYear) {
        return 12 - (startMonth - 1); // Current year and start year match
    }
    if (currentYear === endYear) {
        if (currentMonth <= endMonth) {
            return endMonth; // Current year and end year match, and current month <= end month
        } else {
            return 0; // Current month is after the end month
        }
    }
    return 12; // Default case when current year is between start year and end year
}

const calculateInitialAmount = (accounts = [], incomeSource = [], investmentSource = []) => {
    const sumIncomeSource = sumBy(
        incomeSource,
        ({ isSelected, accountBalance, beginningYear = '01-01-2025', endYear = '01-01-2025', frequency }: any) => {
            if (!isSelected) {
                return 0;
            }
            let partsbeginningYear = beginningYear.split('-');
            let partsendYear = endYear.split('-');
            let formattedDatebeginningYear = `${partsbeginningYear[1]}-${partsbeginningYear[0]}-${partsbeginningYear[2]}`;
            let formattedendYear = `${partsendYear[1]}-${partsendYear[0]}-${partsendYear[2]}`;
            const result = calculateAmountAfterMonths(formattedDatebeginningYear, formattedendYear);
            const updatedAmount = frequency === 'Annually' ? accountBalance : result * (accountBalance / 12);
            return updatedAmount;
        }
    );

    const sumInvestmentSource = sumBy(
        investmentSource,
        ({
            isRCSelected,
            frequency,
            recurringContributions,
            recurringContributionsStartDate,
            recurringContributionsEndDate
        }: any) => {
            if (!isRCSelected) {
                return 0;
            }
            const result = calculateAmountAfterMonths(
                recurringContributionsStartDate || '01-01-2025',
                recurringContributionsEndDate || '01-01-2025'
            );
            const updatedAmount = frequency === 'Annually' ? recurringContributions : result * recurringContributions;
            return updatedAmount;
        }
    );

    const annualContribution = sumIncomeSource + sumInvestmentSource;

    const initialInvestment = sumBy(accounts, 'amount') || 0;
    return { initialInvestment, annualContribution };
};

// adviceGoals?.response?.filter((g) => g?.response?.body?.analysisReport?.isGoalRealistic);
export const getAdvisorConsoleProposals = createAsyncThunk(
    'advisorConsole/getproposals',
    async ({ tenant }: GetAdvisorConsoleProposalsPayload, { dispatch, rejectWithValue, getState }) => {
        try {
            const { data: apiResponse } = await uipApiInstance<GetProposalsResponse>({
                method: 'GET',
                url: `/api/advice/getproposals?tenant=${tenant}`
            });

            const fullState: any = getState();
            const dataItems: AdminTableItem[] =
                apiResponse?.data?.map(
                    ({
                        clientFirstName,
                        clientLastName,
                        form_fields = [],
                        global,
                        _id,
                        riskProfile,
                        response,
                        request
                    }: any) => {
                        let plan_portfolio: any = undefined;
                        let is_any_realistic = false;
                        const subItems =
                            form_fields?.map((formField: any) => {
                                const { data, id, goalId } = formField;
                                const goal = (GoalTypeTitlemapping as { [key: string]: GoalTypeEnum })[
                                    data.wealthGoalType!
                                ];

                                const goal_data = request.filter(
                                    (itemRequest: any) => goalId === itemRequest?.goalId
                                )[0];

                                const is_goal_realistic = response.filter(
                                    (itemRequest: any) => goalId === itemRequest?.goalId
                                )[0]?.response?.body?.analysisReport?.isGoalRealistic;

                                let incomeSource: any = data?.sources?.incomeSource || [];
                                let investmentSource: any = data?.sources?.investmentSource || [];
                                const cleanPortfolioValue = calculateInitialAmount(
                                    goal_data?.accounts,
                                    incomeSource,
                                    investmentSource
                                );

                                let goalPortfolioValue = null;
                                if (is_goal_realistic) {
                                    goalPortfolioValue =
                                        cleanPortfolioValue?.annualContribution +
                                        (goal_data?.isIWSApplied
                                            ? goal_data?.recommendedWealthSplit
                                            : cleanPortfolioValue.initialInvestment);

                                    if (plan_portfolio == undefined) {
                                        plan_portfolio = goalPortfolioValue;
                                    } else {
                                        if (goalPortfolioValue < plan_portfolio) plan_portfolio = goalPortfolioValue;
                                    }
                                    is_any_realistic = true;
                                }

                                return {
                                    goal,
                                    portfolioValue: is_goal_realistic ? goalPortfolioValue : -1,
                                    probabilityofSuccess: data.probabilityOfSuccess,
                                    id: [_id, id, goalId].filter(Boolean).join('_'),
                                    goalPriority: data?.goalPriority || '',
                                    goalType: data?.goalType,
                                    equityFixedIncome:
                                        data?.equityFixedIncome ||
                                        calculateEquityFixedIncome({
                                            portfolios: fullState.global.portfolio,
                                            recommendedPortfolioId:
                                                response?.[0]?.response?.body?.analysisReport?.recommendedPortfolioId
                                        })
                                };
                            }) || [];

                        const numberofGoals = ~~form_fields?.length;

                        let lastUpdated = '';
                        if (global?.lastUpdateDate && dayjs(global?.lastUpdateDate)?.isValid()) {
                            lastUpdated = dayjs(global?.lastUpdateDate).format('MM/DD/YYYY');
                        }
                        return {
                            id: _id,
                            clientGoalName: [clientFirstName, clientLastName].filter(Boolean).join(' '),
                            status: global?.status ?? 'incomplete',
                            probabilityOfSuccess: global?.probabilityOfSuccess,
                            equityFixedIncome: global?.equityFixedIncome,
                            // portfolioApproach,
                            riskGoalPriority: riskProfile,
                            portfolioValue: is_any_realistic && numberofGoals && plan_portfolio ? plan_portfolio : -1,
                            numberofGoals,
                            subItems,
                            lastUpdated
                        };
                    }
                ) || [];

            const itemsPerPage = 10;

            const pagination: AdvisorConsoleStore['pagination'] = {
                page: 1,
                totalPages: getNumberOfPages(dataItems.length, itemsPerPage),
                itemsPerPage,
                totalItems: dataItems.length
            };

            const items = getItemsByPage({
                dataItems,
                itemsPerPage,
                page: 1
            });

            const clients = _sortBy(
                _uniqBy(
                    dataItems
                        ?.filter(({ clientGoalName }) => !!clientGoalName)
                        ?.map(({ clientGoalName }) => {
                            const name = clientGoalName?.toUpperCase();

                            return {
                                value: name,
                                label: clientGoalName
                            };
                        }),
                    'value'
                ),
                'label'
            );

            const keyValueRequest = apiResponse?.data?.reduce((acc: Record<string, any>, { _id, ...rest }: any) => {
                acc[_id] = { ...rest, _id };
                return acc;
            }, {});

            return {
                dataItems,
                items,
                pagination,
                clients,
                keyValueRequest
            };
        } catch (err: any) {
            console.log(err);
            dispatch(logEventToBackEnd('GET_PROPOSALS_API_ERROR'));
            if (err?.code === 'ERR_BAD_REQUEST') {
                return {
                    dataItems: [],
                    items: [],
                    pagination: {
                        page: 1,
                        totalPages: 1,
                        itemsPerPage: 0,
                        totalItems: 0
                    },
                    clients: [],
                    keyValueRequest: {}
                };
            }
            return rejectWithValue(err);
        }
    }
);

export const deletePlan = createAsyncThunk(
    'advisorConsole/deleteclientprofile',
    async ({ tenant, planId }: DeletePlanPayload, { dispatch, rejectWithValue }) => {
        try {
            const response = await uipApiInstance({
                method: 'POST',
                url: `/api/advice/deleteclientprofile/${planId}?tenant=${tenant}`,
                withCredentials: false
            });
            if (response.data.success) {
                dispatch(getAdvisorConsoleProposals({ tenant }));
            }
        } catch (err) {
            console.log(err);
            dispatch(logEventToBackEnd('DELETE_PLAN_ERROR'));
            return rejectWithValue(err);
        }
    }
);

const INITIAL_STATE: AdvisorConsoleStore = {
    loading: false,
    pagination: {
        page: 1,
        totalPages: 1,
        itemsPerPage: 10,
        totalItems: 0
    },
    items: [],
    dataItems: [],
    clients: [],
    error: false,
    filters: {
        status: statusOptions[0]?.value,
        riskProfile: riskProfileOptions[0]?.value,
        type: typeOptions[0]?.value,
        priority: priorityOptions[0]?.value
    },
    hasFilters: false,
    keyValueRequest: {}
};

const advisorConsole = createSlice({
    name: 'advisorConsole',
    initialState: INITIAL_STATE,
    reducers: {
        changePage: (state, action: PayloadAction<number>) => {
            const {
                pagination: { itemsPerPage }
            } = state;

            const dataItems = filterDataItems({
                dataItems: state.dataItems,
                filters: state.filters
            });

            const page = action.payload;

            state.pagination.page = page;

            state.items = getItemsByPage({
                dataItems,
                itemsPerPage,
                page
            });
        },
        changeItemsPerPage: (state, action: PayloadAction<number>) => {
            const page = 1; // force back to page 1 to prevent bugs
            const itemsPerPage = action.payload;

            const dataItems = filterDataItems({
                dataItems: state.dataItems,
                filters: state.filters
            });

            state.pagination = {
                ...state.pagination,
                page,
                totalPages: getNumberOfPages(dataItems.length, itemsPerPage),
                itemsPerPage
            };

            const items = getItemsByPage({
                dataItems,
                itemsPerPage,
                page
            });

            state.items = items;
        },
        setFilters: (state, action: PayloadAction<AdvisorConsoleStoreFilters>) => {
            const page = 1; // force back to page 1 to prevent bugs

            /**
             *
             *
             *
             *
             */
            /// TODO: FINISH FILTERS VALIDAITONS

            const filteredDataItems = filterDataItems({
                dataItems: state.dataItems,
                filters: action.payload
            });

            const items = getItemsByPage({
                dataItems: filteredDataItems,
                itemsPerPage: state.pagination.itemsPerPage,
                page
            });

            state.items = items;

            state.pagination = {
                ...state.pagination,
                page,
                totalPages: getNumberOfPages(filteredDataItems.length, state.pagination.itemsPerPage),
                totalItems: filteredDataItems.length
            };

            state.filters = action.payload;

            state.hasFilters = true;
        },
        resetAdviceConsoleReducer: () => INITIAL_STATE
    },
    extraReducers: (builder) => {
        builder.addCase(getAdvisorConsoleProposals.pending, (state) => {
            state.loading = true;
        }),
            builder.addCase(getAdvisorConsoleProposals.fulfilled, (state, action) => {
                const { dataItems, items, pagination, clients, keyValueRequest } = action.payload;

                state.dataItems = dataItems;
                state.items = items;
                state.pagination = pagination;
                state.clients = clients;
                state.keyValueRequest = keyValueRequest;
                state.loading = false;
                state.error = false;
            }),
            builder.addCase(getAdvisorConsoleProposals.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });
    }
});

export const { actions: advisorConsoleActions } = advisorConsole;

export default advisorConsole.reducer;