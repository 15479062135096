import { isEmpty, isUndefined } from 'lodash';
import moment from 'moment';
import { format, isValid, parseISO } from 'date-fns';
import { ReRunOptionType } from '../common/types';
import store from '../redux/store';

const currentState = store.getState();

export function getInitials(name: string): string {
    let initials;
    const splittedName: any = name.split(' ').filter(Boolean);

    if (!isUndefined(splittedName) && splittedName.length > 1) {
        initials = splittedName[0].charAt(0) + splittedName[splittedName.length - 1].charAt(0);
    } else {
        initials = name.substring(0, 2);
    }
    return initials.toUpperCase();
}

export function deepEqual(obj1: any, obj2: any) {
    // Check if both are objects and not null
    if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
        const keys1 = Object.keys(obj1).sort();
        const keys2 = Object.keys(obj2).sort();

        // Check if keys length is the same
        if (keys1.length !== keys2.length) {
            return false;
        }

        // Check keys and values
        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }
        return true; // Objects are equal
    } else {
        // If not objects, do a strict comparison
        return obj1 === obj2;
    }
}

export function calculateYearsBetweenDates(startDate: any, dateStr: string) {
    const [day, month, year] = dateStr?.split('-').map(Number);
    let start = startDate;
    if (typeof startDate === 'string') {
        const parts = startDate?.split('-'); // Split into [day, month, year]
        const reformattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`; // "2023-05-26"
        const newStartDate = new Date(reformattedDate);
        start = new Date(newStartDate);
    }
    const endDate = new Date(year, month - 1, day);
    const end = new Date(endDate);
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    if (months < 0) {
        years--;
        months += 12;
    }
    const fractionalYears = years + months / 12;
    return fractionalYears.toFixed(1);
}

export const getCurrentYear = () => {
    const year = moment().year();

    return year.toString();
};
// The below mapper needs to be updated to add more countries
const dateCountryMapper: any = {
    US: 'mm/dd/yyyy'
};
export const SHORT_DATE_FORMAT = dateCountryMapper[currentState?.global?.globalConfig?.countryCode] || 'mm/dd/yyyy';

export const isValidDate = (date: string | Date): boolean => {
    if (typeof date === 'string') {
        return isValid(parseISO(date));
    }
    return isValid(date);
};
export const toShortDate = (date: string) => {
    const newDate = new Date(date);
    return isValidDate(newDate) ? format(newDate, SHORT_DATE_FORMAT) : null;
};

export function formatCurrency(number = 0): string {
    const localization = store.getState()?.global?.globalConfig?.currencySymbol;
    return `${getCurrency(localization)}${formatNumber(number)}`;
}

export function formatNumber(number = 0): string {
    let formattedNumber = new Intl.NumberFormat().format(number);
    return formattedNumber;
}

export function getCurrency(localization: string) {
    switch (localization) {
        case 'en':
            return '$';
        case 'en_ca':
            return 'CAD $';
        default:
            return '$';
    }
}

export const spaceToHyphen = (phrase: string) => {
    let hyphenatedString = '';
    if (!isEmpty) {
        hyphenatedString = phrase.split('').join('-');
    }
    return hyphenatedString;
};

export const formatCurrencyOrYearWithPlus = (optionValue: ReRunOptionType['optionValue']) => {
    if (typeof optionValue === 'number') return `+${formatCurrency(optionValue)}`;
    return `+${optionValue}`;
};

export const formatCurrencyOrYearWithMinus = (optionValue: ReRunOptionType['optionValue']) => {
    if (typeof optionValue === 'number') return `-${formatCurrency(optionValue)}`;
    return `-${optionValue}`;
};

// export const formatCurrencyDollar = (num: any, doNotAllowZero = true, showDollar = true) => {
//     let value = num.toString();
//     value = value.replace(/[^.0-9]/g, '');

//     if (doNotAllowZero) {
//         value = value.replace(/\b0+/g, '');
//     }

//     let splittedValue = value.split('.');

//     if (splittedValue[1]?.length > 2) {
//         splittedValue[1] = splittedValue[1][0] + splittedValue[1][1];
//         value = [splittedValue[0], splittedValue[1]].join('.');
//     }

//     if (splittedValue?.length > 2) {
//         value = [splittedValue[0], splittedValue[1]].join('.');
//     }

//     value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//     return showDollar ? '$' + (value || 0) : value;
// };

export const removeNonNumeric = (num: any) => {
    return num?.toString()?.replace(/[^.0-9]/g, '');
};
export const numberWithCommas = (x: any) => x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const isEqual = (obj1: Record<string, any>, obj2: Record<string, any>): boolean => {
    const normalize = (obj: Record<string, any>): [string, any][] => {
        return Object.entries(obj)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort by key
            .map(([key, value]) => [key, value]);
    };

    const normalizedObj1 = normalize(obj1);
    const normalizedObj2 = normalize(obj2);

    return JSON.stringify(normalizedObj1) === JSON.stringify(normalizedObj2);
};

export const sortObjectKeysAlphabetically = (obj: any) => {
    // Get the keys of the object and sort them alphabetically
    const sortedKeys = Object.keys(obj).sort();

    // Create a new object with keys in sorted order
    const sortedObj: any = {}; // Use `any` to allow dynamic key-value assignment

    sortedKeys.forEach((key) => {
        sortedObj[key as keyof any] = obj[key as keyof any]; // Type casting to ensure proper key-value types
    });

    return sortedObj;
};

export const Tenant = {
    trial: 'Trial',
    fullAccess: 'Full Access',
    demo: 'Demo'
};

export const Localization = {
    en: 'English-US',
    en_ca: 'English-CA',
    es: 'Spanish-MEX'
};
