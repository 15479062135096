import { styled } from '@mui/material';
import retirement from '../assets/images/pdf/retirement.svg';
import bequest from '../assets/images/pdf/bequest.svg';
import buyahouse from '../assets/images/pdf/buyahouse.svg';
import buyacar from '../assets/images/pdf/buyacar.svg';
import payforcollege from '../assets/images/pdf/payforcollege.svg';
import custom from '../assets/images/pdf/custom.svg';
import star from '../assets/images/pdf/star.svg';

export const getIconForPDF = (type: any, size?: string) => {
    const SVGforPDF = styled('img')(() => ({
        width: size || '20px',
        height: size || '20px'
    }));
    if (type === 'retirement') return <SVGforPDF src={retirement} />;
    if (type === 'bequest') return <SVGforPDF src={bequest} />;
    if (type === 'buyahouse') return <SVGforPDF src={buyahouse} />;
    if (type === 'buyacar') return <SVGforPDF src={buyacar} />;
    if (type === 'payforcollege') return <SVGforPDF src={payforcollege} />;
    if (type === 'custom') return <SVGforPDF src={custom} />;
    if (type === 'star') return <SVGforPDF src={star} />;
    return;
};
