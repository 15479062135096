import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet, useNavigate, Navigate } from 'react-router-dom';

import { Spinner } from '../components/atoms';
import { ClickWrapAgreement } from '../components/molecules/ClickWrapAgreement/ClickWrapAgreement';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { logEventToBackEnd, saveUserAgreementsSettings, updateGlobalLoaderState } from '../features/global/globalSlice';
import { logout } from '../features/auth/authSlice';
import dayjs from 'dayjs';
import { LeftPanel } from '../components/molecules/LeftPanel/LeftPanel';
import AddIncomeSourcesDrawer from '../containers/add-income-sources-drawer';
import { AddInvestmentAccounts } from '../containers/add-investment-accounts';
import { RiskProfileDrawer } from '../containers/risk-tolerance-drawer';
import { PersonalInfoAndRelationshipsDrawer } from '../containers/create-client-step/PersonalInfoAndRelationshipsDrawer';
import {
    calculateAnnualContribution,
    calculateTotalIncome,
    calculateTotalWealthAvailable
} from '../containers/funding-source-list/FundingSource';
import { removeLoader, showLoader, updateGoal } from '../features/client-goals';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { Footer } from '../components/molecules';
import { getCurrentYear } from '../utils';
import ScrollToTop from './scrollToTop';
import { useLocation } from 'react-router-dom';

const allowedPathsForAdvisor = ['/clients', '/createclient', '/creategoals', '/reviewplan'];
const displaySidebarPaths = ['/creategoals', '/reviewplan'];

const StyledContainer = styled('div')(() => ({
    display: 'flex',
    paddingTop: '60px'
}));

const StyledBodyContainer = styled('div')(() => ({
    flex: 1,
    minWidth: 0
}));

const MainSectionContainer: any = styled('main')(({ isAuthenticated }: any) => {
    if (!isAuthenticated) {
        return {
            minHeight: '74vh'
        };
    }

    return {
        minHeight: '78vh',
        margin: '0',
        width: '100%'
    };
});

export const RequiredAuth: React.FC = () => {
    const { t } = useTranslation();
    const okta = useOktaAuth();
      const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isAuthenticated = okta?.authState?.isAuthenticated;
    const [activeDrawer, setActiveDrawer] = useState<
        null | 'client' | 'relationship' | 'incomeSource' | 'wealthSource' | 'riskProfile'
    >(null);
    const user = useAppSelector((state) => state.auth.user);
    const global = useAppSelector((state) => state.global);
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const adviceGoals = useAppSelector((state) => state.adviceGoals);

    const {
        clientFirstName,
        clientLastName,
        relationship,
        riskProfile,
        accounts: { incomeSources, investmentsSources }
    } = adviceClient;
    const clientFullName = `${clientFirstName} ${clientLastName}`;
    const totalAnnualIncome = calculateTotalIncome(incomeSources);
    const totalWealthAvailable = calculateTotalWealthAvailable(investmentsSources);
    const totalAnnualContributions = calculateAnnualContribution(investmentsSources);
    const clientRelationship = `${relationship[0]?.firstName} ${relationship[0]?.lastName}`;
    const leftPanelData = {
        name: clientFullName,
        relationship: relationship.length > 0 ? clientRelationship : null,
        riskProfile: riskProfile,
        totalIncomeAvailable: totalAnnualIncome,
        totalRetirementAvailable: 253330000,
        annualRetirementContributions: 50000,
        totalWealthAvailable: totalWealthAvailable,
        annualWealthContributions: totalAnnualContributions,
        goalAmount: 20240
    };

    const handleLogout = async () => {
        updateGlobalLoaderState(true);
        try {
            const sessionExists = await okta.oktaAuth.session.exists();
            if (sessionExists) {
                await okta.oktaAuth.closeSession();
            }
        } finally {
            const isUserAuthenticated = okta.authState?.isAuthenticated;
            if (isUserAuthenticated) {
                await dispatch(logEventToBackEnd('LOGOUT_SUCCESS'));
                await okta.oktaAuth.tokenManager.clear();
                await okta.oktaAuth.clearStorage();
                dispatch(logout());
            }
        }
    };

    const handleAgreementComplete = async () => {
        await dispatch(
            saveUserAgreementsSettings({
                click_wrap_agreements: {
                    accepted: true,
                    userAggrementAcceptedTimeStamp: dayjs().format('YYYYMMDDHHmmss'),
                    agreements: [
                        {
                            agreement_name: 'UserAgreement',
                            acceptedUserAgreementVersion: user?.userAgreement?.version
                        }
                    ]
                }
            })
        );
    };

    const handleEditSuccess = async (deletedAccounts: string[] = [], formFields?: any[]) => {
        const formFieldsData = formFields || adviceGoals.formFields;
        if (formFieldsData.length > 0) {
            dispatch(showLoader());
            const newFormFields = formFieldsData.map((goal: any) => {
                return {
                    ...goal,
                    data: {
                        ...goal.data,
                        sources: {
                            ...goal.data.sources,
                            incomeSource: goal.data.sources.incomeSource.filter(
                                (source: any) => !deletedAccounts.includes(source.accountNumber)
                            ),
                            investmentSource: goal.data.sources.investmentSource.filter(
                                (source: any) => !deletedAccounts.includes(source.accountNumber)
                            )
                        }
                    }
                };
            });
            await dispatch(
                updateGoal({
                    ...{ ...adviceGoals, formFields: newFormFields },
                    global: {
                        editMode: true,
                        ...adviceGoals?.global,
                        banner: {
                            visible: true,
                            type: 'attention',
                            isGoalSummary: adviceClient.step === 2 ? false : true
                        },
                        ...(adviceGoals.global?.enablePDFGenerate && { enablePDFGenerate: false })
                    }
                })
            );
            dispatch(removeLoader());
        }
    };

    const handleRiskProfileEditSuccess = async () => {
        dispatch(showLoader());
        await dispatch(
            updateGoal({
                ...adviceGoals,
                global: {
                    ...adviceGoals?.global,
                    status: 'incomplete',
                    enablePDFGenerate: false,
                    banner: {
                        visible: true,
                        type: 'attention',
                        isGoalSummary: adviceClient.step === 1,
                        message: t('BANNER_ATTENTION_MESSAGE_SUMMARY')
                    }
                }
            })
        );
        dispatch(removeLoader());
    };

    useEffect(() => {
        const checkTokenExpiry = () => {
            if (okta.authState?.accessToken) {
                const { expiresAt, accessToken } = okta.authState?.accessToken;
                const currentTime = Math.floor(Date.now() / 1000);
                //console.log(currentTime);
                //console.log(expiresAt);
                if (!accessToken || expiresAt < currentTime) {
                    handleLogout();
                }
            }
        };
        const interval = setInterval(checkTokenExpiry, 15000);
        return () => clearInterval(interval);
    }, [okta.authState?.accessToken]);

    useEffect(() => {
        if (okta?.authState && !isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.origin, window.location.href);
            okta?.oktaAuth.setOriginalUri(originalUri);
            navigate(originalUri);
        }
    }, [okta?.authState, isAuthenticated]);

    if (!isAuthenticated) {
        return <Spinner enabled />;
    }

    if (global.showAgreementModal) {
        return (
            <ClickWrapAgreement
                title="User Terms"
                open={global.showAgreementModal}
                agreements={[
                    {
                        title: 'Terms of Use Agreement',
                        content: user?.userAgreement?.text
                    }
                ]}
                onClose={handleLogout}
                onComplete={() => handleAgreementComplete()}
            />
        );
    }
console.log(pathname,"location")
    if (
        user.userRole !== 'admin' &&
        user.userRole !== 'ftadmin' &&
        !allowedPathsForAdvisor.includes(window.location.pathname)
    ) {
        return <Navigate to="/clients" />;
    }

    return (
        // maintain app layout
        <StyledContainer>
            {displaySidebarPaths.includes(pathname) ? (
                <>
                    <LeftPanel
                        data={leftPanelData}
                        onEditIncomeClick={() => setActiveDrawer('incomeSource')}
                        onEditInvestmentClick={() => setActiveDrawer('wealthSource')}
                        onEditRiskProfile={() => setActiveDrawer('riskProfile')}
                    />
                    {activeDrawer === 'incomeSource' && (
                        <AddIncomeSourcesDrawer
                            setOpenDrawer={(flag: boolean) => setActiveDrawer(flag ? 'incomeSource' : null)}
                            openDrawer={activeDrawer === 'incomeSource'}
                            mode="edit"
                            onEditSuccess={handleEditSuccess}
                        />
                    )}
                    {activeDrawer === 'wealthSource' && (
                        <AddInvestmentAccounts
                            setOpenDrawer={(flag: boolean) => setActiveDrawer(flag ? 'wealthSource' : null)}
                            openDrawer={activeDrawer === 'wealthSource'}
                            mode="edit"
                            onEditSuccess={handleEditSuccess}
                        />
                    )}
                    {activeDrawer === 'riskProfile' && (
                        <RiskProfileDrawer
                            setOpenDrawer={(flag: boolean) => setActiveDrawer(flag ? 'riskProfile' : null)}
                            openDrawer={activeDrawer === 'riskProfile'}
                            mode="edit"
                            onEditSuccess={handleRiskProfileEditSuccess}
                        />
                    )}
                    {(activeDrawer === 'client' || activeDrawer === 'relationship') && (
                        <PersonalInfoAndRelationshipsDrawer
                            setOpenDrawer={(flag: boolean) => setActiveDrawer(flag ? 'client' : null)}
                            openDrawer={activeDrawer === 'client' || activeDrawer === 'relationship'}
                            isRelationship={activeDrawer === 'relationship'}
                        />
                    )}
                </>
            ) : null}
            <StyledBodyContainer>
                <MainSectionContainer id="main" isAuthenticated={isAuthenticated}>
                    <ScrollToTop />
                    <Outlet />
                </MainSectionContainer>
                <Footer
                    primaryText={
                        <Trans
                            i18nkey="DESCRIPTION_FOOTER_TWO"
                            defaults={t('DESCRIPTION_FOOTER_TWO')}
                            values={{ YEAR: getCurrentYear() }}
                            components={{ bold: <strong /> }}
                        />
                    }
                    links={[
                        {
                            label: 'Terms of Use',
                            link: `${process.env.PUBLIC_URL}/assets/pdf/en/Digital_Advisor_Terms_of_Use.pdf`
                        },
                        {
                            label: 'Privacy Notice',
                            link: 'https://www.franklintempletonglobal.com/privacy'
                        }
                    ]}
                    eulaVariant={true}
                />
            </StyledBodyContainer>
        </StyledContainer>
    );
};
