import Highcharts from 'highcharts/highcharts';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { useRef } from 'react';
import highchartsMore from 'highcharts/highcharts-more';
import { PATPalette } from '../../../themes/palette';
import _last from 'lodash/last';
import { renderToString } from 'react-dom/server';
import { ProjectedWealthPathLabel } from '../../atoms/ProjectedWealthPathLabel';
import { GoalPriorityEnum, GoalTypeEnum } from '../../../common/types';
import { UniversalCard } from '../UniversalCard/UniversalCard';
import './styles.scss';
import { Grid, styled } from '@mui/material';
import { DataTable, TABLE_VARIANT } from '../../atoms/Table/Table';
import { formatLabelNumber, getExhibitTableValues, labelStyle } from './utils';
import { ReactComponent as AreaLegendUpper } from '../../../assets/icons/areaRangeUpper.svg';
import { ReactComponent as AreaLegendLower } from '../../../assets/icons/areaRangeLower.svg';
import { formatCurrency } from '../../../utils';

// Enables the tag <use> from Icon component
Highcharts.AST.allowedTags.push('use');

highchartsMore(Highcharts);

const StyledSpan = styled('div')(() => ({
    lineHeight: '20px',
    verticalAlign: 'middle',
    fontWeight: 500,
    fontSize: '16px',
    padding: '0px 5px'
}));

type ProjectedWealthPathData = {
    year: number;
    low: number;
    high: number;
    target: number;
    bankruptcyHigh: number;
    bankruptcyLow: number;
};

export type ProjectedWealthPathChartProps = {
    data: ProjectedWealthPathData[];
    plotLine?: {
        type: GoalTypeEnum;
        year: number;
    };
    bankruptcyYear?: number;
    lowPercentile: string;
    highPercentile: string;
    targetPercentile: string;
    goalPriority: GoalPriorityEnum;
    isReadOnly?: boolean; // this is added for PDF rendering
    retirementYear?: number;
};

export const ProjectedWealthPathChart = ({
    bankruptcyYear,
    data,
    plotLine,
    targetPercentile,
    isReadOnly = false,
    retirementYear
}: ProjectedWealthPathChartProps) => {
    const chartComponentRef = useRef<HighchartsReactRefObject | null>(null);
    const firstYear = data?.[0]?.year;
    const lastYear = _last(data)?.year;
    const highAndLow = data
        ?.map(({ year, low, high }) => [year, low, high])
        .filter((item) => (bankruptcyYear ? item[0] <= bankruptcyYear : true));
    const probabilityOfSucess = data
        ?.map(({ year, target }) => [year, target])
        .filter((item) => (bankruptcyYear ? item[0] <= bankruptcyYear : true));
    const bankruptcyLine = data?.map(({ bankruptcyHigh }) => [bankruptcyYear, bankruptcyHigh]);

    const highAndLowBankruptcy = data
        ?.map(({ year, bankruptcyLow, bankruptcyHigh }) => [year, bankruptcyLow, bankruptcyHigh])
        .filter((item) => (bankruptcyYear ? item[0] >= bankruptcyYear : false));
    const hightData = highAndLow.map((row) => [row[0], row[2]]);
    const lowData = highAndLow.map((row) => [row[0], row[1]]);

    const series: Highcharts.SeriesOptionsType[] = [
        {
            name: `Median`,
            type: 'line',
            data: probabilityOfSucess,
            zIndex: 2,
            color: PATPalette.secondary.skyBlue[300],
            lineWidth: 3,
            marker: {
                enabled: false
            },
            animation: !isReadOnly
        },
        {
            name: `High`,
            type: 'line',
            data: hightData,
            dashStyle: 'Dash',
            zIndex: 2,
            color: PATPalette.secondary.teal[300],
            lineWidth: 2,
            enableMouseTracking: false,
            marker: {
                enabled: false
            },
            animation: !isReadOnly
        },
        {
            name: `Low`,
            type: 'line',
            data: lowData,
            zIndex: 2,
            color: PATPalette.secondary.orange[300],
            lineWidth: 2,
            dashStyle: 'DashDot',
            enableMouseTracking: false,
            marker: {
                enabled: false
            },
            animation: !isReadOnly
        },

        {
            name: 'High & Low',
            type: 'arearange',
            data: highAndLow,
            color: PATPalette.secondary.skyBlue[100],
            lineWidth: 0,
            animation: !isReadOnly,
            marker: {
                enabled: false
            },
            zIndex: 0,
            states: {
                inactive: {
                    opacity: 1
                },
                hover: {
                    opacity: 1,
                    enabled: false
                }
            }
        }
    ];

    if (bankruptcyYear) {
        series.push(
            {
                name: 'No Income',
                type: 'arearange',
                data: highAndLowBankruptcy,
                color: PATPalette.secondary.berry[100],
                lineWidth: 0,
                marker: {
                    enabled: false
                },
                zIndex: 0,
                states: {
                    inactive: {
                        opacity: 1
                    },
                    hover: {
                        opacity: 1,
                        enabled: false
                    }
                }
            },
            {
                name: 'No Income',
                showInLegend: false,
                type: 'line',
                data: bankruptcyLine,
                zIndex: 2,
                color: PATPalette.primary.berry,
                lineWidth: 3,
                marker: {
                    enabled: false
                }
            }
        );
    }

    const options: Highcharts.Options = {
        chart: {
            style: {
                fontFamily: 'TT Commons Pro'
            },
            animation: !isReadOnly,
            spacingTop: 20
        },
        title: {
            text: ''
        },
        xAxis: {
            width: '99%',
            crosshair: {
                dashStyle: 'Solid',
                zIndex: 2
            },
            type: 'linear',
            lineWidth: 0,
            left: 70,
            minPadding: 0,
            maxPadding: 0,
            min: firstYear,
            max: lastYear,
            allowDecimals: false,
            tickColor: 'rgba(0,0,0,0.5)',
            tickPosition: 'outside',
            labels: {
                distance: 20,
                style: labelStyle,
                formatter: ({ value }) => {
                    if (value?.toString()?.includes('.')) {
                        return '';
                    }

                    return value.toString();
                }
            },

            ...(!!plotLine && {
                plotLines: [
                    {
                        dashStyle: 'Dash',
                        color: PATPalette.neutral.grey[400],
                        width: 1,
                        value: plotLine.year,
                        zIndex: 10,
                        label: {
                            align: 'center',
                            rotation: 360,
                            useHTML: true,
                            verticalAlign: 'top',
                            y: -10,
                            formatter: () => {
                                return renderToString(
                                    <>
                                        <div className="plot-title">Drawdowns Begin</div>
                                        <ProjectedWealthPathLabel type={plotLine.type} isPDF={isReadOnly} />
                                    </>
                                );
                            }
                        }
                    }
                ]
            })
        },
        yAxis: {
            allowDecimals: false,
            crosshair: false,
            gridLineWidth: 0,
            tickmarkPlacement: 'on',
            tickWidth: 1,
            tickColor: 'rgba(0,0,0,0.5)',
            tickPosition: 'outside',
            title: {
                text: null
            },
            labels: {
                distance: 20,
                style: labelStyle,
                formatter: function () {
                    return formatLabelNumber(this.value as number);
                }
            },
            minPadding: 1,
            maxPadding: 0
        },
        tooltip: {
            shared: true,
            outside: true,
            useHTML: true,
            followPointer: false,
            followTouchMove: false,
            backgroundColor: 'transparent',
            borderWidth: 0,

            formatter: function () {
                const { key: year } = this;

                const currentAreaData = data.find((item) => item.year === Number(year));

                const { high = 0, low = 0, target = 0 } = currentAreaData || {};

                // TODO: Migrate it to a component
                return `
                <div class="tooltip-chart-card">
                    <strong class="title">YEAR ${year}</strong>
                    <strong class="subtitle">PROJECTIONS</strong>
                    <div>
                        <div class="legend">
                        <div class="left">
                            <div
                            class="bar"
                            style="border-bottom:3px dashed ${PATPalette.secondary.teal[300]}"
                            ></div>
                            <span class="name">High</span>
                        </div>

                        <strong class="amount">${formatCurrency(high)}</strong>
                        </div>

                        <div class="legend">
                        <div class="left">
                            <div
                            class="line"
                            style="background: ${PATPalette.secondary.skyBlue[300]}"
                            ></div>
                            <span class="name">Median</span>
                            
                        </div>

                        <strong class="amount">${formatCurrency(target)}</strong>
                        </div>

                        <div class="legend" style="margin-bottom: 0">
                        <div class="left">
                             <div
                            class="low-bar"
                            ></div>
                            <span class="name">Low </span>
                        </div>

                        <strong class="amount">${formatCurrency(low)}</strong>
                        </div>
                    </div>
                </div>
                    `;
            }
        },
        legend: {
            enabled: false,
            align: isReadOnly ? 'left' : 'right',
            verticalAlign: 'top',
            itemMarginBottom: 25,
            symbolHeight: 20,
            symbolWidth: 25,
            itemStyle: {
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '20px'
            }
        },
        series,
        credits: {
            enabled: false
        }
    };

    const { exhibitColumns, exhibitRows } = getExhibitTableValues({
        targetPercentile,
        highAndLow,
        plotLine,
        probabilityOfSucess
    });

    return (
        <Grid marginTop={3}>
            {isReadOnly ? (
                <>
                    <CustomLegend isPDF={true} />
                    <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
                    <Grid marginTop={isReadOnly ? 0 : 1}>
                        <DataTable variant={TABLE_VARIANT.EXHIBIT} columns={exhibitColumns} rows={exhibitRows} />
                    </Grid>
                </>
            ) : (
                <UniversalCard
                    header="Projected Wealth Plan"
                    showInfoToolTip={true}
                    Infomsg={'PROJECTED_WEALTH_PATH_INFO'}
                >
                    <CustomLegend isPDF={false} />
                    <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
                </UniversalCard>
            )}
        </Grid>
    );
};

export interface CustomLegendProps {
    isPDF: boolean;
}

const CustomLegend = ({ isPDF }: CustomLegendProps) => {
    return (
        <Grid container justifyContent={`${isPDF ? 'flex-start' : 'flex-end'}`} className="custom-legend">
            <Grid className="left">
                <Grid className="line" style={{ background: PATPalette.secondary.skyBlue[300] }}></Grid>
                <StyledSpan>Median</StyledSpan>
            </Grid>
            <Grid className="left">
                <Grid className="grid">
                    <AreaLegendUpper />
                    <AreaLegendLower />
                </Grid>
                <StyledSpan>High & Low</StyledSpan>
            </Grid>
        </Grid>
    );
};
