import { Grid } from '@mui/material';
import DoughnutChart from './DoughnutChart';
import PortfolioCompositionList from './PortfolioCompositionList';
import { useGetPortfolioCompositionData } from './useGetPortfolioCompositionData';
import { PDFFooter } from '../../molecules/PDFFooter/PDFFooter';
import { PDFHeader } from '../../molecules/PDFHeader/PDFHeader';
import { PDFTitle } from '../PDF/PDFTitle';
import { GoalTypeEnum } from 'common/types';
import { useTranslation } from 'react-i18next';

export interface PDFPortfolioCompositionProps {
    recommendedPortfolioId: number;
    allMarketPortfolios: any;
    goalType: string;
    goalName: string;
    priority: 'Need' | 'Want' | 'Wish' | 'Dream' | 'onTrack' | 'offTrack' | 'atRisk';
    type: GoalTypeEnum;
    currentPage: number;
    totalPages: number;
}

export const PDFPortfolioComposition = ({
    allMarketPortfolios,
    recommendedPortfolioId,
    goalType,
    goalName,
    priority,
    type,
    currentPage,
    totalPages
}: PDFPortfolioCompositionProps) => {
    const { t } = useTranslation();
    const { doughnutChartData, groupedPortfolio } = useGetPortfolioCompositionData(
        recommendedPortfolioId,
        allMarketPortfolios
    );

    return (
        <Grid height={'1000px'} position={'relative'} marginBottom={'40px'}>
            <PDFHeader pageName={t('PORTFOLIO_COMPOSITION_TITLE')} />
            <PDFTitle goalType={goalType} goalName={goalName} type={type} priority={priority} />
            <Grid container item direction="row" xs={12} spacing={2} mt={1} mb={2} flex={1}>
                <Grid item xs={4}>
                    <DoughnutChart doughnutChartData={doughnutChartData} groupedPortfolio={groupedPortfolio} isPDF />
                </Grid>
                <Grid item xs={8}>
                    <PortfolioCompositionList portfolioListData={groupedPortfolio} isPDF />
                </Grid>
            </Grid>
            <PDFFooter currentPage={currentPage} totalPages={totalPages} />
        </Grid>
    );
};
