import React, { MouseEventHandler } from 'react';
import { Avatar, IconButton, Typography } from '../../components/atoms';
import { AddOutlined } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material';
import { useAppSelector } from '../../common/hooks';
import { UniversalCard } from '../../components/molecules/UniversalCard/UniversalCard';
import List from '../../components/molecules/TableList/List';
import { getFullMonthFromClientDOB, getFullYearFromClientDOB } from '../../common/helper';
import { useTranslation } from 'react-i18next';
import { HeaderIcon } from './HeaderIcon';
import { ReactComponent as AccountIcon } from '../../assets/icons/account.svg';

export interface PersonalInfoCardProps {
    onClick: MouseEventHandler<HTMLButtonElement>;
}

const ColDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(6),
    gap: theme.spacing(6),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
}));
const RelTreeContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(7)
}));
const RelGroup = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}));

export const PersonalInfoCard: React.FC<PersonalInfoCardProps> = ({ onClick }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const adviceClient = useAppSelector((state) => state.adviceClient);
    const formatData = () => {
        return [
            { label: 'Name', value: adviceClient?.clientFirstName || '' },
            {
                label: 'Birth Month',
                value: getFullMonthFromClientDOB(adviceClient?.clientDob) || ''
            },
            { label: 'Birth Year', value: getFullYearFromClientDOB(adviceClient?.clientDob) || '' }
        ];
    };
    return (
        <UniversalCard
            header={t('CLIENT_PROFILE_PERSONAL_INFO_HEADING')}
            headerIcon={<HeaderIcon isDisabled={false} icon={<AccountIcon />} />}
            onClick={() => {}}
        >
            {adviceClient?.clientFirstName && adviceClient?.clientFirstName !== '' ? (
                <ColDiv>
                    <Avatar name={adviceClient?.clientFirstName || ''} />
                    <List data={formatData()} />
                </ColDiv>
            ) : (
                <RelTreeContainer>
                    <RelGroup>
                        {/* Avatar atom needs to be updated to accomodate logged in user's profile image*/}
                        <IconButton
                            iconName={
                                <AddOutlined
                                    color="primary"
                                    sx={{
                                        width: theme.spacing(3.6),
                                        height: theme.spacing(3.6)
                                    }}
                                />
                            }
                            bordered={true}
                            onClick={onClick}
                            color="primary"
                            sx={{
                                border: `1px solid ${theme.palette.primary.main}`,
                                width: theme.spacing(6.2),
                                height: theme.spacing(6.2)
                            }}
                        />
                        <Typography
                            label={t('CLIENT_PROFILE_PERSONAL_INFO_LINK_TEXT')}
                            variant="body.link.large"
                            color="primary.skyBlue"
                            paddingBottom={'0'}
                            marginBottom={'0'}
                            marginTop={theme.spacing(2)}
                            onClick={onClick}
                            sx={{ cursor: 'pointer' }}
                        />
                        <Typography
                            label={t('CLIENT_PROFILE_PERSONAL_INFO_SUBHEADING')}
                            color="neutral.grey.400"
                            variant="body.large"
                        />
                    </RelGroup>
                </RelTreeContainer>
            )}
        </UniversalCard>
    );
};
