import React, { useState } from 'react';
import { styled } from '@mui/material';
import { Chip } from '../../atoms/Chip/Chip';
import { Typography } from '../../atoms/Typography/Typography';
import { UniversalCard } from '../UniversalCard/UniversalCard';
import { useTranslation } from 'react-i18next';
import { ConfirmationCentered } from '../ConfirmationDialog/ConfirmationDialog';
import { useAppSelector, useAppDispatch } from '../../../common/hooks';
import { syncAdviceGoals } from '../../../features/client-goals';
import { PATPalette } from '../../../themes/palette';
import { formatCurrency } from '../../../utils';

export interface GoalSummaryChipProps {
    headerIcon: React.ReactNode | React.ReactNode[];
    goalType: string;
    isRetirement?: boolean;
    goal_name: string;
    priority: 'Need' | 'Want' | 'Wish' | 'Dream';
    goalAmount: number;
    target_date_data: string;
    retirement_age?: number;
    goalId: string;
    targetedRetirementIncome?: number;
    planningDate: string | null;
    // eslint-disable-next-line no-unused-vars
    onGoalDelete: (goalId: string) => void;
    onEdit?: () => void;
}

const StyledDivLeft = styled('div')({
    display: 'flex',
    justifyContent: 'left',
    flexDirection: 'column',
    marginRight: '0.75rem'
});

const StyledDivRight = styled('div')({
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    width: '30%'
});

const StyledContentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    gap: '20px'
});

const StyledDivPriority = styled('div')({
    display: 'flex',
    alignItems: 'left'
});

export const styledMainDiv = styled('div')({
    paddingTop: '0px'
});

export const StyledTable = styled('table')({
    width: '100%',
    borderCollapse: 'collapse',
    '& tr': {
        verticalAlign: 'top',
        '& td': {
            padding: 0
        }
    }
});

export const GoalSummaryChip = ({
    goal_name,
    goalType,
    headerIcon,
    priority,
    goalAmount,
    target_date_data,
    planningDate,
    isRetirement,
    goalId,
    onGoalDelete,
    onEdit,
    targetedRetirementIncome
}: GoalSummaryChipProps) => {
    const { t } = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const dispatch = useAppDispatch();
    const adviceGoals = useAppSelector((state) => state.adviceGoals);
    const banner = adviceGoals?.global?.banner;
    const onConfirmDelete = () => {
        dispatch(
            syncAdviceGoals({
                ...adviceGoals,
                formFields: adviceGoals.formFields.filter((goal) => goal.id !== goalId),
                global: {
                    ...adviceGoals.global,
                    banner: { ...banner, visible: true, type: 'attention', isGoalSummary: true }
                }
            })
        );
        setShowDeleteModal(false);
        onGoalDelete(goalId);
    };

    return (
        <UniversalCard
            header={t(goalType?.toUpperCase())}
            headerIcon={headerIcon}
            actionMenuOptions={[
                {
                    label: 'Edit',
                    onClick: () => {
                        if (onEdit) onEdit();
                    },
                    icon: 'edit'
                },
                {
                    label: 'Delete',
                    onClick: () => setShowDeleteModal(!showDeleteModal),
                    icon: 'delete'
                }
            ]}
            onClickViewAll={() => {}}
            showSubcontent={false}
            cardSx={{
                padding: '20px 24px',
                '& .MuiCardHeader-root': { paddingTop: 0 },
                '& .MuiCardHeader-action': { margin: 0, alignSelf: 'center' }
            }}
        >
            <StyledContentContainer>
                <div>
                    <Typography
                        variant="labels.large"
                        label={t('GOAL_SUMMARY_DETAIL_TEXT')}
                        sx={{ color: PATPalette.neutral.grey[300] }}
                        mb="4px"
                    />
                    <StyledDivPriority>
                        <StyledDivLeft>
                            <Typography variant="headers.h4" color="neutral.black" label={goal_name} />
                        </StyledDivLeft>
                        <StyledDivRight>{priority && <Chip type={priority} />} &nbsp;</StyledDivRight>
                    </StyledDivPriority>
                </div>
                <StyledTable>
                    <tr>
                        {isRetirement && targetedRetirementIncome && (
                            <td>
                                <Typography
                                    variant="labels.large"
                                    label={t('GOAL_SUMMARY_TARGET_INCOME_TEXT')}
                                    sx={{ color: PATPalette.neutral.grey[300] }}
                                    mb="4px"
                                />
                            </td>
                        )}
                        {goalAmount ? (
                            <td>
                                <Typography
                                    variant="labels.large"
                                    label={
                                        isRetirement
                                            ? t('GOAL_SUMMARY_BEQUEST_AMOUNT_TEXT')
                                            : t('GOAL_SUMMARY_TARGET_AMOUNT_TEXT')
                                    }
                                    sx={{ color: PATPalette.neutral.grey[300] }}
                                    mb="4px"
                                />
                            </td>
                        ) : null}
                    </tr>
                    <tr>
                        {isRetirement && targetedRetirementIncome && (
                            <td>
                                <Typography
                                    variant="headers.h4"
                                    color="neutral.black"
                                    label={formatCurrency(targetedRetirementIncome)}
                                />
                            </td>
                        )}
                        {goalAmount ? (
                            <td>
                                <Typography
                                    variant="headers.h4"
                                    color="neutral.black"
                                    label={formatCurrency(goalAmount)}
                                />
                            </td>
                        ) : null}
                    </tr>
                    <tr style={{ height: '20px' }}></tr>
                    <tr>
                        <td>
                            <Typography
                                variant="labels.large"
                                label={
                                    isRetirement
                                        ? t('GOAL_SUMMARY_RETIREMENT_DATE_TEXT')
                                        : t('GOAL_SUMMARY_TARGET_DATE_TEXT')
                                }
                                sx={{ color: PATPalette.neutral.grey[300] }}
                                mb="4px"
                            />
                        </td>
                        {isRetirement && planningDate && (
                            <td>
                                <Typography
                                    variant="labels.large"
                                    label={t('PLANNING_DATE')}
                                    sx={{ color: PATPalette.neutral.grey[300] }}
                                    mb="4px"
                                />
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td>
                            <Typography variant="headers.h4" color="neutral.black" label={target_date_data} />
                        </td>
                        {isRetirement && planningDate && (
                            <td>
                                <Typography variant="headers.h4" color="neutral.black" label={planningDate} />
                            </td>
                        )}
                    </tr>
                </StyledTable>
            </StyledContentContainer>
            {showDeleteModal && (
                <ConfirmationCentered
                    cancelButtonLabel={`${t('BUTTON_CANCEL')}`}
                    confirmButtonLabel={`${t('DELETE_CONFIRMATION_MODAL_DELETE_TEXT')}`}
                    title={`${t('DELETE_GOAL_TITLE')}`}
                    content={`${t('DELETE_GOAL_CONTENT')}`}
                    onCancellation={() => setShowDeleteModal(false)}
                    onConfirmation={() => onConfirmDelete()}
                    open
                />
            )}
        </UniversalCard>
    );
};
