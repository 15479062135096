import { Icon } from '../Icon/Icon';
import { GoalTypeEnum } from '../../../common/types';
import { RETIREMENT_CATEGORY_GOALS, WEALTH_CATEGORY_GOALS } from '../../../common/constants';
import { getIconForPDF } from '../../../utils/goalIconsMapperForPDF';

type Props = {
    type: GoalTypeEnum;
    width?: string;
    isPDF?: boolean;
};

export const ProjectedWealthPathLabel = ({ type, width = '100%', isPDF }: Props) => {
    const { backgroundColor, color, title } = getGoal(type) || {};

    // Using inline css in this component so that the charts can display correctly.
    return (
        <div
            style={{
                width,
                height: '40px',
                borderRadius: '100px',
                background: backgroundColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color,
                gap: '4px'
            }}
        >
            {isPDF ? getIconForPDF(type) : <Icon id={type!} fill="currentColor" />}

            <span
                style={{
                    color: 'currentColor',
                    fontFamily: "'TT Commons Pro'",
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '12px'
                }}
            >
                {title}
            </span>
        </div>
    );
};

const getGoal = (type: GoalTypeEnum) => {
    const goalTypes = [...RETIREMENT_CATEGORY_GOALS, ...WEALTH_CATEGORY_GOALS];

    return goalTypes.find((goalType) => goalType.type === type);
};
